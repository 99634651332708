import comp_zh_HK from "../../core/il8n/lang/zh_HK";
const zh_HK = {
  name: "data",
  // 提示
  tipsMessage: {
    text1: "請先登錄",
  },
  // 首頁index
  homePageIndex: {
    text1: "北京時間",
    text2: "手機投注",
    text3: "隨時隨地 輕鬆投注",
    text4: "MOBILE BET",
    text5: "支持IOS & Android所有移動設備 移動裝置請訪問：",
    text6:
      "我們的手機投注平檯面向全網玩家，提供近百款老虎機百家樂以及彩票遊戲投注，線上存款及線上取款，一鍵操作，達到隨時隨地不間斷娛樂的享受概念。",
    text7: "電子遊藝",
    text8: "千萬獎池等你來",
    text9: "常見問題",
    text10: "簡單便捷，輕鬆上手",
    text11: "申請代理",
    text12: "加盟代理，月入百萬",
    text13: "如何存款",
    text14: "快捷方便，自由存款",
    text15: "如何取款",
    text16: "大款無憂，隨時提取",
    text17: "客服郵箱",
    text18: "客服QQ",
    text19: "客服微信",
    text20: "在線客服",
    text21: "7x24 小時在線貼心服務",
    text22: "QQ二維碼",
    text23: "微信二維碼",
    text24: "關於我們",
    text25: "存款幫助",
    text26: "取款幫助",
    text27: "規則與聲明",
    text28: "常見問題",
    text29: "代理中心",
    text30: "Copyright © bwin Reserved @v2.0.1 - 009",
    text31: "首頁",
    text32: "優惠活動",
    text33: "電子遊藝",
    text34: "真人視訊",
    text35: "電子競技",
    text36: "體育賽事",
    text37: "彩票遊戲",
    text38: "棋牌遊戲",
    text39: "捕魚游戲",
    text40: "原生APP 暢享指尖",
    text41: "體育投注，真人視訊，輕鬆盡在掌握中",
    text42: "全球首家一體化娛樂原生APP，盡情流暢，完美操作，海量",
    text43: "體育，電競頂尖賽事，真人娛樂，彩票投注及電子遊藝等…",
    text44: "最新最全娛樂項目盡在掌中體驗，掃碼下載，即刻擁有！",
    text45: "掃碼下載APP",
    text46: "支持iOS & Android",
    text47: "無需下載直接訪問",
    text48: "視訊直播",
  },
  // 首頁登錄
  homePageLogin: {
    text1: "個人資料",
    text2: "收款方式",
    text3: "遊戲記錄",
    text4: "交易記錄",
    text5: "公告信息",
    text6: "退出登錄",
    text7: "登錄",
    text8: "註冊",
    text9: "賬號",
    text10: "密碼",
    text11: "找回密碼",
    text12: "在線客服",
    text13: "異常驗證",
    text14: "系統檢測到您的賬戶登錄地區異常，請填寫你的賬戶真實姓名進行驗證！",
    text15: "請輸入真實姓名",
    text16: "提交驗證",
    text17: "安全驗證",
    text18: "您正在一台新設備登錄，為了您的賬號安全，請進行安全驗證",
    text19: "請輸入您綁定的手機號",
    text20: "請輸入短信驗證碼",
    text21: "獲取驗證碼",
    text22: "提交驗證",
    text23: "刷新成功",
    text24: "已登出",
    text25: "請輸入真實姓名",
    text26: "無效的手機號",
    text27: "請輸入短信驗證碼",
  },
  // 活動
  promoInfo: {
    text1: "全部優惠",
    text2: "長期活動",
    text3: "限時活動",
    text4: "暫無活動",
    text5: "至",
    text6: "聯繫客服",
    text7: "立即參與",
    text8: "活動狀態：",
    text9: "查看活動記錄",
    text10: "活動狀態：活動截止",
    text11: "查看活動記錄",
    text12: "活動記錄",
    text13: "日期",
    text14: "完成條件",
    text15: "完成狀態",
  },
  // 遊戲二級頁面
  gamePage: {
    text0: "全部遊戲",
    text1: "立即遊戲",
    text2: "熱門推薦",
    text3: "立即遊戲",
    text4: "請輸入遊戲名稱",
    text5: "進入遊戲",
    text6: "英雄聯盟",
    text7: "王者榮耀",
    text8: "DOTA2",
    text9: "CSGO",
    text10: "爐石傳說",
    text11: "守望先鋒",
    text12: "國際足聯",
    text13: "高清直播讓您體驗精彩熱血競技",
    text14: "全球頂尖賽事全覆蓋，提供專業的賠率數據、結算賽果數據。擁有頂尖",
    text15: "的技術支持，為玩家提供令人驚嘆的視覺界面和極致的用戶體驗。",
    text16: "性感荷官 · 傾情展示",
    text17: "全網最美荷官，七國佳麗傾情出演，獨創娛樂&專業雙模式，極致高清",
    text18: "視覺盛宴，多元化遊戲種類，為您帶來最舒心的遊戲體驗。",
    text19: "最新最炫的真人在線娛樂，經典、咪牌、包桌百家樂隨心選擇，美女如",
    text20: "雲多終端兼容，為您帶來極致暢快的遊戲體驗。",
    text21: "百家樂",
    text22: "輪盤",
    text23: "骰寶",
    text24: "龍虎",
    text25: "牛牛",
    text26: "炸金花",
    text27: "三公",
    text28: "體驗分分鐘的刺激",
    text29: "頂尖旗艦平台，最全熱門彩種，玩法多樣操作簡潔",
    text30: "實時助運，穩添好彩！",
    text31: "享受時時彩精彩",
    text32: "超高賠率，時時彩、六合彩、北京賽車應有盡有",
    text33: "致力為玩家打造高品質的娛樂環境。",
    text34: "極速賽車",
    text35: "北京賽車",
    text36: "時時彩",
    text37: "十一選五",
    text38: "六合彩",
    text39: "嶄新玩法 · 感受精彩",
    text40: "豐富的體育賽事一應俱全，項目玩法多元，下半場波膽、讓球嶄新玩",
    text41: "法，感受不一樣的精彩",
    text42: "提前兌現 · 及時止損",
    text43: "創新功能提前兌現，無需等待即可提前結算，止損機制讓您百無禁忌",
    text44: "足球",
    text45: "籃球",
    text46: "排球",
    text47: "棒球",
    text48: "網球",
    text49: "乒乓球",
    text50: "羽毛球",
  },
  // 用户中心
  userPage: {
    // 活動記錄
    text1: "活動記錄",
    text2: "每條活動的數據將會有一定的時間延遲，僅供參考使用",
    text3: "活動名稱",
    text4: "活動時間",
    text5: "年/月/日",
    text6: "查詢",
    text7: "重置",
    text8: "活動名稱",
    text9: "參與時間",
    text10: "操作",
    text11: "查看詳情",
    text12: "一條記錄也沒有呢...",
    text13: "請輸入活動名稱",
    // 收款方式管理
    text14: "收款方式管理",
    text15: "添加收款方式",
    text16: "賬戶姓名",
    text17: "銀行名稱",
    text18: "選擇銀行",
    text19: "支行名稱",
    text20: "請輸入支行名稱",
    text21: "銀行卡號",
    text22: "請輸入銀行卡號",
    text23: "確認卡號",
    text24: "請再次輸入銀行卡號",
    text25: "手機號",
    text26: "驗證碼",
    text27: "請輸入驗證碼",
    text28: "發送驗證碼",
    text29: "賬戶姓名",
    text30: "貨幣名稱",
    text31: "鏈路",
    text32: "請輸入ERC20或TRC20",
    text33: "錢包地址",
    text34: "請輸入錢包地址",
    text35: "確認地址",
    text36: "請再次輸入錢包地址",
    text37: "手機號",
    text38: "幣種",
    text39: "選擇幣種",
    text40: "錢包賬戶ID",
    text41: "請輸入origo錢包ID",
    text42: "確認賬戶",
    text43: "請再次輸入origo錢包ID",
    text44: "確定",
    text45: "返回",
    text46: "銀行卡",
    text47: "數字貨幣",
    text48: "origo錢包",
    text49: "暫無銀行卡",
    text50: "添加銀行卡",
    text51: "添加數字貨幣",
    text52: "添加origo錢包",
    // 公告
    text53: "通知",
    text54: "公告",
    // 銀行卡
    text55: "銀行卡支付",
    text56: "支付詳情",
    text57: "收款戶名",
    text58: "收款銀行",
    text59: "收款卡號",
    text60: "開戶行-支行信息",
    text61: "溫馨提示",
    text62: "1、請正確填寫收款卡號和充值金額。",
    text63: "2、銀行卡轉賬成功後請確認已付款。",
    text64: "3、到賬時間取決於銀行處理時間，請耐心等待。",
    text65: "4、請勿重複支付。",
    // 存款
    text66: "存款",
    text67: "支付方式",
    text68: "公司入款",
    text69: "線上入款",
    text70: "數字貨幣",
    text71: "VIP專享",
    text72: "贈送",
    text73: "充值通道",
    text74: "溫馨提示",
    text75:
      "1.請注意頁面顯示的最小轉賬額度，小於最小充值金額的充值將不會上賬且無法退回",
    text76:
      "2.您充值至上述地址後，需要整個網絡節點的確認，請耐心等待，我們收到數字貨幣後會立即向您的賬戶上賬對應金額，若長時間未收到，可聯繫客服諮詢",
    text77: "3.轉賬時請務必核對幣種以及鏈名稱，若提交錯誤資產不可找回",
    text78:
      "4.頁面顯示匯率只供參考，由於匯率存在波動，上賬時將根據最新的匯率比例進行計算",
    text79: "存款姓名",
    text80: "未及時到賬，請務必輸入正確的存款姓名",
    text81: "充值金額",
    text82: "單筆最低入款金額",
    text83: "單筆最高入款金額",
    text84: "請輸入存款姓名",
    text85: "請輸入合法金額",
    text86: "充值金額不在範圍內",
    text87: "通道異常，請聯繫客服！",
    //短信驗證彈窗
    text88: "請驗證手機號提款",
    text89: "手機",
    text90: "請輸入手機號",
    text91: "驗證碼",
    text92: "請輸入驗證碼",
    text93: "發送驗證碼",
    text94: "確定",
    text95: "手機號不能為空",
    text96: "請輸入正確的手機號",
    text97: "驗證碼不能為空",
    text98: "手機號格式錯誤",
    // 取款
    text99: "取款",
    text100: "主賬戶額度",
    text101: "元",
    text102: "一鍵歸集",
    text103: "歸集中",
    text104: "收款方式",
    text105: "添加收款方式",
    text106: "下一步",
    text107: "收款方式",
    text108: "提款金額",
    text109: "當前可提現金額",
    text110: "全部",
    text111: "實際到賬金額",
    text112: "實際到賬金額",
    text113: "提現手續費",
    text114: "行政費用",
    text115: "優惠扣除",
    text116: "交易密碼",
    text117: "請輸入密碼",
    text118: "上一步",
    text119: "確定",
    text120: "請選擇取款方式",
    text121: "刷新成功",
    text122: "提款金額不能為空！",
    text123: "交易密碼不能為空！",
    text124: "申請成功，請等待平台審核!",
    // 遊戲記錄
    text125: "遊戲記錄",
    text126: "每條注單的數據將會有一定的時間延遲，僅供參考使用",
    text127: "遊戲平台",
    text128: "投注金額",
    text129: "時間篩選",
    text130: "查詢",
    text131: "重置",
    text132: "訂單號",
    text133: "遊戲平台",
    text134: "遊戲名稱",
    text135: "投注時間",
    text136: "派彩時間",
    text137: "投注金額",
    text138: "有效投注",
    text139: "派彩金額",
    text140: "盈虧金額",
    text141: "總投注",
    text142: "總有效投注",
    text143: "總派金",
    text144: "總盈虧金額",
    // 彩金
    text146: "彩金",
    text147: "領取有效期截止",
    text148: "加載中",
    text149: "到底了",
    text150: "暫無數據",
    text151: "彩金領取",
    text152: "流水要求",
    text153: "倍",
    text154: "取消",
    text155: "立即領取",
    text156: "全部",
    text157: "可領取",
    text158: "已領取",
    text159: "已過期",
    // 公告消息
    text160: "公告消息",
    text161: "一條通知都沒有呢...",
    text162: "一條公告都沒有呢...",
    // 全民返利
    text163: "全民返利",
    text164: "規則說明",
    text165: "累計返利",
    text166: "滿",
    text167: "元，且有效會員≥",
    text168: "人，即可領取",
    text169: "領取",
    text170: "會員",
    text171: "有效會員",
    text172: "我的推廣碼",
    text173: "下載推廣碼",
    text174: "我的邀請碼",
    text175: "複製邀請碼",
    text176: "我的推廣地址",
    text177: "複製地址",
    text178: "規則說明",
    text179: "領取返利",
    text180: "流水要求",
    text181: "取 消",
    text182: "確 定",
    text183: "未滿足領取要求，最低領取金額",
    text184: "元,有效會員人數≥",
    text185: "人,領取上限",
    text186: "領取成功",
    text187: "領取失敗",
    // 邀請會員
    text188: "邀請會員",
    text189: "總投注",
    text190: "累計獲得總返利",
    text191: "註冊時間：",
    text192: "選擇時間：",
    text193: "會員賬號",
    text194: "註冊時間",
    text195: "總有效投注(元)",
    text196: "返利金(元)",
    // 個人資料
    text197: "個人資料",
    text198: "常用設備管理",
    text199: "（最多可綁定10個常用登陸設備）",
    text200: "本機",
    text201: "刪除",
    text202: "暫無設備",
    // 資金密碼
    text203: "資金密碼",
    text204: "舊密碼",
    text205: "請輸入原資金密碼",
    text206: "當前您所使用的資金密碼",
    text207: "新密碼",
    text208: "請輸入新資金密碼",
    text209: "密碼6位數字組合",
    text210: "確認密碼",
    text211: "請再次輸入密碼",
    text212: "必須與新密碼一致",
    text213: "確定",
    text214: "確認密碼不能為空",
    text215: "交易密碼必須為6位數字",
    text216: "兩次輸入的密碼不一致",
    text217: "新資金密碼不能為空",
    text218: "交易密碼必須為6位數字",
    text219: "原資金密碼不能為空",
    // 賬號密碼
    text220: "賬號密碼",
    text221: "舊密碼",
    text222: "請輸入原登錄密碼",
    text223: "當前您所使用的登錄密碼",
    text224: "新密碼",
    text225: "請輸入新登錄密碼",
    text226: "密碼6-15位,字母數字符號至少兩種組合",
    text227: "確認密碼",
    text228: "請再次輸入新密碼",
    text229: "必須與新密碼一致",
    text230: "確認密碼不能為空",
    text231: "兩次輸入的密碼不一致",
    text232: "原登錄密碼不能為空",
    text233: "新登錄密碼不能為空",
    text234: "請重新登錄",
    // tab
    text235: "安全驗證",
    text236: "資金密碼",
    text237: "登錄密碼",
    text238: "設備管理",
    // 返水詳情頁
    text239: "交易記錄",
    text240: "返水詳情",
    text241: "只可查詢近一個月的交易記錄，如需更多信息請聯繫客服查詢",
    text242: "投注金額",
    text243: "時間篩選",
    text244: "時間",
    text245: "遊戲平台",
    text246: "遊戲名稱",
    text247: "流水",
    text248: "返水",
    text249: "您查詢時間已超過既定期限，如需更多信息請聯繫在線客服",
    // 交易記錄
    text250: "交易記錄",
    text251: "只可查詢近一個月的交易記錄，如需更多信息請聯繫客服查詢",
    text252: "交易類型",
    text253: "交易時間",
    text254: "選擇時間",
    text255: "複製",
    text256: "金額",
    text257: "狀態",
    text258: "詳情",
    text259: "充值記錄",
    text260: "銀行轉賬",
    text261: "提款記錄",
    text262: "返水記錄",
    text263: "其它",
    text264: "今日",
    text265: "昨日",
    text266: "近7日",
    text267: "近30日",
    text268: "支付方式",
    text269: "提款方式",
    text270: "返水類型",
    text271: "類型",
    text272: "您查詢時間已超過既定期限，如需更多信息請聯繫在線客服",
    // 收款方式管理
    text273: "收款方式管理",
    text274: "添加銀行卡/數字貨幣",
    text275: "不可重複添加相同的銀行",
    // 基本信息
    text276: "賬號密碼",
    text277: "交易密碼",
    text278: "管理設備",
    text279: "管理",
    text280: "修改",
    text281: "設置",
    // 信息編輯
    text282: "真實姓名",
    text283: "請輸入真實姓名",
    text284: "會員生日",
    text285: "用戶暱稱",
    text286: "聯繫QQ",
    text287: "請輸入用戶暱稱",
    text288: "手機號碼",
    text289: "請輸入手機號碼",
    text290: "聯繫微信",
    text291: "請輸入聯繫微信",
    text292: "會員郵箱",
    text293: "請輸入會員郵箱",
    text294: "請輸入用戶暱稱",
    // 個人中心
    text295: "普通用戶",
    text296: "餘額",
    text297: "佣金",
    text298: "返水領取",
    text299: "VIP等級",
    text300: "等級",
    text301: "達成條件",
    text302: "次",
    // 用戶菜單
    text303: "用戶信息",
    text304: "遊戲記錄",
    text305: "交易記錄",
    text306: "公告消息",
    text307: "我的賬戶",
    text308: "活動記錄",
    text309: "全民返利",
    text310: "存款",
    text311: "取款",
    text312: "彩金",
    text313: "返水額度不滿足條件，無法領取！",
    text314: "密碼",
    text315: "銀行賬戶",
    text316: "語言",
    text317: "轉移",
    text318: "會員總數",
    text319: "請創建您的提款方式",
    text320: "提款方式",
    text321: "創建提款方式",
    text322: "請驗證手機號",
    text323: "手機短訊驗證碼",
  },
  ...comp_zh_HK,
};
export default zh_HK;
