import comp_zh_CN from "../../core/il8n/lang/zh_CN";
const zh_CN = {
  name: "data",
  // 借口返回错误码处理
  errorCode: {
    loading: "加载中...",
    webCode503: "维护中",
    webDefault: "网络响应异常，请稍后再试，或联系客服咨询",
    "4001": "调用远程接口异常",
    "401": "您的登录账户已经过期，请您重新登录",
    "503": "系统维护中",
    "0": "成功！",
    "1": "失败！",
    "500": "异常!",
    "1001": "登录失败，获取token失败",
    "1012": "网络异常，请稍后再试！",
    "1101": "用户未授权登录",
    "1102": "用户授权不匹配",
    "4011": "redis链接异常",
    "4021": "短信发送失败",
    "7001": "参数异常",
    "7002": "数据解析异常",
    "7011": "插入参数错误",
    "7101": "需要重新汇总的数据为空",
    "7102": "Id不能为空",
    "7103": "当前ip为非CIDR格式",
    "7201": "初始化验证码失败",
    "7202": "验证码校验失败,请重试",
    "7203": "验证码不能为空",
    "7204": "验证码发送过于频繁",
    "7301": "该账户不存在或已被激活,请重新输入",
    "7302": "该账户已被激活,请不要重复激活",
    "7303": "该账户正在激活中，请勿重复激活",
    "7304": "账号不存在",
    "7305": "账户密码错误，请重新输入！3",
    "7306": "您的密码修改次数已达上限，请联系客服处理！",
    "8000": "找不到相关数据",
    "9980": "活动尚未开始",
    "9981": "用户层级不匹配",
    "9983": " 名称不能为空！",
    "9985": " 层级信息异常！",
    "9987": " 保存异常！",
    "9990": " 自动审核条件设置异常，请确认后重试！",
    "9992": "操作已生效,请勿重复操作",
    "9993": "层级设置数据异常，请检查层级设置后重试！",
    "9994": "未查询到用户信息",
    "9996": "用户不满足活动要求",
    "9997": "活动已过期",
    "10001": "活动尚未开始或已截止",
    "10002": "定时活动异常",
    "10003": "该活动只允许新注册玩家参与",
    "10004": "您的会员层级不在活动允许参与范围内",
    "10031": "记录的类型不能修改",
    "10032": "该记录的跳转类型不能修改",
    "10033": "该记录的跳转内容不能修改",
    "10034": "该记录固定编码不能修改",
    "10035": "该记录不能删除",
    "10062": "签到异常用户不满足活动要求",
    "10091": "不参与厂商数据格式异常，数据格式为key-value格式",
    "10501": "IP已经添加，无需再次添加",
    "10502":
      "当跳转类型为外链的时候，跳转内容必须以 http:// 或者 https:// 开头！",
    "11001": "您的申请已提交，请等待客服联系",
    "11031": "至少添加一个层级",
    "11061": "该方案已被使用，无法关闭",
    "11091": "该代理账号可能已被禁用，或被删除，或者代理层级不是代理",
    "11501": "代理账号名已存在，请重新填写",
    "11531": "佣金配置详情信息为空",
    "11801": "手机号加密失败，请稍后再试",
    "12001": "获取游戏链接失败",
    "12002": "获取游戏链接失败",
    "12003": "删除失败，标签下还存在游戏",
    "12104": "该游戏或者该厂商已被删除",
    "12105": "该游戏厂商尚未支持转账确认",
    "12208": "暂无奖池数据",
    "12501": "queryType必須爲[vendor 或 tags 或 reportFee]其中一種",
    "12532": "起始时间为必填项",
    "12533": "起始时间大于结束时间",
    "12534": "起始时间大于60天",
    "12565": "gameKindCode不能为空",
    "12566": "user的id与member的id不匹配",
    "12592": "游戏商不存在vendorCode",
    "12597": "厂商信息不能为空:",
    "13001": "用户不存在",
    "13002": "用户注册时保存数据库失败",
    "13003": "用户检查失败",
    "13004": "用户状态异常",
    "13006": "member不存在",
    "13007": "您的账号未绑定手机号码，请联系客服处理",
    "13008": "该账号已被冻结，请联系客服",
    "13009": "该账号已被禁用，请联系客服",
    "13010": "由于版本更新您账号超出字符限制，请联系在线客服处理",
    "130011": "用户名或密码错误请重新输入,或联系客服处理",
    "130012": "用户名已存在",
    "13501": "手机号已存在",
    "13502": "用户名id不能为空",
    "13503": "手机号的用户名没有找到",
    "13801": "用户注册失败",
    "13802": "用户更新失败",
    "13803": "远程调用用户更新失败",
    "13901": "会员注册设置异常，请联系客服！",
    "13902": "后台找回密码次数限制配置错误",
    "14001": "用户转账失败",
    "14002": "自动转账失败",
    "14003": "两次稽核的值已经发生变化了",
    "14031": "订单暂未锁定，请先锁定再操作",
    "14032": "该订单已被其他人锁定,您无法操作！",
    "14033": "订单的状态不符合要求！",
    "14034": "提交失败，汇率已变动，请核对并确认金额后重新提交！",
    "14061": "提款申请已提交，请勿重复提交",
    "14062": "提款异常",
    "14091": "您已经绑定过此银行卡",
    "14092": "银行卡账户名不能为空",
    "14093": "绑定银行卡已达到上限",
    "14094": "银行卡姓名与你账号的真实姓名不一致",
    "14095": "钱包类型绑定数量已达上限",
    "14096": "银行卡暂时无法删除，请联系客服处理，谢谢",
    "14097": "该银行卡或数字钱包已被绑定,请重新输入",
    "14098": "您的账号被禁用，请联系客服人员！",
    "14099": "您的钱包账户被禁用，请联系客服人员！",
    "14100": "您输入的账号有误，请联系客服人员！",
    "14101": "请输入长度为6位数字的钱包ID号，谢谢",
    "14501": "存款人不能为空",
    "14502": "支付平台编码和名称为必填项",
    "14503": "钱包用户没有找到",
    "14504": "您的单次提款金额低于下限：",
    "14505": "您的单次提款金额高于上限：",
    "14506": "存款金额必须大于零",
    "14801": "更新失败",
    "14901": "支付密钥没有设置",
    "14902": "获取支付列表的URL没有设置",
    "14903": "获取同步订单的URL没有设置",
    "14904": "订单回调的URL没有设置",
    "14905": "数字货币的充值回调CALLBACK没有设置",
    "14906": "该商户的支付密钥没有设置",
    "14907": "支付相关的字典设置出错了",
    "15001": "领取失败",
    "15002": "领取异常",
    "15034": "获取租户信息异常",
    "15035": "数据已被更改，手动回滚事务",
    "15036": "参与次数已达上限",
    "15037": "参与总金额已达上限",
    "15038": "单次金额已超上限",
    "15061": "全民返利功能尚未开启",
    "15062": "未领取返利金额小于最低领取额度",
    "15063": "请勿重复提交",
    "15064": "正在领取返水中",
    "15091": "余额正在归集，不予查询！！！",
    "15121": "钱包服务异常",
    "15151": "订单已经处理过",
    "15181": "清理冻结失败",
    "15501": "转账金额必须大于1",
    "15502": "转账金额必须大于0",
    "15503": "转账模式不是合法值",
    "15504": "冻结余额不足",
    "15801": "中心钱包转入到游戏商失败！",
    "15802": "游戏商转出到中心钱包失败！",
    "15901": "返水未配置",
    "16001": "平台维护",
    "16002": "平台不存在",
    "16003": "账号已在其它设备登录，请重新登录",
    "17007": "银行名称或币种不能为空",
    "17008": "卡号或钱包地址不能为空",
    "17009": "银行名称或币种不能为空",
    "17010": "支行或链路不能为空",
    "17011": "会员id不能为空",
    "100661": "您的签到请求已发送，请勿重复提交",
    "110004": "Token 无效！",
    "110007": "用户未授权登录！",
    "110009": "当前ip受限",
    "110010": "请求过于频繁，请稍后再试",
    "120001": "该商户不存在",
    "120004": "请求参数中存在null",
    "120014": "用户的中心钱包不存在",
    "120017": "会员等级不存在",
    "120021": "订单号已经存在：",
    "120024": "订单信息不匹配",
    "120025": "支付模式不匹配",
    "120026": "订单状态已经为最终状态,您无法操作！！",
    "120027": "您上一次提款未处理完，请耐心等待！",
    "120029": "订单需要风控审核通过，您才能操作！",
    "120031": "您的每日提现次数已经用完：",
    "120032": "钱包已被禁用，请联系客服！",
    "120050": "严重警告：该用户的密值不匹配",
    "120051": "您的余额不足，无法提现！",
    "120055": "生成密值时发生错误",
    "120056": "您的提款密码错误！",
    "120057": "更新钱包数据失败version不匹配",
    "120070": "用户的余额不足",
    "120093": "获取频繁，请稍后再试！！！",
    "120561":
      "您填写的邀请码有问题(代理用户不存在，或尚未启用),请核实后重新提交申请",
    "130000": "该币种在链路上的汇率没有找到,请更换币种",
    "999999": "支付异常",
    "1000": "未知错误，若出现异常情况，请联系客服咨询",
    "130013": "原密码错误，修改失败",
  },
  // 提示
  tipsMessage: {
    text1: "请先登录",
  },
  // 首页index
  homePageIndex: {
    text1: "北京时间",
    text2: "手机投注",
    text3: "随时随地 轻松投注",
    text4: "MOBILE BET",
    text5: "支持IOS & Android所有移动设备 移动装置请访问：",
    text6:
      "我们的手机投注平台面向全网玩家，提供近百款老虎机百家乐以及彩票游戏投注，线上存款及线上取款，一键操作，达到随时随地不间断娱乐的享受概念。",
    text7: "电子游艺",
    text8: "千万奖池等你来",
    text9: "常见问题",
    text10: "简单便捷，轻松上手",
    text11: "申请代理",
    text12: "加盟代理，月入百万",
    text13: "如何存款",
    text14: "快捷方便，自由存款",
    text15: "如何取款",
    text16: "大款无忧，随时提取",
    text17: "客服邮箱",
    text18: "客服QQ",
    text19: "客服微信",
    text20: "在线客服",
    text21: "7x24 小时在线贴心服务",
    text22: "QQ二维码",
    text23: "微信二维码",
    text24: "关于我们",
    text25: "存款帮助",
    text26: "取款帮助",
    text27: "规则与声明",
    text28: "常见问题",
    text29: "代理中心",
    text30: "Copyright © bwin Reserved @v2.0.1 - 009",
    text31: "首页",
    text32: "优惠活动",
    text33: "电子游艺",
    text34: "真人视讯",
    text35: "电子竞技",
    text36: "体育赛事",
    text37: "彩票游戏",
    text38: "棋牌游戏",
    text39: "捕鱼游戏",
    text40: "原生APP 畅享指尖",
    text41: "体育投注，真人视讯，轻松尽在掌握中",
    text42: "全球首家一体化娱乐原生APP，尽情流畅，完美操作，海量",
    text43: "体育，电竞顶尖赛事，真人娱乐，彩票投注及电子游艺等…",
    text44: "最新最全娱乐项目尽在掌中体验，扫码下载，即刻拥有！",
    text45: "扫码下载APP",
    text46: "支持iOS & Android",
    text47: "无需下载直接访问",
    text48: "视讯直播",
    text49: "是否去绑定银行卡？",
    text50: "您未绑定银行卡",
    text51: "确定",
    text52: "取消",
  },
  // 首页登录
  homePageLogin: {
    text1: "个人资料",
    text2: "收款方式",
    text3: "游戏记录",
    text4: "交易记录",
    text5: "公告信息",
    text6: "退出登录",
    text7: "登录",
    text8: "注册",
    text9: "账号",
    text10: "密码",
    text11: "找回密码",
    text12: "在线客服",
    text13: "异常验证",
    text14: "系统检测到您的账户登录地区异常，请填写你的账户真实姓名进行验证！",
    text15: "请输入真实姓名",
    text16: "提交验证",
    text17: "安全验证",
    text18: "您正在一台新设备登录，为了您的账号安全，请进行安全验证",
    text19: "请输入您绑定的手机号",
    text20: "请输入短信验证码",
    text21: "获取验证码",
    text22: "提交验证",
    text23: "刷新成功",
    text24: "已登出",
    text25: "请输入真实姓名",
    text26: "无效的手机号",
    text27: "请输入短信验证码",
  },
  // 活动
  promoInfo: {
    text1: "全部优惠",
    text2: "长期活动",
    text3: "限时活动",
    text4: "暂无活动",
    text5: "至",
    text6: "联系客服",
    text7: "立即参与",
    text8: "活动状态：",
    text9: "查看活动记录",
    text10: "活动状态：活动截止",
    text11: "查看活动记录",
    text12: "活动记录",
    text13: "日期",
    text14: "完成条件",
    text15: "完成状态",
  },
  // 游戏二级页面
  gamePage: {
    text0: "全部游戏",
    text1: "立即游戏",
    text2: "热门推荐",
    text3: "立即游戏",
    text4: "请输入游戏名称",
    text5: "进入游戏",
    text6: "英雄联盟",
    text7: "王者荣耀",
    text8: "DOTA2",
    text9: "CSGO",
    text10: "炉石传说",
    text11: "守望先锋",
    text12: "国际足联",
    text13: "高清直播让您体验精彩热血竞技",
    text14: "全球顶尖赛事全覆盖，提供专业的赔率数据、结算赛果数据。拥有顶尖",
    text15: "的技术支持，为玩家提供令人惊叹的视觉界面和极致的用户体验。",
    text16: "性感荷官 · 倾情展示",
    text17: "全网最美荷官，七国佳丽倾情出演，独创娱乐&专业双模式，极致高清",
    text18: "视觉盛宴，多元化游戏种类，为您带来最舒心的游戏体验。",
    text19: "最新最炫的真人在线娱乐，经典、咪牌、包桌百家乐随心选择，美女如",
    text20: "云多终端兼容，为您带来极致畅快的游戏体验。",
    text21: "百家乐",
    text22: "轮盘",
    text23: "骰宝",
    text24: "龙虎",
    text25: "牛牛",
    text26: "炸金花",
    text27: "三公",
    text28: "体验分分钟的刺激",
    text29: "顶尖旗舰平台，最全热门彩种，玩法多样操作简洁",
    text30: "实时助运，稳添好彩！",
    text31: "享受时时彩精彩",
    text32: "超高赔率，时时彩、六合彩、北京赛车应有尽有",
    text33: "致力为玩家打造高品质的娱乐环境。",
    text34: "极速赛车",
    text35: "北京赛车",
    text36: "时时彩",
    text37: "十一选五",
    text38: "六合彩",
    text39: "崭新玩法 · 感受精彩",
    text40: "丰富的体育赛事一应俱全，项目玩法多元，下半场波胆、让球崭新玩",
    text41: "法，感受不一样的精彩",
    text42: "提前兑现 · 及时止损",
    text43: "创新功能提前兑现，无需等待即可提前结算，止损机制让您百无禁忌",
    text44: "足球",
    text45: "篮球",
    text46: "排球",
    text47: "棒球",
    text48: "网球",
    text49: "乒乓球",
    text50: "羽毛球",
  },
  // 用户中心
  userPage: {
    // 活动记录
    text1: "活动记录",
    text2: "每条活动的数据将会有一定的时间延迟，仅供参考使用",
    text3: "活动名称",
    text4: "活动时间",
    text5: "年/月/日",
    text6: "查询",
    text7: "重置",
    text8: "活动名称",
    text9: "参与时间",
    text10: "操作",
    text11: "查看详情",
    text12: "一条记录也没有呢...",
    text13: "请输入活动名称",
    // 收款方式管理
    text14: "收款方式管理",
    text15: "添加收款方式",
    text16: "账户姓名",
    text17: "银行名称",
    text18: "选择银行",
    text19: "支行名称",
    text20: "请输入支行名称",
    text21: "银行卡号",
    text22: "请输入银行卡号",
    text23: "确认卡号",
    text24: "请再次输入银行卡号",
    text25: "手机号",
    text26: "验证码",
    text27: "请输入验证码",
    text28: "发送验证码",
    text29: "账户姓名",
    text30: "货币名称",
    text31: "链路",
    text32: "请输入ERC20或TRC20",
    text33: "钱包地址",
    text34: "请输入钱包地址",
    text35: "确认地址",
    text36: "请再次输入钱包地址",
    text37: "手机号",
    text38: "币种",
    text39: "选择币种",
    text40: "钱包账户ID",
    text41: "请输入origo钱包ID",
    text42: "确认账户",
    text43: "请再次输入origo钱包ID",
    text44: "确定",
    text45: "返回",
    text46: "银行卡",
    text47: "数字货币",
    text48: "origo钱包",
    text49: "暂无银行卡",
    text50: "添加银行卡",
    text51: "添加数字货币",
    text52: "添加origo钱包",
    // 公告
    text53: "通知",
    text54: "公告",
    // 银行卡
    text55: "银行卡支付",
    text56: "支付详情",
    text57: "收款户名",
    text58: "收款银行",
    text59: "收款卡号",
    text60: "开户行-支行信息",
    text61: "温馨提示",
    text62: "1、请正确填写收款卡号和充值金额。",
    text63: "2、银行卡转账成功后请确认已付款。",
    text64: "3、到账时间取决于银行处理时间，请耐心等待。",
    text65: "4、请勿重复支付。",
    // 存款
    text66: "存款",
    text67: "支付方式",
    text68: "公司入款",
    text69: "线上入款",
    text70: "数字货币",
    text71: "VIP专享",
    text72: "赠送",
    text73: "充值通道",
    text74: "温馨提示",
    text75:
      "1.请注意页面显示的最小转账额度，小于最小充值金额的充值将不会上账且无法退回",
    text76:
      "2.您充值至上述地址后，需要整个网络节点的确认，请耐心等待，我们收到数字货币后会立即向您的账户上账对应金额，若长时间未收到，可联系客服咨询",
    text77: "3.转账时请务必核对币种以及链名称，若提交错误资产不可找回",
    text78:
      "4.页面显示汇率只供参考，由于汇率存在波动，上账时将根据最新的汇率比例进行计算",
    text79: "存款姓名",
    text80: "未及时到账，请务必输入正确的存款姓名",
    text81: "充值金额",
    text82: "单笔最低入款金额",
    text83: "单笔最高入款金额",
    text84: "请输入存款姓名",
    text85: "请输入合法金额",
    text86: "充值金额不在范围内",
    text87: "通道异常，请联系客服！",
    //短信验证弹窗
    text88: "请验证手机号提款",
    text89: "手机",
    text90: "请输入手机号",
    text91: "验证码",
    text92: "请输入验证码",
    text93: "发送验证码",
    text94: "确定",
    text95: "手机号不能为空",
    text96: "请输入正确的手机号",
    text97: "验证码不能为空",
    text98: "手机号格式错误",
    // 取款
    text99: "取款",
    text100: "主账户额度",
    text101: "元",
    text102: "一键归集",
    text103: "归集中",
    text104: "收款方式",
    text105: "添加收款方式",
    text106: "下一步",
    text107: "收款方式",
    text108: "提款金额",
    text109: "当前可提现金额",
    text110: "全部",
    text111: "实际到账金额",
    text112: "实际到账金额",
    text113: "提现手续费",
    text114: "行政费用",
    text115: "优惠扣除",
    text116: "交易密码",
    text117: "请输入密码",
    text118: "上一步",
    text119: "确定",
    text120: "请选择取款方式",
    text121: "刷新成功",
    text122: "提款金额不能为空！",
    text123: "交易密码不能为空！",
    text124: "申请成功，请等待平台审核!",
    // 游戏记录
    text125: "游戏记录",
    text126: "每条注单的数据将会有一定的时间延迟，仅供参考使用",
    text127: "游戏平台",
    text128: "投注金额",
    text129: "时间筛选",
    text130: "查询",
    text131: "重置",
    text132: "订单号",
    text133: "游戏平台",
    text134: "游戏名称",
    text135: "投注时间",
    text136: "派彩时间",
    text137: "投注金额",
    text138: "有效投注",
    text139: "派彩金额",
    text140: "盈亏金额",
    text141: "总投注",
    text142: "总有效投注",
    text143: "总派金",
    text144: "总盈亏金额",
    // 彩金
    text146: "彩金",
    text147: "领取有效期截止",
    text148: "加载中",
    text149: "到底了",
    text150: "暂无数据",
    text151: "彩金领取",
    text152: "流水要求",
    text153: "倍",
    text154: "取消",
    text155: "立即领取",
    text156: "全部",
    text157: "可领取",
    text158: "已领取",
    text159: "已过期",
    // 公告消息
    text160: "公告消息",
    text161: "一条通知都没有呢...",
    text162: "一条公告都没有呢...",
    // 全民返利
    text163: "全民返利",
    text164: "规则说明",
    text165: "累计返利",
    text166: "满",
    text167: "元，且有效会员≥",
    text168: "人，即可领取",
    text169: "领取",
    text170: "会员",
    text171: "有效会员",
    text172: "我的推广码",
    text173: "下载推广码",
    text174: "我的邀请码",
    text175: "复制邀请码",
    text176: "我的推广地址",
    text177: "复制地址",
    text178: "规则说明",
    text179: "领取返利",
    text180: "流水要求",
    text181: "取 消",
    text182: "确 定",
    text183: "未满足领取要求，最低领取金额",
    text184: "元,有效会员人数≥",
    text185: "人,领取上限",
    text186: "领取成功",
    text187: "领取失败",
    // 邀请会员
    text188: "邀请会员",
    text189: "总投注",
    text190: "累计获得总返利",
    text191: "注册时间：",
    text192: "选择时间：",
    text193: "会员账号",
    text194: "注册时间",
    text195: "总有效投注(元)",
    text196: "返利金(元)",
    // 个人资料
    text197: "个人资料",
    text198: "常用设备管理",
    text199: "（最多可绑定10个常用登陆设备）",
    text200: "本机",
    text201: "删除",
    text202: "暂无设备",
    // 资金密码
    text203: "资金密码",
    text204: "旧密码",
    text205: "请输入原资金密码",
    text206: "当前您所使用的资金密码",
    text207: "新密码",
    text208: "请输入新资金密码",
    text209: "密码6位数字组合",
    text210: "确认密码",
    text211: "请再次输入密码",
    text212: "必须与新密码一致",
    text213: "确定",
    text214: "确认密码不能为空",
    text215: "交易密码必须为6位数字",
    text216: "两次输入的密码不一致",
    text217: "新资金密码不能为空",
    text218: "交易密码必须为6位数字",
    text219: "原资金密码不能为空",
    // 账号密码
    text220: "账号密码",
    text221: "旧密码",
    text222: "请输入原登录密码",
    text223: "当前您所使用的登录密码",
    text224: "新密码",
    text225: "请输入新登录密码",
    text226: "密码6-15位,字母数字符号至少两种组合",
    text227: "确认密码",
    text228: "请再次输入新密码",
    text229: "必须与新密码一致",
    text230: "确认密码不能为空",
    text231: "两次输入的密码不一致",
    text232: "原登录密码不能为空",
    text233: "新登录密码不能为空",
    text234: "请重新登录",
    // tab
    text235: "安全验证",
    text236: "资金密码",
    text237: "登录密码",
    text238: "设备管理",
    // 返水详情页
    text239: "交易记录",
    text240: "返水详情",
    text241: "只可查询近一个月的交易记录，如需更多信息请联系客服查询",
    text242: "投注金额",
    text243: "时间筛选",
    text244: "时间",
    text245: "游戏平台",
    text246: "游戏名称",
    text247: "流水",
    text248: "返水",
    text249: "您查询时间已超过既定期限，如需更多信息请联系在线客服",
    // 交易记录
    text250: "交易记录",
    text251: "只可查询近一个月的交易记录，如需更多信息请联系客服查询",
    text252: "交易类型",
    text253: "交易时间",
    text254: "选择时间",
    text255: "复制",
    text256: "金额",
    text257: "状态",
    text258: "详情",
    text259: "充值记录",
    text260: "银行转账",
    text261: "提款记录",
    text262: "返水记录",
    text263: "其它",
    text264: "今日",
    text265: "昨日",
    text266: "近7日",
    text267: "近30日",
    text268: "支付方式",
    text269: "提款方式",
    text270: "返水类型",
    text271: "类型",
    text272: "您查询时间已超过既定期限，如需更多信息请联系在线客服",
    // 收款方式管理
    text273: "收款方式管理",
    text274: "添加银行卡/数字货币",
    text275: "不可重复添加相同的银行",
    // 基本信息
    text276: "账号密码",
    text277: "交易密码",
    text278: "管理设备",
    text279: "管理",
    text280: "修改",
    text281: "设置",
    // 信息编辑
    text282: "真实姓名",
    text283: "请输入真实姓名",
    text284: "会员生日",
    text285: "用户昵称",
    text286: "联系QQ",
    text287: "请输入用户昵称",
    text288: "手机号码",
    text289: "请输入手机号码",
    text290: "联系微信",
    text291: "请输入联系微信",
    text292: "会员邮箱",
    text293: "请输入会员邮箱",
    text294: "请输入用户昵称",
    // 个人中心
    text295: "普通用户",
    text296: "余额",
    text297: "佣金",
    text298: "返水领取",
    text299: "VIP等级",
    text300: "等级",
    text301: "达成条件",
    text302: "次",
    // 用户菜单
    text303: "用户信息",
    text304: "游戏记录",
    text305: "交易记录",
    text306: "公告消息",
    text307: "我的账户",
    text308: "活动记录",
    text309: "全民返利",
    text310: "存款",
    text311: "取款",
    text312: "彩金",
    text313: "返水额度不满足条件，无法领取！",
    text314: "密码",
    text315: "银行账户",
    text316: "语言",
    text317: "转移",
    text318: "会员总数",
    text319: "请创建您的提款方式",
    text320: "提款方式",
    text321: "创建提款方式",
    text322: "请验证手机号",
    text323: "手机短信验证码",
  },
  ...comp_zh_CN,
};
export default zh_CN;
