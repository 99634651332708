import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import en from "./lang/en";
import vi_VN from "./lang/vi_VN";
import zh_CN from "./lang/zh_CN";
import zh_HK from "./lang/zh_HK";
/*---------使用语言包-----------*/
let lang = window.locale || "vi-VN";
const locale =
  localStorage.getItem("locale") === "vi-VN"
    ? "vi_VN"
    : localStorage.getItem("locale") || lang;
const i18n = new VueI18n({
  locale, // 语言标识
  messages: {
    zh_CN, //中文简体
    zh_HK, //中文繁体
    en,
    vi_VN, //越南语
    en_US: en, //
  },
  silentTranslationWarn: true,
});
export default i18n;
