import Vue from 'vue'
import VueRouter from 'vue-router'
import StartUp from '../views/StartUp.vue'
import Wrap from '../views/Wrap.vue'

Vue.use(VueRouter)

const routes = [{ // 启动页
    path: '/',
    name: 'startUp',
    component: StartUp
},
{ // 维护页
    path: '/maintain',
    name: 'maintain',
    component: () =>
        import('../core/components/Maintain.vue')
},
{ // ip限制页
    path: '/iplimit',
    name: 'iplimit',
    component: () =>
        import('../core/components/Iplimit.vue')
},
{ // 财务中心-银行卡存款
    path: '/bankRecharge',
    name: 'bankRecharge',
    component: () =>
        import('../views/my/Finance/BankRecharge.vue'),
},
{
    path: '/index',
    name: 'index',
    component: Wrap,
    children: [{ // 主页
        path: '/home',
        name: 'home',
        component: () =>
            import('../views/wrap/home/index.vue')
    },
    // { // 注册页
    //     path: '/register',
    //     name: 'register',
    //     component: () =>
    //         import('../views/wrap/Register.vue')
    // },
    { // 登录注册
        path: '/register',
        name: 'register',
        component: () =>
            import('../views/wrap/Author.vue'),
    },
    { // vip
        path: '/vip',
        name: 'vip',
        component: () =>
            import('../views/wrap/VIP.vue')
    },
    { //存款提款说明
        path: '/guide',
        name: 'guide',
        component: () =>
            import('../views/wrap/Guide.vue')
    },
    { // 优惠活动
        path: '/promo',
        name: 'promo',
        component: () =>
            import('../views/wrap/Promo.vue'),
        meta: {
            title: 'promotions',
        }
    },
    { // 手机投注
        path: '/mobileBet',
        name: 'mobileBet',
        component: () =>
            import('../views/wrap/MobileBet.vue')
    },
    { // 活动详情
        path: '/promoInfo',
        name: 'promoInfo',
        component: () =>
            import('../views/wrap/PromoInfo.vue'),
        meta: {
            title: 'promotions',
        }
    },
    { // 电子游戏
        path: '/elec',
        name: 'elec',
        component: () =>
            import('../views/wrap/Elec.vue')
    },
    { // 
        path: '/poker',
        name: 'poker',
        component: () =>
            import('../views/wrap/Poker.vue'),
        meta: {
            title: 'poker',
        }
    },
    { // 
        path: '/sponsorships',
        name: 'sponsorships',
        component: () =>
            import('../views/wrap/Sponsorships.vue')
    },
    { // 
        path: '/help',
        name: 'help',
        component: () =>
            import('../views/wrap/HelpCenter.vue')
    },
    { // 
        path: '/affiliates',
        name: 'affiliates',
        component: () =>
            import('../views/wrap/Affiliates.vue')
    },
    { // affiliates
        path: '/ipl',
        name: 'ipl',
        component: () =>
            import('../views/wrap/ipl.vue')
    },
    { // 
        path: '/slots',
        name: 'slots',
        component: () =>
            import('../views/wrap/Slots.vue'),
        meta: {
            title: 'slots',
        }
    },
    { // 真人视讯
        path: '/live',
        name: 'live',
        component: () =>
            import('../views/wrap/Live.vue'),
        meta: {
            title: 'LIVE CASINO',
        }
    },
    { // 电子竞技
        path: '/gaming',
        name: 'gaming',
        component: () =>
            import('../views/wrap/Gaming.vue')
    },
    { // 体育赛事
        path: '/sport',
        name: 'sport',
        component: () =>
            import('../views/wrap/Sport.vue'),
        meta: {
            title: 'sports',
        }
    },
    { // 彩票
        path: '/lottery',
        name: 'lottery',
        component: () =>
            import('../views/wrap/Lottery.vue')
    },
    { // 棋牌
        path: '/chess',
        name: 'chess',
        component: () =>
            import('../views/wrap/Chess.vue')
    },
    { // 捕鱼
        path: '/fish',
        name: 'fish',
        component: () =>
            import('../views/wrap/Fish.vue')
    },
    {
        path: '/virtual',
        name: 'virtual',
        component: () =>
            import('../views/wrap/Virtual.vue')
    },
    {
        path: '/mobile-app',
        name: 'mobile-app',
        component: () =>
            import('../views/wrap/Mobile-app.vue'),
        meta: {
            title: 'APP DOWNLOAD',
        }
    },
    {
        path: '/help',
        name: 'help',
        component: () =>
            import('../views/wrap/Help.vue')
    },

    { // 个人中心
        path: '/my',
        name: 'my',
        component: () =>
            import('../views/my/My.vue'),
    },
    { // 个人资料
        path: '/userInfo',
        name: 'userInfo',
        component: () =>
            import('../views/my/UserInfo/UserInfo.vue'),
        meta: {
            title: 'User Info',
        }
    },
    { // 资金密码
        path: '/fundPass',
        name: 'fundPass',
        component: () =>
            import('../views/my/Safety/FundPass.vue'),
        meta: {
            title: 'fundPass',
        }
    },
    { // 账号密码
        path: '/password',
        name: 'password',
        component: () =>
            import('../views/my/Safety/Password.vue'),
        meta: {
            title: 'password',
        }
    },
    { // 设备管理
        path: '/devices',
        name: 'devices',
        component: () =>
            import('../views/my/Safety/Devices.vue'),
        meta: {
            title: 'devices',
        }
    },
    { // 彩金管理
        path: '/jackpot',
        name: 'jackpot',
        component: () =>
            import('../views/my/Jackpot/Jackpot.vue'),
            meta: {
                title: 'jackpot',
            }
    },
    { // 财务中心-会员存款
        path: '/deposit',
        name: 'deposit',
        component: () =>
            import('../views/my/Finance/Deposit.vue'),
            meta: {
                title: 'deposit',
            }
    },
    
    { // 财务中心-会员提款
        path: '/withdraw',
        name: 'withdraw',
        component: () =>
            import('../views/my/Finance/Withdraw.vue'),
        meta: {
            title: 'withdraws',
        }
    },
    // 归集
    {
        path: '/platformBalance',
        name: 'platformBalance',
        component: () =>
            import('../views/my/Finance/PlatformBalance.vue'),
        meta: {
            title: 'transfer',
        }
    },
    { // 收款方式管理
        path: '/bankCard',
        name: 'bankCard',
        component: () =>
            import('../views/my/BankCard/BankCard.vue'),
        meta: {
            title: 'bacnk accounts',
        }
    },
    { // 添加收款方式
        path: '/bankAdd',
        name: 'bankAdd',
        component: () =>
            import('../views/my/BankCard/BankAdd.vue'),
        meta: {
            title: 'bankAdd',
        }
    },
    { // 交易记录
        path: '/transRecord',
        name: 'transRecord',
        component: () =>
            import('../views/my/TransRecord/TransRecord.vue'),
        meta: {
            title: 'trans Record',
        }
    },
    { // 返水详情
        path: '/rebateInfo',
        name: 'rebateInfo',
        component: () =>
            import('../views/my/TransRecord/RebateInfo.vue'),
        meta: {
            title: 'rebate Info',
        }
    },
    { // 游戏记录
        path: '/gameRecord',
        name: 'gameRecord',
        component: () =>
            import('../views/my/GameRecord/GameRecord.vue'),
        meta: {
            title: 'bet history',
        }
    },
    { // 活动记录
        path: '/activeRecord',
        name: 'activeRecord',
        component: () =>
            import('../views/my/ActiveRecord/ActiveRecord.vue'),
        meta: {
            title: 'activeRecord',
        }
    },
    { // 公告和站内信
        path: '/notice',
        name: 'notice',
        component: () =>
            import('../views/my/Mail/Mail.vue'),
        meta: {
            title: 'messages',
        }
    },
    { // 全民返利
        path: '/rebate',
        name: 'rebate',
        component: () =>
            import('../views/my/Rebate/Rebate.vue'),
        meta: {
            title: 'rebate',
        }
    },
    { // 全民返利-邀请列表
        path: '/rebateList',
        name: 'rebateList',
        component: () =>
            import('../views/my/Rebate/RebateList.vue'),
        meta: {
            title: 'rebate',
        }
    },
    {
        path: '*',
        name: 'error404',
        component: () =>
            import('../views/Error404.vue')
    }

    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.afterEach(() => {
    // 首次进入在顶部
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0;
});
export default router