import comp_vi_VN from "../../core/il8n/lang/vi_VN";
const vi_VN = {
  name: "dữ liệu",
  // 借口返回错误码处理
  errorCode: {
    loading: "Đang tải...",
    webCode503: "Đang bảo trì",
    webDefault:
      "Phản hồi mạng không bình thường, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng để được tư vấn",
    "4001": "Ngoại lệ giao diện cuộc gọi từ xa",
    "401": "Tài khoản đăng nhập của bạn đã hết hạn, vui lòng đăng nhập lại",
    "503": "Bảo trì hệ thống",
    "0": "thành công! ",
    "1": "Thất bại! ",
    "500": "khác thường!",
    "1001": "Đăng nhập không thành công, không lấy được mã thông báo",
    "1012": "Mạng bất thường, vui lòng thử lại sau! ",
    "1101": "Người dùng không được phép đăng nhập",
    "1102": "Ủy quyền người dùng không khớp",
    "4011": "redis link exception",
    "4021": "không gửi được tin nhắn",
    "7001": "tham số ngoại lệ",
    "7002": "Ngoại lệ phân tích cú pháp dữ liệu",
    "7011": "Lỗi chèn tham số",
    "7101": "Dữ liệu cần được tổng hợp lại trống rỗng",
    "7102": "Id không được để trống",
    "7103": "IP hiện tại ở định dạng không phải CIDR",
    "7201": "Không thể khởi tạo mã xác minh",
    "7202": "Xác minh mã xác minh không thành công, vui lòng thử lại",
    "7203": "mã xác minh phải được điền",
    "7204": "Mã xác minh được gửi quá thường xuyên",
    "7301": "Tài khoản không tồn tại hoặc đã được kích hoạt, vui lòng nhập lại",
    "7302": "Tài khoản đã được kích hoạt, vui lòng không kích hoạt lại",
    "7303": "Tài khoản đang được kích hoạt, vui lòng không kích hoạt lại",
    "7304": "Tài khoản không tồn tại",
    "7305": "Mật khẩu tài khoản không chính xác, vui lòng nhập lại! 3 ",
    "7306":
      "Thời gian sửa đổi mật khẩu của bạn đã đạt đến giới hạn trên, vui lòng liên hệ với bộ phận dịch vụ khách hàng! ",
    "8000": "Không tìm thấy dữ liệu liên quan",
    "9980": "Sự kiện chưa bắt đầu",
    "9981": "Cấp độ người dùng không khớp",
    "9983": " Tên là bắt buộc! ",
    "9985": "Thông tin mức độ là bất thường! ",
    "9987": "Lưu ngoại lệ! ",
    "9990":
      "Cài đặt điều kiện kiểm tra tự động không bình thường, vui lòng xác nhận và thử lại! ",
    "9992": "Thao tác đã có hiệu lực, vui lòng không lặp lại thao tác",
    "9993":
      "Dữ liệu cài đặt mức không bình thường, vui lòng kiểm tra cài đặt mức và thử lại! ",
    "9994": "Không tìm thấy thông tin người dùng",
    "9996": "Người dùng không đáp ứng các yêu cầu hoạt động",
    "9997": "Sự kiện đã hết hạn",
    "10001": "Sự kiện chưa bắt đầu hoặc đã kết thúc",
    "10002": "Ngoại lệ hoạt động hẹn giờ",
    "10003": "Sự kiện này chỉ cho phép người chơi mới đăng ký tham gia",
    "10004": "Cấp độ thành viên của bạn không được phép tham gia sự kiện",
    "10031": "Không thể sửa đổi loại bản ghi",
    "10032": "Không thể sửa đổi loại bước nhảy của bản ghi này",
    "10033": "Không thể sửa đổi nội dung bước nhảy của bản ghi này",
    "10034": "Không thể sửa đổi mã cố định của bản ghi này",
    "10035": "Không thể xóa hồ sơ",
    "10062":
      "Người dùng đăng nhập bất thường không đáp ứng các yêu cầu hoạt động",
    "10091":
      "Không tham gia vào định dạng dữ liệu của nhà sản xuất là bất thường, định dạng dữ liệu là định dạng khóa-giá trị",
    "10501": "IP đã được thêm, không cần thêm lại",
    "10502": "Khi loại jump là liên kết ngoài, nội dung jump phải là http",
    "11001":
      "Đơn đăng ký của bạn đã được gửi, vui lòng đợi bộ phận chăm sóc khách hàng liên hệ",
    "11031": "Thêm ít nhất một cấp độ",
    "11061": "Chương trình đã được sử dụng và không thể đóng lại",
    "11091":
      "Tài khoản proxy có thể đã bị vô hiệu hóa, hoặc bị xóa hoặc cấp độ proxy không phải là proxy",
    "11501": "Tên tài khoản proxy đã tồn tại, vui lòng điền lại",
    "11531": "Chi tiết cấu hình hoa hồng trống",
    "11801": "Mã hóa số điện thoại không thành công, vui lòng thử lại sau",
    "12001": "Không lấy được liên kết trò chơi",
    "12002": "Không lấy được liên kết trò chơi",
    "12003": "Xóa không thành công, vẫn còn trò chơi dưới nhãn",
    "12104": "Trò chơi hoặc nhà sản xuất đã bị xóa",
    "12105": "Nhà sản xuất trò chơi vẫn chưa hỗ trợ xác nhận chuyển nhượng",
    "12208": "Không có dữ liệu nhóm giải thưởng",
    "12501":
      "queryType phải là một trong [nhà cung cấp hoặc các thẻ hoặc reportFee]",
    "12532": "Thời gian bắt đầu là bắt buộc",
    "12533": "Thời gian bắt đầu lớn hơn thời gian kết thúc",
    "12534": "Thời gian bắt đầu lớn hơn 60 ngày",
    "12565": "GameKindCode không được để trống",
    "12566": "id của người dùng không khớp với id của thành viên",
    "12592": "Nhà cung cấp trò chơi không có Mã nhà cung cấp",
    "12597": "Thông tin nhà sản xuất không được để trống",
    "13001": "Người dùng không tồn tại",
    "13002": "Không thể lưu cơ sở dữ liệu trong khi đăng ký người dùng",
    "13003": "Kiểm tra người dùng không thành công",
    "13004": "Trạng thái người dùng không bình thường",
    "13006": "thành viên không tồn tại",
    "13007":
      "Tài khoản của bạn không bị ràng buộc với số điện thoại di động, vui lòng liên hệ với bộ phận dịch vụ khách hàng",
    "13008":
      "Tài khoản đã bị đóng băng, vui lòng liên hệ với bộ phận chăm sóc khách hàng",
    "13009":
      "Tài khoản đã bị vô hiệu hóa, vui lòng liên hệ với bộ phận dịch vụ khách hàng",
    "13010":
      "Do cập nhật phiên bản tài khoản của bạn vượt quá giới hạn ký tự, vui lòng liên hệ với dịch vụ khách hàng trực tuyến",
    "130011":
      "Nếu tên người dùng hoặc mật khẩu sai, vui lòng nhập lại hoặc liên hệ với dịch vụ khách hàng để xử lý",
    "130012": "Tên đăng kí đã được sử dụng",
    "13501": "Số điện thoại đã tồn tại",
    "13502": "Không được để trống id tên người dùng",
    "13503": "Không tìm thấy tên người dùng của số điện thoại di động",
    "13801": "Đăng ký người dùng không thành công",
    "13802": "Cập nhật người dùng không thành công",
    "13803": "Cập nhật người dùng cuộc gọi từ xa không thành công",
    "13901":
      "Cài đặt đăng ký thành viên không bình thường, vui lòng liên hệ với bộ phận chăm sóc khách hàng! ",
    "13902":
      "Số lần mật khẩu được truy xuất trong nền được định cấu hình không chính xác",
    "14001": "Chuyển người dùng không thành công",
    "14002": "Tự động chuyển không thành công",
    "14003": "Giá trị của hai lần kiểm tra đã thay đổi",
    "14031": "Lệnh chưa được khóa, vui lòng khóa trước khi hoạt động",
    "14032": "Lệnh đã bị khóa bởi người khác và bạn không thể vận hành nó! ",
    "14033": "Tình trạng đơn hàng không đạt yêu cầu! ",
    "14034":
      "Gửi không thành công, tỷ giá hối đoái đã thay đổi, vui lòng kiểm tra và xác nhận số tiền và gửi lại! ",
    "14061": "Yêu cầu rút tiền đã được gửi, vui lòng không gửi lại",
    "14062": "Ngoại lệ rút tiền",
    "14091": "Bạn đã ràng buộc thẻ ngân hàng này",
    "14092": "Tên tài khoản thẻ ngân hàng không được để trống",
    "14093": "Thẻ ngân hàng bị ràng buộc đã đạt đến giới hạn trên",
    "14094":
      "Tên của thẻ ngân hàng không phù hợp với tên thật của tài khoản của bạn",
    "14095": "Số lượng loại ví bị ràng buộc đã đạt đến giới hạn trên",
    "14096":
      "Thẻ ngân hàng tạm thời không thể bị xóa, vui lòng liên hệ với bộ phận chăm sóc khách hàng để xử lý, cảm ơn",
    "14097": "Thẻ ngân hàng hoặc ví điện tử đã bị ràng buộc, vui lòng nhập lại",
    "14098":
      "Tài khoản của bạn bị vô hiệu hóa, vui lòng liên hệ với bộ phận dịch vụ khách hàng! ",
    "14099":
      "Tài khoản ví của bạn bị vô hiệu hóa, vui lòng liên hệ với bộ phận dịch vụ khách hàng! ",
    "14100":
      "Số tài khoản bạn đã nhập không chính xác, vui lòng liên hệ với bộ phận chăm sóc khách hàng! ",
    "14101": "Vui lòng nhập số ID ví có độ dài 6 chữ số, cảm ơn",
    "14501": "Người gửi tiền không được để trống",
    "14502": "Mã và tên nền tảng thanh toán là bắt buộc",
    "14503": "Không tìm thấy người dùng Wallet",
    "14504": "Số tiền rút một lần của bạn dưới giới hạn dưới:",
    "14505": "Số tiền rút một lần của bạn cao hơn giới hạn trên:",
    "14506": "Số tiền gửi phải lớn hơn 0",
    "14801": "Cập nhật không thành công",
    "14901": "Khóa thanh toán chưa được đặt",
    "14902": "URL để lấy danh sách thanh toán chưa được đặt",
    "14903": "URL để nhận thứ tự đồng bộ hóa chưa được đặt",
    "14904": "URL của lệnh gọi lại không được đặt",
    "14905":
      "Cuộc gọi lại nạp tiền CALLBACK của tiền tệ kỹ thuật số không được thiết lập",
    "14906": "Khóa thanh toán của người bán này chưa được đặt",
    "14907": "Cài đặt từ điển liên quan đến thanh toán bị sai",
    "15001": "Bộ sưu tập không thành công",
    "15002": "Nhận ngoại lệ",
    "15034": "Lấy thông tin ngoại lệ về người thuê nhà",
    "15035":
      "Dữ liệu đã được thay đổi, hãy quay lại giao dịch theo cách thủ công",
    "15036": "Đã đạt đến giới hạn tham gia",
    "15037": "Tổng số tiền tham gia đã đạt đến giới hạn trên",
    "15038": "Một số tiền đã vượt quá giới hạn trên",
    "15061": "Chức năng hoàn tiền quốc gia chưa được mở",
    "15062":
      "Số tiền bồi hoàn chưa có người nhận nhỏ hơn số tiền yêu cầu bồi thường tối thiểu",
    "15063": "Không gửi lại",
    "15064": "Nhận các khoản giảm giá",
    "15091": "Số dư đang được thu thập, không cần truy vấn! ! ! ",
    "15121": "Dịch vụ ví không bình thường",
    "15151": "Đơn hàng đã được xử lý",
    "15181": "Không thể dọn sạch vùng đóng băng",
    "15501": "Số tiền chuyển phải lớn hơn 1",
    "15502": "Số tiền chuyển phải lớn hơn 0",
    "15503": "Chế độ chuyển nhượng không có giá trị pháp lý",
    "15504": "Số dư cố định không đủ",
    "15801": "Không thể chuyển ví trung tâm đến đại lý trò chơi! ",
    "15802":
      "Việc chuyển khoản của người bán trò chơi đến ví trung tâm không thành công! ",
    "15901": "Khoản bồi hoàn không được định cấu hình",
    "16001": "Bảo trì nền tảng",
    "16002": "Nền tảng không tồn tại",
    "16003":
      "Tài khoản đã được đăng nhập trên thiết bị khác, vui lòng đăng nhập lại",
    "17007": "Tên ngân hàng hoặc đơn vị tiền tệ không được để trống",
    "17008": "Số thẻ hoặc địa chỉ ví không được để trống",
    "17009": "Tên ngân hàng hoặc đơn vị tiền tệ không được để trống",
    "17010": "Chi nhánh hoặc liên kết không được để trống",
    "17011": "id thành viên không được để trống",
    "100661": "Yêu cầu đăng nhập của bạn đã được gửi, vui lòng không gửi lại",
    "110004": "Token không hợp lệ! ",
    "110007": "Người dùng không được phép đăng nhập! ",
    "110009": "IP hiện tại bị hạn chế",
    "110010": "Yêu cầu quá thường xuyên, vui lòng thử lại sau",
    "120001": "Người bán không tồn tại",
    "120004": "Có giá trị rỗng trong tham số yêu cầu",
    "120014": "Ví trung tâm của người dùng không tồn tại",
    "120017": "Cấp độ thành viên không tồn tại",
    "120021": "Số đơn hàng đã tồn tại:",
    "120024": "Thông tin đặt hàng không khớp",
    "120025": "Mẫu thanh toán không khớp",
    "120026":
      "Trạng thái của đơn đặt hàng đã được hoàn tất và bạn không thể vận hành nó! ! ",
    "120027": "Lần rút tiền cuối cùng của bạn chưa được xử lý, hãy kiên nhẫn! ",
    "120029":
      "Các đơn đặt hàng cần được kiểm soát rủi ro phê duyệt trước khi bạn có thể hoạt động! ",
    "120031": "Số tiền rút hàng ngày của bạn đã hết:",
    "120032":
      "Ví đã bị vô hiệu hóa, vui lòng liên hệ với bộ phận dịch vụ khách hàng! ",
    "120050": "Cảnh báo nghiêm trọng: Mật khẩu không khớp cho người dùng này",
    "120051": "Số dư của bạn không đủ để rút tiền mặt! ",
    "120055": "Lỗi khi tạo giá trị bí mật",
    "120056": "Mật khẩu rút tiền của bạn sai! ",
    "120057": "Cập nhật dữ liệu ví không thành công, phiên bản không khớp",
    "120070": "Số dư của người dùng không đủ",
    "120093": "Nhận thường xuyên, vui lòng thử lại sau! ! ! ",
    "120561":
      "Có vấn đề với mã lời mời bạn đã điền (người dùng proxy không tồn tại hoặc chưa được kích hoạt), vui lòng xác minh và gửi lại đơn đăng ký",
    "130000":
      "Không tìm thấy tỷ giá hối đoái của đơn vị tiền tệ này trên liên kết, vui lòng thay đổi đơn vị tiền tệ",
    "999999": "Ngoại lệ thanh toán",
    "1000":
      "Lỗi không xác định, nếu có tình huống bất thường, vui lòng liên hệ với bộ phận chăm sóc khách hàng để được tư vấn",
    "130013": "Mật khẩu ban đầu sai, sửa đổi không thành công",
  },
  // 提示
  tipsMessage: {
    text1: "vui lòng đăng nhập trước",
  },
  // 首页index
  homePageIndex: {
    text1: "Giờ Bắc Kinh",
    text2: "Cá cược trên thiết bị di động",
    text3: "Cá cược dễ dàng mọi lúc, mọi nơi",
    text4: "Cược DI ĐỘNG",
    text5:
      "Hỗ trợ IOS & Android tất cả các thiết bị di động Các thiết bị di động vui lòng truy cập:",
    text6:
      "Nền tảng cá cược di động của chúng tôi dành cho người chơi trên toàn mạng, cung cấp gần 100 máy đánh bạc và đặt cược trò chơi xổ số, gửi tiền trực tuyến và rút tiền trực tuyến, thao tác một cú nhấp chuột, để đạt được khái niệm giải trí không bị gián đoạn mọi lúc, mọi nơi.",
    text7: "trò chơi điện tử",
    text8: "Hàng triệu giải thưởng đang chờ bạn",
    text9: "vấn đề thường gặp",
    text10: "Đơn giản và thuận tiện, dễ sử dụng",
    text11: "đại lý ứng dụng",
    text12: "Tham gia đại lý, thu nhập hàng tháng là một triệu",
    text13: "Cách gửi tiền",
    text14: "Nhanh chóng và dễ dàng, tiền gửi miễn phí",
    text15: "Cách rút tiền",
    text16: "Số tiền lớn không phải lo lắng, rút tiền bất cứ lúc nào",
    text17: "Email dịch vụ khách hàng",
    text18: "Dịch vụ khách hàng QQ",
    text19: "Dịch vụ khách hàng WeChat",
    text20: "dịch vụ trực tuyến",
    text21: "Dịch vụ chăm sóc trực tuyến 7x24 giờ",
    text22: "Mã QR QQ",
    text23: "Mã QR wechat",
    text24: "về chúng tôi",
    text25: "Trợ giúp Gửi tiền",
    text26: "Trợ giúp Rút tiền",
    text27: "Quy tắc và Tuyên bố",
    text28: "vấn đề thường gặp",
    text29: "Trung tâm đại lý",
    text30: "Bản quyền © bwin Reserved @ v2.0.1 - 009",
    text31: "trang đầu",
    text32: "Khuyến mại",
    text33: "trò chơi điện tử",
    text34: "Video thực tế",
    text35: "eSports",
    text36: "Sự kiện thể thao",
    text37: "trò chơi xổ số",
    text38: "trò chơi cờ vua",
    text39: "trò chơi câu cá",
    text40: "Ứng dụng gốc thích thú với đầu ngón tay",
    text41: "Cá cược thể thao, video trực tiếp, dễ nắm bắt",
    text42:
      "APP gốc giải trí tích hợp đầu tiên trên thế giới, tận hưởng hoạt động mượt mà, hoàn hảo, lớn",
    text43:
      "Thể thao, các sự kiện thể thao điện tử hàng đầu, giải trí trực tiếp, cá cược xổ số và trò chơi điện tử, v.v.",
    text44:
      "Các hạng mục giải trí mới nhất và đầy đủ nhất bạn có thể trải nghiệm trong lòng bàn tay, quét code tải về là có ngay!",
    text45: "Quét mã để tải xuống APP",
    text46: "Hỗ trợ iOS và Android",
    text47: "Truy cập trực tiếp mà không cần tải xuống",
    text48: "Live streaming",
    text49: "Bạn có muốn ràng buộc một thẻ ngân hàng không? ",
    text50: "Bạn chưa ràng buộc thẻ ngân hàng",
    text51: "Chắc chắn rồi",
    text52: "Hủy bỏ",
  },
  // 首页登录
  homePageLogin: {
    text1: "thông tin cá nhân",
    text2: "phương thức thanh toán",
    text3: "Bản ghi trò chơi",
    text4: "Ghi lại giao dịch",
    text5: "tin tức chính thức",
    text6: "đăng xuất",
    text7: "Đăng nhập",
    text8: "Đăng ký",
    text9: "tài khoản",
    text10: "mật khẩu mở khóa",
    text11: "Lấy lại mật khẩu",
    text12: "dịch vụ trực tuyến",
    text13: "Xác thực ngoại lệ",
    text14:
      "Hệ thống phát hiện khu vực đăng nhập tài khoản của bạn bất thường, vui lòng điền tên thật của tài khoản để xác minh!",
    text15: "vui lòng nhập tên thật của bạn",
    text16: "Gửi xác minh",
    text17: "xác minh an toàn",
    text18:
      "Bạn đang đăng nhập bằng một thiết bị mới. Để bảo mật cho tài khoản của bạn, vui lòng thực hiện xác minh bảo mật",
    text19: "Vui lòng nhập số điện thoại di động của bạn",
    text20: "Vui lòng nhập mã xác minh SMS",
    text21: "lấy mã xác minh",
    text22: "Gửi xác minh",
    text23: "Làm mới thành công",
    text24: "Đã đăng xuất",
    text25: "vui lòng nhập tên thật của bạn",
    text26: "Số điện thoại không hợp lệ",
    text27: "Vui lòng nhập mã xác minh SMS",
  },
  // 活动
  promoInfo: {
    text1: "Tất cả phiếu mua hàng",
    text2: "Hoạt động dài hạn",
    text3: "Sự kiện trong thời gian có hạn",
    text4: "Không có hoạt động nào vào lúc này",
    text5: "đến",
    text6: "Liên hệ với dịch vụ khách hàng",
    text7: "Tham gia ngay",
    text8: "Trạng thái hoạt động:",
    text9: "Xem nhật ký hoạt động",
    text10: "Trạng thái hoạt động: Hoạt động đã chết",
    text11: "Xem nhật ký hoạt động",
    text12: "Bản ghi hoạt động",
    text13: "ngày",
    text14: "Điều kiện hoàn thành",
    text15: "điều kiện hoàn thành",
  },
  // 游戏二级页面
  gamePage: {
    text0: "Tất cả trò chơi",
    text1: "Bắt đầu chơi",
    text2: "Đề xuất hàng đầu",
    text3: "Bắt đầu chơi",
    text4: "Vui lòng nhập tên trò chơi",
    text5: "vào trò chơi",
    text6: "Liên Minh Huyền Thoại",
    text7: "Danh dự của các vị vua",
    text8: "DOTA 2",
    text9: "CS GO",
    text10: "Hearthstone",
    text11: "Overwatch",
    text12: "FIFA",
    text13:
      "Truyền hình trực tiếp độ nét cao cho phép bạn trải nghiệm các môn thể thao thú vị",
    text14:
      "Bao quát đầy đủ các sự kiện hàng đầu thế giới, cung cấp dữ liệu tỷ lệ cược chuyên nghiệp và dữ liệu kết quả dàn xếp. Có hàng đầu",
    text15:
      "Hỗ trợ kỹ thuật để cung cấp cho người chơi giao diện hình ảnh tuyệt vời và trải nghiệm người dùng tuyệt vời nhất.",
    text16: "Đại lý gợi cảm · Trưng bày",
    text17:
      "Người tập hợp đẹp nhất trên toàn bộ mạng, với sự tham gia của các mỹ nhân đến từ bảy quốc gia, giải trí độc đáo và chế độ kép chuyên nghiệp, độ phân giải cực cao",
    text18:
      "Bữa tiệc hình ảnh, thể loại trò chơi đa dạng, mang đến cho bạn trải nghiệm chơi game thoải mái nhất.",
    text19:
      "Trò chơi giải trí trực tuyến trực tiếp mới nhất và hấp dẫn nhất, trò chơi baccarat cổ điển, micrô, bảng trọn gói để lựa chọn, phụ nữ xinh đẹp chẳng hạn như",
    text20:
      "Tương thích với nhiều thiết bị đầu cuối đám mây, mang lại cho bạn trải nghiệm chơi game mượt mà nhất.",
    text21: "Baccarat",
    text22: "cò quay",
    text23: "Tài Xỉu",
    text24: "Rồng hổ",
    text25: "Con bò",
    text26: "bông hoa chiên vàng",
    text27: "Ba công tước",
    text28: "Trải nghiệm những phút hồi hộp",
    text29:
      "Nền tảng hàng đầu hàng đầu, các loại màu phổ biến nhất, lối chơi đa dạng và hoạt động đơn giản",
    text30: "Trợ giúp thời gian thực, tiếp tục thêm may mắn!",
    text31: "Tận hưởng thế giới tuyệt vời",
    text32: "Tỷ lệ cược siêu cao, mọi lúc, Mark Six và Bắc Kinh đua",
    text33:
      "Cam kết tạo ra một môi trường giải trí chất lượng cao cho người chơi.",
    text34: "Đua xe tốc độ",
    text35: "Đua xe Bắc Kinh",
    text36: "Màu thời gian",
    text37: "Năm từ Mười một",
    text38: "Mark Six",
    text39: "Một cách mới để chơi · cảm thấy tuyệt vời",
    text40:
      "Một loạt các sự kiện thể thao luôn sẵn sàng và các trò chơi được chơi theo nhiều cách khác nhau. Hiệp hai táo bạo và bóng mới chơi",
    text41: "Fa, cảm thấy khác biệt và tuyệt vời",
    text42: "Tiền mặt sớm · Cắt lỗ đúng lúc",
    text43:
      "Các chức năng sáng tạo được tính trước tiền mặt, bạn có thể thanh toán trước mà không cần chờ đợi và cơ chế cắt lỗ cho phép bạn không có điều gì cấm kỵ",
    text44: "bóng đá",
    text45: "bóng rổ",
    text46: "bóng chuyền",
    text47: "bóng chày",
    text48: "quần vợt",
    text49: "bóng bàn",
    text50: "cầu lông",
  },
  // 用户中心
  userPage: {
    // 活动记录
    text1: "Bản ghi hoạt động",
    text2:
      "Dữ liệu của mỗi hoạt động sẽ có độ trễ thời gian nhất định, chỉ mang tính chất tham khảo",
    text3: "Tên sự kiện",
    text4: "Thời gian hoạt động",
    text5: "năm tháng ngày",
    text6: "Hỏi thăm",
    text7: "cài lại",
    text8: "Tên sự kiện",
    text9: "Thời gian tham gia",
    text10: "vận hành",
    text11: "kiểm tra chi tiết",
    text12: "Không một bản ghi nào ...",
    text13: "Vui lòng nhập tên sự kiện",
    // 收款方式管理
    text14: "Quản lý Thanh toán",
    text15: "Thêm phương thức thanh toán",
    text16: "tên tài khoản",
    text17: "Tên ngân hàng",
    text18: "Chọn ngân hàng",
    text19: "Tên chi nhánh",
    text20: "Vui lòng nhập tên chi nhánh",
    text21: "Số thẻ ngân hàng",
    text22: "Vui lòng nhập số thẻ ngân hàng",
    text23: "Xác nhận số thẻ",
    text24: "Vui lòng nhập lại số thẻ ngân hàng",
    text25: "Số điện thoại",
    text26: "Mã xác nhận",
    text27: "vui lòng nhập mã xác nhận",
    text28: "Gửi mã xác minh",
    text29: "tên tài khoản",
    text30: "tên đơn vị tiền tệ",
    text31: "liên kết",
    text32: "Vui lòng nhập ERC20 hoặc TRC20",
    text33: "Địa chỉ ví",
    text34: "Vui lòng nhập địa chỉ ví",
    text35: "Xác nhận địa chỉ",
    text36: "Vui lòng nhập lại địa chỉ ví",
    text37: "Số điện thoại",
    text38: "tiền tệ",
    text39: "Chọn đơn vị tiền tệ",
    text40: "ID tài khoản Wallet",
    text41: "Vui lòng nhập ID ví origo",
    text42: "Xác nhận Tài khoản",
    text43: "Vui lòng nhập lại ID ví origo",
    text44: "Chắc chắn rồi",
    text45: "trở về",
    text46: "Thẻ ngân hàng",
    text47: "Tiền tệ kỹ thuật số",
    text48: "ví origo",
    text49: "Chưa có thẻ ngân hàng",
    text50: "Thêm thẻ ngân hàng",
    text51: "Thêm tiền kỹ thuật số",
    text52: "Thêm ví origo",
    // 公告
    text53: "Để ý",
    text54: "sự thông báo",
    // 银行卡
    text55: "Thanh toán bằng thẻ",
    text56: "Chi tiết Thanh toán",
    text57: "Tên tài khoản người nhận thanh toán",
    text58: "Ngân hàng thụ hưởng",
    text59: "Số thẻ thanh toán",
    text60: "Ngân hàng Tài khoản - Thông tin chi nhánh phụ",
    text61: "Mẹo tử tế",
    text62: "1. Vui lòng điền chính xác số thẻ và số tiền nạp.",
    text63:
      "2. Vui lòng xác nhận rằng thanh toán đã được thực hiện sau khi chuyển khoản qua thẻ ngân hàng thành công.",
    text64:
      "3. Thời gian đến phụ thuộc vào thời gian xử lý của ngân hàng, hãy kiên nhẫn.",
    text65: "4. Vui lòng không trả hai lần.",
    // 存款
    text66: "đặt cọc",
    text67: "phương thức thanh toán",
    text68: "Tiền gửi Công ty",
    text69: "Gửi tiền Trực tuyến",
    text70: "Tiền tệ kỹ thuật số",
    text71: "VIP độc quyền",
    text72: "quà tặng",
    text73: "Kênh nạp tiền",
    text74: "Mẹo tử tế",
    text75:
      "1. Vui lòng chú ý đến số tiền chuyển tối thiểu được hiển thị trên trang, những lần nạp ít hơn số tiền nạp tối thiểu sẽ không được ghi có và không thể trả lại",
    text76:
      "2. Sau khi bạn nạp tiền đến địa chỉ trên, bạn cần có xác nhận của toàn bộ nút mạng. Hãy kiên nhẫn chờ đợi. Sau khi nhận được tiền kỹ thuật số, chúng tôi sẽ ghi có ngay số tiền tương ứng vào tài khoản của bạn. Nếu bạn chưa nhận được Lâu lâu có thể liên hệ bộ phận chăm sóc khách hàng để được tư vấn ",
    text77:
      "3. Hãy nhớ kiểm tra đơn vị tiền tệ và tên chuỗi khi chuyển tiền. Nếu bạn gửi sai tài sản, bạn sẽ không thể lấy lại được",
    text78:
      "4. Tỷ giá hối đoái hiển thị trên trang chỉ mang tính chất tham khảo. Do tỷ giá hối đoái biến động, tài khoản sẽ được tính dựa trên tỷ giá hối đoái mới nhất",
    text79: "Tên tiền gửi",
    text80: "Tài khoản chưa đến kịp, vui lòng nhập đúng tên tiền gửi",
    text81: "Số tiền nạp",
    text82: "Số tiền gửi tối thiểu duy nhất",
    text83: "Số tiền gửi tối đa duy nhất",
    text84: "Vui lòng nhập tên khoản tiền gửi",
    text85: "Vui lòng nhập số tiền hợp pháp",
    text86: "Số tiền nạp không nằm trong phạm vi",
    text87:
      "Kênh không bình thường, vui lòng liên hệ với bộ phận chăm sóc khách hàng!",
    //短信验证弹窗
    text88: "Vui lòng xác minh số điện thoại di động của bạn để rút tiền",
    text89: "điện thoại di động",
    text90: "Vui lòng nhập số điện thoại",
    text91: "Mã xác nhận",
    text92: "vui lòng nhập mã xác nhận",
    text93: "Gửi mã xác minh",
    text94: "Chắc chắn rồi",
    text95: "Không được để trống số điện thoại",
    text96: "Xin vui lòng nhập một số điện thoại hợp lệ",
    text97: "mã xác minh phải được điền",
    text98: "Số điện thoại không đúng định dạng",
    // 取款
    text99: "Rút tiền",
    text100: "Giới hạn tài khoản chính",
    text101: "Nhân dân tệ",
    text102: "Bộ sưu tập một cú nhấp chuột",
    text103: "nồng độ",
    text104: "phương thức thanh toán",
    text105: "Thêm phương thức thanh toán",
    text106: "Bước tiếp theo",
    text107: "phương thức thanh toán",
    text108: "Số tiền rút",
    text109: "Số tiền rút hiện tại",
    text110: "tất cả các",
    text111: "Số tiền Biên nhận Thực tế",
    text112: "Số tiền Biên nhận Thực tế",
    text113: "Phí rút tiền",
    text114: "Chi phí quản lý",
    text115: "Khấu trừ ưu đãi",
    text116: "Mật khẩu giao dịch",
    text117: "Vui lòng nhập mật khẩu của bạn",
    text118: "Trước",
    text119: "Chắc chắn rồi",
    text120: "Vui lòng chọn phương thức rút tiền",
    text121: "Làm mới thành công",
    text122: "Số tiền rút không được để trống!",
    text123: "Mật khẩu giao dịch không được để trống!",
    text124: "Ứng dụng thành công, vui lòng đợi nền tảng xem xét!",
    // 游戏记录
    text125: "Bản ghi trò chơi",
    text126:
      "Dữ liệu của mỗi lần đặt cược sẽ có độ trễ thời gian nhất định và chỉ mang tính chất tham khảo",
    text127: "nền tảng chơi game",
    text128: "Số tiền đặt cược",
    text129: "bộ lọc thời gian",
    text130: "Hỏi thăm",
    text131: "cài lại",
    text132: "số đơn hàng",
    text133: "nền tảng chơi game",
    text134: "tên trò chơi",
    text135: "Đặt cược thời gian",
    text136: "Thời gian thanh toán",
    text137: "Số tiền đặt cược",
    text138: "Cược hợp lệ",
    text139: "Số tiền thanh toán",
    text140: "Số tiền lãi và lỗ",
    text141: "Tổng tiền cược",
    text142: "Tổng số tiền đặt cược hiệu quả",
    text143: "Tổng thanh toán",
    text144: "Tổng số tiền lãi và lỗ",
    // 彩金
    text146: "Thưởng",
    text147: "Biên nhận hết hạn",
    text148: "Đang tải",
    text149: "Đến cuối cùng",
    text150: "Không có dữ liệu",
    text151: "Yêu cầu tiền thưởng",
    text152: "Yêu cầu về luồng",
    text153: "nếp gấp",
    text154: "Hủy bỏ",
    text155: "lấy nó ngay bây giờ",
    text156: "tất cả các",
    text157: "Có sẵn",
    text158: "Nhận",
    text159: "hết hạn",
    // 公告消息
    text160: "Thông báo thông báo",
    text161: "Không một thông báo nào ...",
    text162: "Không một thông báo nào ...",
    // 全民返利
    text163: "Giảm giá quốc gia",
    text164: "mô tả quy tắc",
    text165: "Giảm giá tích lũy",
    text166: "Đầy",
    text167: "Nhân dân tệ, và thành viên hợp lệ≥",
    text168: "Mọi người có thể nhận được nó",
    text169: "nhận",
    text170: "thành viên",
    text171: "Thành viên tích cực",
    text172: "Mã khuyến mại của tôi",
    text173: "Tải xuống mã khuyến mại",
    text174: "Mã lời mời của tôi",
    text175: "Sao chép mã lời mời",
    text176: "Địa chỉ Quảng cáo của Tôi",
    text177: "Sao chép địa chỉ",
    text178: "mô tả quy tắc",
    text179: "Nhận tiền hoàn lại",
    text180: "Yêu cầu về luồng",
    text181: "Hủy bỏ",
    text182: "Chắc chắn rồi",
    text183:
      "Yêu cầu xác nhận quyền sở hữu không được đáp ứng, số tiền yêu cầu tối thiểu",
    text184: "Nhân dân tệ, số lượng thành viên hợp lệ≥",
    text185: "Mọi người, đạt được giới hạn trên",
    text186: "Đã nhận thành công",
    text187: "Không nhận được",
    // 邀请会员
    text188: "Mời thành viên",
    text189: "Tổng tiền cược",
    text190: "Tổng số tiền chiết khấu được tích lũy",
    text191: "Thời gian đăng ký:",
    text192: "thời gian lựa chọn:",
    text193: "tài khoản thành viên",
    text194: "Thời gian đăng ký",
    text195: "Tổng Cược Hiệu quả (Nhân dân tệ)",
    text196: "Hoàn trả (nhân dân tệ)",
    // 个人资料
    text197: "thông tin cá nhân",
    text198: "Quản lý thiết bị chung",
    text199: "(Có thể liên kết tối đa 10 thiết bị đăng nhập chung)",
    text200: "tự nhiên",
    text201: "xóa bỏ",
    text202: "Hiện không có thiết bị",
    // 资金密码
    text203: "Mật khẩu quỹ",
    text204: "Mật khẩu cũ",
    text205: "Vui lòng nhập mật khẩu quỹ ban đầu",
    text206: "Mật khẩu hiện tại của quỹ bạn đang sử dụng",
    text207: "mật khẩu mới",
    text208: "Vui lòng nhập mật khẩu quỹ mới",
    text209: "Mật khẩu kết hợp 6 chữ số",
    text210: "Xác nhận mật khẩu",
    text211: "Vui lòng nhập lại mật khẩu của bạn",
    text212: "phải khớp với mật khẩu mới",
    text213: "Chắc chắn rồi",
    text214: "xác nhận mật khẩu không được để trống",
    text215: "Mật khẩu giao dịch phải có 6 chữ số",
    text216: "Mật khẩu được nhập hai lần không khớp",
    text217: "Mật khẩu quỹ mới không được để trống",
    text218: "Mật khẩu giao dịch phải có 6 chữ số",
    text219: "Mật khẩu quỹ ban đầu không được để trống",
    // 账号密码
    text220: "mật khẩu tài khoản",
    text221: "Mật khẩu cũ",
    text222: "Vui lòng nhập mật khẩu đăng nhập ban đầu",
    text223: "Mật khẩu đăng nhập hiện tại của bạn",
    text224: "mật khẩu mới",
    text225: "Vui lòng nhập mật khẩu đăng nhập mới",
    text226: "Mật khẩu có 6-15 chữ số, ít nhất hai tổ hợp ký hiệu chữ và số",
    text227: "Xác nhận mật khẩu",
    text228: "Vui lòng nhập lại mật khẩu mới",
    text229: "phải khớp với mật khẩu mới",
    text230: "xác nhận mật khẩu không được để trống",
    text231: "Mật khẩu được nhập hai lần không khớp",
    text232: "Mật khẩu đăng nhập ban đầu không được để trống",
    text233: "Mật khẩu đăng nhập mới không được để trống",
    text234: "xin vui lòng đăng nhập lại",
    // tab
    text235: "xác minh an toàn",
    text236: "Mật khẩu quỹ",
    text237: "mật khẩu đăng nhập",
    text238: "Quản lý thiết bị",
    // 返水详情页
    text239: "Ghi lại giao dịch",
    text240: "Chi tiết giảm giá",
    text241:
      "Chỉ có thể hỏi hồ sơ giao dịch của tháng trước. Để biết thêm thông tin chi tiết, vui lòng liên hệ bộ phận chăm sóc khách hàng để hỏi",
    text242: "Số tiền đặt cược",
    text243: "bộ lọc thời gian",
    text244: "thời gian",
    text245: "nền tảng chơi game",
    text246: "tên trò chơi",
    text247: "nước chảy",
    text248: "Trở về",
    text249:
      "Thời gian yêu cầu của bạn đã vượt quá thời hạn thiết lập, nếu bạn cần thêm thông tin, vui lòng liên hệ với bộ phận chăm sóc khách hàng trực tuyến",
    // 交易记录
    text250: "Ghi lại giao dịch",
    text251:
      "Chỉ có thể hỏi hồ sơ giao dịch của tháng trước. Để biết thêm thông tin chi tiết, vui lòng liên hệ bộ phận chăm sóc khách hàng để hỏi",
    text252: "Loại giao dịch",
    text253: "giờ giao dịch",
    text254: "khoảng thời gian lựa chọn",
    text255: "sao chép",
    text256: "số lượng",
    text257: "tiểu bang",
    text258: "thông tin chi tiết",
    text259: "Bản ghi nạp tiền",
    text260: "Chuyển khoản ngân hàng",
    text261: "Lịch sử Rút tiền",
    text262: "Trả lại hồ sơ",
    text263: "khác",
    text264: "hôm nay",
    text265: "hôm qua",
    text266: "7 ngày qua",
    text267: "30 ngày trước",
    text268: "phương thức thanh toán",
    text269: "Phương thức rút tiền",
    text270: "Loại giảm giá",
    text271: "loại hình",
    text272:
      "Thời gian yêu cầu của bạn đã vượt quá thời hạn thiết lập, nếu bạn cần thêm thông tin, vui lòng liên hệ với bộ phận chăm sóc khách hàng trực tuyến",
    // 收款方式管理
    text273: "Quản lý Thanh toán",
    text274: "Thêm thẻ ngân hàng / tiền tệ kỹ thuật số",
    text275: "Không thể thêm nhiều lần cùng một ngân hàng",
    // 基本信息
    text276: "mật khẩu tài khoản",
    text277: "Mật khẩu giao dịch",
    text278: "Quản lý thiết bị",
    text279: "quản lý",
    text280: "Ôn lại",
    text281: "cài đặt",
    // 信息编辑
    text282: "tên thật",
    text283: "vui lòng nhập tên thật của bạn",
    text284: "sinh nhật thành viên",
    text285: "Biệt hiệu của người dùng",
    text286: "Liên hệ với QQ",
    text287: "Vui lòng nhập biệt hiệu của người dùng",
    text288: "Số điện thoại di động",
    text289: "Vui lòng nhập số điện thoại",
    text290: "Liên hệ với WeChat",
    text291: "Vui lòng nhập liên hệ WeChat",
    text292: "Email Thành viên",
    text293: "Vui lòng nhập email thành viên",
    text294: "Vui lòng nhập biệt hiệu của người dùng",
    // 个人中心
    text295: "người dùng chung",
    text296: "THĂNG BẰNG",
    text297: "Uỷ ban",
    text298: "Hoàn lại tiền",
    text299: "Cấp độ VIP",
    text300: "lớp",
    text301: "Các điều kiện đã đáp ứng",
    text302: "Hạng hai",
    // 用户菜单
    text303: "Thông tin người dùng",
    text304: "Bản ghi trò chơi",
    text305: "Ghi lại giao dịch",
    text306: "Thông báo thông báo",
    text307: "tài khoản của tôi",
    text308: "Bản ghi hoạt động",
    text309: "Giảm giá quốc gia",
    text310: "đặt cọc",
    text311: "Rút tiền",
    text312: "Thưởng",
    text313:
      "Số tiền giảm giá không đáp ứng các điều kiện và không thể được yêu cầu!",
    text314: "mật khẩu mở khóa",
    text315: "Tài khoản ngân hàng",
    text316: "ngôn ngữ",
    text317: "chuyển khoản",
    text318: "Tổng số thành viên",
    text319: "Vui lòng tạo phương thức rút tiền của bạn",
    text320: "Phương thức rút tiền",
    text321: "Tạo phương thức rút tiền",
    text322: "Vui lòng xác minh số điện thoại",
    text323: "Mã xác minh SMS",
  },
  ...comp_vi_VN,
};
export default vi_VN;
