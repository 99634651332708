let platForms = window.projectImgUrl;
let skin = '';
if (platForms === 'amjs') {
    skin = '澳门金沙';
} else if (platForms === 'bmyl') {
    skin = '博美娱乐';
} else if (platForms === 'amxpj') {
    skin = '澳门新葡京';
} else if (platForms === 'qxyl') {
    skin = '千禧娱乐';
}
export default {
    // api prompt
    errorMessage: {
        text1: "Please login first",
        text2: "Failed to get proxy rules",
        text3: "Failed to get recommended games",
        text4: "Received successfully",
        text5: "Failed to receive",
        text6: "Failed to get rule",
        text7: "Failed to get game platform",
        text8: "Browser",
        text9: "Operation failed",
        text10: "The operation was successful",
        text11: "Failed to get announcement",
        text12: "Failed to get the internal message",
        text13: "Failed to get shortcut menu",
        text14: "Get the verification code successfully",
        text15: "Error getting verification code",
        text16: "Registration failed",
        text17: "Balance is being collected",
        text18: "Failed to get shortcut menu",
        text19: "Send successfully",
        text20: "Failed to get super bonus",
        text21: "Failed to get vip level",
        text22: "Validation failed",
        text23: "Send failed",
        text24: "Validation failed",
        text25: "The account has been logged in on another device, please log in again",
        text26: "Your login account has expired, please log in again",
        text27: "Failed to get exchange rate",
        text28: "Failed to get list",
        text29: "Search failed",
        text30: "Failed to get the list of popular games",
        text31: "Please enter account and password",
        text32: "Please pass the verification first",
        text33: "Please enter the verification code",
        text34: "Your authentication information is wrong, and",
        text35: "The number of times of verification will freeze the account!",
        text36: "Failed to get game record",
        text37: "Failed to get active record",
        text38: "QQ number does not exist",
        text39: "Wechat code does not exist",
        text40: "Email address does not exist",
        text41: "Update successful!",
        text42: "Password must be 6 digits",
        text43: "The passwords entered twice do not match",
        text44: "Please enter the correct old password",
        text45: "Please fill in your real name and then add a bank card",
        text46: "Warm reminder",
        text47: "Please set the withdrawal password first and then add the bank card",
        text48: "Please bind your mobile phone number and add a bank card",
        text49: "Please select a payment method",
        text50: "Please enter ERC20 or TRC20",
        text51: "Please enter the branch name",
        text52: "Please enter the wallet address",
        text53: "Please enter the bank card number",
        text54: "The wallet addresses entered twice are inconsistent",
        text55: "The bank card numbers entered twice are inconsistent",
        text56: "Service exception",
        text57: "Added successfully",
        text58: "Add failed",
        text59: "The game is under maintenance",
        text60: "Failed to get commission fee",
        text61: "Please select a withdrawal method",
        text62: "Please enter the transaction password",
        text63: "Your withdrawal limit is lower than the single withdrawal limit",
        text64: "Your withdrawal limit is higher than the single withdrawal limit",
        text65: "Your actual amount is too low to withdraw",
        text66: "Failed to get deposit method",
        text67: "Submitted successfully",
        text68: "Failed to get list",
        text69: "The phone format is incorrect",
    },
    // text
    textMessage: {
        text1: "to",
        text2: "Get it now",
        text3: "Received",
        text4: "Expired",
        text5: "fishing",
        text6: "In progress",
        text7: "Incomplete",
        text8: "Waiting for review",
        text9: "Completed",
        text10: "Approval failed",
        text11: "Participation failed",
        text12: "Participation was successful",
        text13: "Failed to get valid member",
        text14: "Failed to get rebate",
        text15: "Failed to get the promotion code",
        text16: "Unhandled",
        text17: "Successful recharge",
        text18: "Recharge failed",
        text19: "Close",
        text20: "Processing",
        text21: "Pending payment",
        text22: "Paying",
        text23: "Payment successful",
        text24: "Payment failed",
        text25: "closed",
        text26: "Offline bank card payment",
        text27: "Offline QR code payment",
        text28: "Online Payment",
        text29: "manual division",
        text30: "Payment in Numerical Currency",
        text31: "Alipay scan code",
        text32: "WeChat scan code",
        text33: "WeChat H5",
        text34: "Alipay H5",
        text35: "To be collected",
        text36: "UnionPay scan code",
        text37: "UnionPay Transfer",
        text38: "Other",
        text39: "Withdrawal succeeded",
        text40: "Withdrawal failed",
        text41: "Pending payment",
        text42: "Payment in progress",
        text43: "Successful payment",
        text44: "Failed to withdraw money",
        text45: "Bank Card Withdrawal",
        text46: "manually subdivide",
        text47: "Numerical Currency Withdrawal Request",
        text48: "origo wallet withdrawal",
        text49: "Success",
        text50: "Failed",
        text51: "closed",
        text52: "Failed to set cache data",
        text53: "The current browser has security risks, it is recommended to replace it!",
        text54: "Copy successfully",
        text55: "Copy failed",
        text56: "Please wait",
        // other records
        textMapDate:{
            6: 'Offline deposit discount',
            7: 'Online Deposit Offer',
            11: 'Manual up points',
            12: 'Manual deposit bonus points',
            13: 'Manual rebate bonus points',
            14: 'Manual activity discount on points',
            15: 'Automatic activity discounts on points',
            20: 'Manually place points-deposit',
            21: 'Manual sub-points - promotions',
            22: 'Manual Subscription - Manual Deposit Reversal',
            23: 'Manual Subscription-Deposit Offer',
            25: 'Manual Score-Promotions',
            26: 'Manual point-up - manual deposit rebate',
            27: 'Manual Score-Deposit Offer',
            28: 'Manual score-other',
            31: 'Manually add bonuses',
            32: 'Event prize money',
            33: 'Manual bonus bonus - manual deposit rebate',
            34: 'Manual bonus bonus-deposit offer',
            35: 'Manually adding bonuses - promotions',
            36: 'Manual increase - other bonuses',
            42: 'Numerical currency discount on points',
            44: 'Relief bonus points',
            45: 'Relief Bonus Bonus',
            46: 'Daily check-in score',
            50: 'National rebate',
            51: 'Other',
            52: 'Interest Deposit Deposit',
            53: 'Interest Treasure Principal Withdrawal',
            62: 'Information authentication',
            63: 'Weekly Rewards',
            64: 'Lucky Bet',
            65: 'Anniversary Gift',
            66: 'chess and card rewards',
            67: 'chess rescue',
            68: 'A thousand times a hundred times',
            69: 'One hundred million yuan in return',
            99: 'Interest withdrawal from interest treasure',
        },
    },
    // component
    componentsViews: {
        // Electron
        text1: "Video Games",
        // retrieve password
        text2: "Retrieve password",
        text3: "Please enter the account number",
        text4: "Confirm Commit",
        text5: "Please enter the SMS verification code",
        text6: "Get verification code",
        text7: "Confirm Commit",
        text8: "Please enter a new password",
        text9: "Please enter the new password again",
        text10: "Confirm Submit",
        text11: "Any questions? Please contact",
        text12: "Online Customer Service",
        text13: "Step 1: Verify account information",
        text14: "Step 2: SMS Verification",
        text15: "Step 3: Modify the new password",
        text16: "The passwords entered twice do not match",
        text17: "Step 1: Verify account information",
        // ip limit
        text18: "Access restricted",
        text19: "Dear user, due to relevant laws and regulations, your area cannot be accessed, if you have any questions, please contact customer service, we will reply to you as soon as possible, we apologize for the inconvenience caused to you, thank you understanding and support!",
        text20: "Your IP address",
        // ip verification popup
        text21: "Exception validation",
        text22: "The system detects that your account login area is abnormal, please fill in your account real name for verification!",
        text23: "Please enter your real name",
        text24: "Submit Verification",
        text25: "Security Authentication",
        text26: "You are logging in on a new device. For the security of your account, please perform security verification",
        text27: "Please enter your bound mobile phone number",
        text28: "Invalid phone number",
        // system maintenance
        text29: "System Maintenance Upgrade",
        text30: "Expected opening time:",
        text31: "Dear user:",
        text32: "In order to bring you a better experience,",
        text33: "The official platform is undergoing server upgrade. During this period, the website and products of the sports section will be temporarily suspended. We will restore it as soon as possible. Sorry for the inconvenience caused, thank you for your understanding and support!",
        // scrolling announcement
        text34: "Latest Announcements/NEWS",
        text35: "Announcement Notice",
        // registration form
        text36: "Account",
        text37: "Password",
        text38: "Confirm Password",
        text39: "Mobile phone number",
        text40: "SMS verification code",
        text41: "Get verification code",
        text42: "Invitation Code",
        text43: "real name",
        text44: "Male",
        text45: "Female",
        text46: "Select Date",
        text47: "WeChat",
        text48: "Mailbox",
        text49: "Contact address",
        text50: "I am over 18 years old and agree to the 'User Agreement' of this site",
        text51: "Register",
        text52: "(optional)",
        text53: "Please enter your mobile number",
        text54: "Verification code has been sent",
        text55: "Invitation code format error",
        text56: "Please enter the account number",
        text57: "Please enter your password",
        text58: "The two passwords do not match",
        text59: "Invalid mailbox",
        text60: "Invalid QQ number",
        text61: "Invalid WeChat",
        text62: "Please enter the verification code",
        text63: "Please tick the User Agreement",
        text64: "Please fill out the form completely",
        text65: "Registration succeeded",
        // Thematic event details
        text66: "Limited Time Offer",
        text67: "Permanent",
        // verification code
        text68: "Verification Code",
        text69: "Failed to get verification code configuration",
        text70: "Failed to get verification code",
        text71: "Failed to get extreme test",
        text72: "Loading",
        text73: "Click to verify",
        text74: "Verification successful",
        text75: "Validation failed",
        // proxy application
        text76: "Proxy Application",
        text77: "Enter application account number",
        text78: "Enter account password",
        text79: "Please enter your name",
        text80: "Name",
        text81: "Please enter your phone number",
        text82: "Birthday",
        text83: "Please select a birthday",
        text84: "Please enter qq",
        text85: "Please enter the WeChat ID",
        text86: "Gender",
        text87: "Mailbox",
        text88: "Address",
        text89: "Please enter the address",
        text90: "The submission is successful, please contact the commissioner within 3 days to open, and provide your agent number and agent link.",
        text91: "Apply Now",
        text92: "Got it",
        text93: "Please enter 4-15 letters or numbers",
        text94: "Mobile phone number cannot be empty",
        text95: "Please enter the correct mobile phone number",
        text96: "Please enter 6-16 letters or numbers",
        text97: "The two passwords do not match, please re-enter",
        text98: "Please enter your name",
        text99: "Name cannot exceed 8 characters",
        text100: "Please select a birthday",
        text101: "Please enter the correct QQ number",
        text102: "Please enter the correct WeChat ID",
        text103: "Please enter the correct email address",
        text104: "Successful application",
        text105: "Please enter complete information",
        text106: "Enter password again",
        text107: "Please enter your email address",
    },
    // help center
    helpamjs: {
        'text0': `About Us`,
        'text1': `${skin} is registered in Manila, Philippines, holds a sports betting and online casino license issued by the First Cagayan Leisure and Resort Corporation of the Philippine Government Cagayan Special Economic Zone, and is authorized by the Philippine Government and the Cagayan Special Economic Zone CEZA and supervision, all our gaming operations are in compliance with the Philippine Government Gaming Treaty. In the increasingly competitive online gaming market, we are constantly seeking innovations, looking for the latest ideas, and adhering to the best services. To provide customers with high-quality services, advanced products, rich entertainment experience, is our eternal purpose of ${skin}. `,
        'text2': "We think what you want, and provide high-standard and comprehensive personal privacy and confidentiality measures as well as a high-strength financial security system. Players' online payment and all banking transactions will be conducted by international financial institutions through a high-standard secure and confidential network port (SSL 128 bit encryption Standard). Entering the player account data must also have the player's unique login ID and password to ensure that the customer's funds are safe and secure. At the same time, the most advanced encryption measures are used to ensure the safety of players' games, and 7×24 hours of background detection and monitoring ensure that we can provide a completely fair and safe online game space. All activities of customers on this platform are strictly confidential, and we will never disclose customer data to third parties (including relevant units). ",
        'text3': 'We promise you:',
        'text4': 'In good faith',
        'text5': 'As an international professional online gaming operator, we promise to provide every customer with the safest and fairest gaming games and a full range of services. ',
        'text6': 'Provide the most diverse and realistic games',
        'text7': 'The dealers in our live video are professionally trained by international casinos, professional, efficient and rigorous! Each hand is dealt by the dealer in real time, not the pre-set computer probability result. Through high-tech webcasting technology, it will bring you the exciting experience of being in the casino scene!',
        'text8': 'Our sports betting has top-level trading room operators, investing a lot of manpower and resources in order to bring complete events and rich gameplay to sports-loving players. ',
        'text9': 'Various lottery games are based on official game results, allowing players to enjoy the most authentic entertainment on the lively betting interface. ',
        'text10': "Our video game is jointly developed with the world's top video game development team, using the most advanced animation visual effects, using the fairest random number generation probability, allowing you to enjoy the diverse, exciting, and visual electronic games with peace of mind. entertainment. ",
        'text11': "All our company's games have common advantages: no need to download, simple interface, complete functions, exquisite graphics, and the game is fair, just and open!",
        'text12': 'Provide excellent customer service',
        'text13': "We have a customer service team selected with the highest standards and the most rigorous training to provide you with real-time online customer service 365 days x 24 hours. Each player's consultation will be answered in a professional and cordial manner, and will help you solve problems while bringing you a home away from home experience! Provide multi-channel interaction with customers, understand customer needs, and pay attention to customers' opinions at any time and suggestions; hold more offers and promotions to provide customers with more feedback and surprises. ",
        'text14': 'Efficient and secure deposits and withdrawals',
        'text15': 'We offer a variety of safe and easy deposit and withdrawal options to our members. We have always adhered to the principles of "Know Our Member (KYC)" and Anti-Money Laundering (AML), and cooperated with third-party financial management authorities to ensure maximum compliance with relevant laws and regulations. ',
        'text16': 'We are very proud of the company we have built and want all users to enjoy and profit from our well-designed products and services in a safe and enjoyable environment. We provide the fastest and safest withdrawal method, and members can apply for withdrawal 365 days x 24 hours',
        'text17': 'Make you a big winner',
        'text18': 'We will continue to launch all kinds of discounts that are beneficial to new and old members, giving surprises every day, giving feedback every week, and giving out every month, making you a big winner.',
        "text20": "While serving every member wholeheartedly, we also shoulder the responsibility to the society and charity. Taking it from the society and using it for the society is also one of the tenets we have been adhering to. We actively participate in various donation activities, and will never be forgotten to make more for the needs of the society.",
        'text21': "The company promotes controlled gambling. We recommend that you follow some basic rules:",
        'text22': 'Gambling is prohibited for persons under the age of 18. Please make sure you are over 18 years old before joining the game.',
        'text23': 'Customize your betting budget and bet against it. ',
    },
    'problem': {
        'text1': `About ${skin}`,
        'text2': `${skin} was established in 2009. It holds an online gaming operation license issued by the Philippine government. It is also an online gaming website with a legal gaming license issued by the Online Gaming Commission. It is a professional casino that operates legally. The company has technical cooperation with Asia Gaming, Boeing (BBIN), PT (PlayTech) and MG (MicroGaming) to jointly create a high-quality gaming platform with absolute legitimacy and fairness. To provide our customers with a variety of gaming activities, and provide generous rewards. `,
        'text3': 'Management with integrity',
        'text4': "The company strictly abides by the Philippine government's gaming rules and is based on 'sincerity'. 100% credit guarantees that the game is fair and just. Each of your deposits and withdrawals will be completed safely and quickly after being reviewed",
        'text5': 'Fair and just',
        'text6': 'Dealers in live entertainment are subject to strict professional training and certification in international casinos, and all games are based on the actions of the dealers, rather than boring computer odds preset results. We use high-tech webcasting technology to bring you the exciting experience of the casino. Electronic games use the fairest random number generation probability, allowing you to enjoy diverse and dazzling entertainment games with peace of mind. ',
        'text7': 'Security and Privacy',
        'text8': "For the customer's network security, the company established a network security maintenance center and obtained the GEOTRUST international certification to ensure that the website is fair and just. In game betting, we use 128-bit SSL encryption transmission system, and MD5 encryption protection provides further protection for game platform and customer information. We promise that we will continue to spare no effort to strictly implement the confidentiality and privacy system to ensure the security of personal information and to ensure that your information will not be disclosed to any party. ",
        'text9': 'Friendly and professional customer service team',
        'text10': 'Our friendly and well-trained customer service specialists can provide you with the most superior and personal service while helping to solve customer problems. We also have an online chat function, so that customers can contact us instantly. ',
        'text11': 'FAQ',
        'text12': `One. ${skin}Register`,
        'text13': `1. How to register ${skin} real money account? `,
        'text14': `Click the "Free Account" button in the upper right corner of the webpage, fill in the real and effective information according to the requirements of the pop-up page, and the ${skin} background system will automatically generate an account. If you have any questions during this process, please contact ${skin} customer service immediately. `,
        'text15': `2. How to log into the ${skin} game? `,
        'text16': `You can log in to the official website or through the client to log in to the game page of ${skin}. `,
        'text17': '3. Have you tried it? ',
        'text18': 'We provide a free trial account to let you understand and familiarize yourself with our game platform and game types. If you are satisfied with our game platform, you can deposit to play real money games. (Remarks: The trial bonus is for trial play only, and does not participate in withdrawal)',
        'text19': `3. Why can't I browse the ${skin} website normally`,
        'text20': `The ${skin} website may cause you to be unable to browse the website normally when the interference of factors cannot be resisted. In this case, please contact the ${skin} customer service staff immediately.`,
        'text21': `4.${skin}Customer game age limit`,
        'text22': `${skin}The minimum age limit for real money accounts is 21 years old. Anyone under 21 years old, ${skin} refuses to register! `,
        'text23': `two. ${skin}Account`,
        'text24': '1. How to change the registration information',
        'text25': `${skin} must be registered with your real name. Once the registration is successful, the name and account number cannot be changed; the bank card information and password can be changed by yourself in the [Member Center]. If you encounter any problems during this process, you can Feel free to contact ${skin} customer service. `,
        'text26': '2. What should I do if I forget my password? ',
        'text27': `${skin} has a total of 2 passwords. One is your login password to enter the website or game, and the other is the security password used when withdrawing money. `,
        'text28': 'A. Forgot the login password: Click "Forgot Password" in the upper right corner of the website:',
        'text29': '1. Reset the login password by entering a secure password',
        'text30': '2. Reset the login password through the email address bound to your registration information;',
        'text31': '3. Retrieve the login password through the mobile phone bound to your game account;',
        'text32': 'B. Forgot security password:',
        'text33': '1. Please log in to your account first, go to [Member Center] > [Security Management] > [Security Password], and reset the security password by setting security questions;',
        'text34': '2. Reset the security question through the mobile phone bound to the game account and reset the security password. ',
        'text35': '3. How to complete the deposit',
        'text36': 'A. deposit',
        'text37': `Since you have successfully registered an account, you can start the deposit business. So far, ${skin} accepts: "Scan code payment", "Online payment", "WeChat payment", "Alipay transfer", and "Internet banking transfer/ATM and counter" and other deposit methods. Please choose the most suitable deposit method according to the actual situation. `,
        'text38': 'B. How soon after making a deposit can I be added to my account? ',
        'text39': `According to the deposit method you choose, the amount of money will arrive at different times. Generally, it will arrive immediately. If you have any questions, please contact ${skin} customer service. `,
        'text40': 'C. Are there any wagering requirements after depositing? ',
        'text41': 'After you complete the deposit, if you do not claim any bonus, you only need to complete the bet amount that is double the deposit amount to apply for withdrawal. ',
        'text42': '4. How to complete the withdrawal',
        'text43': 'A. withdrawal',
        'text44': 'After your account has completed all betting requirements, you can submit the application after filling in the information in [Member Withdrawal] of [Financial Center], and the relevant department will process it for you immediately; when applying for withdrawal, please be sure to fill in the form real bank information. Withdrawals are generally available within 5 minutes. ',
        'text45': 'B. Why was the withdrawal rejected? ',
        'text46': `After the withdrawal is returned by the relevant department of ${skin}, please contact the customer service of ${skin} immediately. `,
        'text47': '5. How to transfer money internally? ',
        'text48': 'After logging in to the account, click "Funds - Indoor Transfer" in the upper right corner of the computer web version, click "Transfer" at the bottom of the home page for the mobile web version, select the corresponding game hall, select "Transfer to the game platform" during the game, and mention Select "Transfer to Master Account" when making the payment. ',
        'text49': `Three. ${skin}game`,
        'text50': `How many games does 1.${skin} offer? `,
        'text51': `${skin} currently offers multi-platform high-paying games, including:`,
        'text52': '2. What should I do if it is very slow to enter the game? ',
        'text53': 'If you enter our website or the game is too slow, please clear the cache! The steps for IE and 360 browsers are: ',
        'text54': '1. Please exit our website first. ',
        'text55': '2. Click "Tools" in the upper right corner of the browser, select "Internet Options", then click "Delete", check "Cookies and Website Data" and click Delete, wait for the deletion to complete, please try to enter the game again! ',
        'text56': `About 3. What should I do if the video cannot be displayed in the game? ${skin}`,
        'text57': 'It is suggested that you can clear your FLASH cache, please click this link URL:',
        'text58': "Then click 'Delete All Web Sites'. Click 'Confirm' in the pop-up window to complete the operation! ",
        'text59': '4. Can the mobile phone play games? ',
        'text60': 'Currently the website supports Android and iPhone, you can play web games or download client games. However, even in the WIFI environment, the current operating capability of the mobile phone processor is still limited, and sometimes the screen freezes. So it is recommended to use the computer to run the game! ',
        'text61': '5. How to generate game results and ensure the fairness of the game? ',
        'text62': `${skin} All game results are generated by the game provider, the game results are real and valid, ${skin} will be responsible for all game results. The dealers in the live entertainment are subject to strict professional training and certification of international casinos, and all games respond according to the dealer's actions, rather than the uninteresting computer probability preset results. Electronic games use the fairest random number generation probability, allowing you to enjoy diverse and dazzling entertainment games with peace of mind. `,
        'text63': '6. How to place a bet, cancel a bet, and check the game record? ',
        'text64': `${skin}All game bets have a prominent bet button on the game page, you can bet according to your betting habits. Slot games cannot be revoked. Once clicked, the game will start automatically, please click properly. Live or sports games have the option to cancel bets until the bet is not accepted. All game records can be queried in the game page menu. Feel free to contact ${skin} customer service if you have any questions. `,
        'text65': '7. Why is the valid bet amount in the betting record inconsistent with your statistics? ',
        'text66': 'The AG platform and the Boeing platform both use the US Eastern Time (US Eastern Time such as New York, Washington time), so the time in the betting record will be different from the actual betting time. For example, if the actual betting time is 20:00 in the evening, the time displayed on the record is 8:00 in the morning, with a time difference of 12 hours. The Greenwich Mean Time used by MG is 8 hours different from Beijing time. ',
        'text67': '8. Is a live-action game a real-time game? Is the game page fake? ',
        'text68': `${skin} The live live game providers are Playtech, AG, Microgaming, BBIN, and all their live live games are real-time games. The TV next to the PT card table broadcasts the global synchronized programs of CNN TV in the United States or other famous TV stations. In addition, you can also chat with the live dealer at any time; AG, you can make real-time calls by dialing the Philippine phone on the desktop. `,
        'text69': '9. What should I do if the game suddenly stops and encounters an emergency? ',
        'text70': `${skin} The game has been continuously improved and has become a very stable game environment in the industry. However, due to the interference of many uncontrollable factors such as the international network, the country where the customer is located, etc., the game may be suddenly interrupted. If you encounter such problems, please do not panic and immediately contact ${skin} customer service staff for assistance. ${skin} hereby solemnly promises that ${skin} will be responsible and compensate for any game losses caused by ${skin}! . `,
        'text71': `Four. ${skin}Bet and Bonus Requirements`,
        'text72': `1.${skin} how to get bonus`,
        'text73': `${skin} provides many opportunities to get preferential bonuses, some bonuses are automatically added to your account without applying, and some bonuses require you to apply and are automatically added to you after passing. `,
        'text74': '2. Can the bonus be cancelled? ',
        'text75': 'Once the bonus has been awarded to your account, it cannot be cancelled. ',
        'text76': '3. Can I withdraw money without completing the wagering requirement? ',
        'text77': `${skin} All real money accounts will not be withdrawn until the corresponding bonus wagering requirements have been completed. If your account balance is less than 5 RMB, all previous wagering requirements will be automatically cleared. `,
    },
    'declarations': {
        'text88': 'Rules and Declarations',
        'text89': "The company is a legal Internet trading organization. Customers who intend to participate in all the company's games are reminded to pay attention to the relevant laws and regulations of their country or region of residence. If in doubt, they should seek local legal advice on relevant issues. These terms cover all agreements between you and the company when gaming on this website. ",
        'text90': '1. Legal requirements',
        'text91': 'Under the laws of your country of residence, you must be of legal age to use this website. ',
        'text92': 'If you are under the age of 21, you may not use this website under any circumstances. ',
        'text93': 'The legality of online gambling has not been clarified in some regional or national laws, and even some regions or countries have clearly regulated online gambling as illegal. We do not intend to invite anyone to use this site illegally in these regions or countries. The existence of this website does not imply that we promote online gambling in any region or country where gambling is illegal. You must be solely responsible for determining whether the use of online gambling is legal in the region or country where you live. ',
        'text94': '2. Account regulations',
        'text95': "The customer can only have one account. If it is found that the customer has multiple accounts, the company has the right to freeze the customer's account and cancel all winning bets in the account. The company reserves the right to reserve the right to cancel any amount that causes the account balance to be incorrect. In addition, if it is found that the account holder and relatives share multiple different accounts, the company reserves the right to restrict each customer to keep a maximum of one account. If the above situation has constituted fraud, harm or infringement of the rights or interests of the company and other users, the company has the right to require the user to compensate and confiscate the balance in the user's account, and terminate or cancel the user's right to continue to use the system",
        'text96': '3. Transaction settlement',
        'text97': 'Users are responsible and obliged to check their account balance before or after each game transaction and before leaving the game. When the user suspects that the balance is wrong, he must file a complaint with the online customer service staff of this website or the customer service department of the company before the next round of the game (the start of the game and the appearance of the checkout screen are regarded as one round). If you do not do so, the company will consider that you have waived your right to appeal and accept the current balance data as correct. ',
        'text98': 'For users who settle transactions directly with the company, the bank account name must be the same as the name used in the membership registration and application process. If the user uses a credit or debit card, the cardholder name must also be the same as the name used during the membership registration and application process. In the event that the bank account name or cardholder name is inconsistent with the name used for account registration (member registration), the company reserves the right to refuse the settlement of related transactions',
        'text99': 'For users who settle transactions directly with the company, the company will deposit the gaming income into your user account according to the transaction method information provided by the user. In the process of depositing any money or income into the account, if there is a transaction error due to insufficient information or incorrect information provided by the user, the company shall not be liable in any form. Once the user finds that the funds in the account are incorrect, it is the responsibility of the user to notify us immediately. ',
        'text100': "The company has the right to deduct and deduct the above fees from the user's account balance for the bank charges incurred by the company due to the user's settlement transaction, including handling fees, telegraphic postage, etc. ",
        'name1': 'JACKPOT will deduct a certain prize pool management fee according to the specific winning amount after winning the lottery, which is based on the amount charged by the game platform company. ',
        'name2': '4. The rights and obligations of the company',
        'name3': "he company will take various measures to protect the player's account security. ",
        'name4': 'The company will never use any artificial means to affect the fairness of the game. ',
        'name5': 'The company is not responsible for the illegal use of player accounts. ',
        'name6': 'The company reserves the right to revise all systems and rules. ',
        'name7': `If the company finds that players cheat, attack or destroy the normal operation of the system in the game, and use explicit or implied methods to slander "${skin} Casino" , abuse, intimidation, and slander, the company reserves the right to refuse/prohibit and revoke the player's continued use of the system after being dissuaded by the company, and "will be prosecuted in accordance with the laws of the Republic of the Philippines". `,
        'name8': 'Our company has "backup data" for your personal data and records in the system. However, in the unfortunate event of irresistible factors (such as natural disasters, terrorist activities, wars, riots...) resulting in the loss of the above data, the company will do its best to restore all historical data, and the restored data will also be considered fair. ',
        'name9': '5, promotions and offers',
        'name10': 'All promotions, offers and special events are subject to these Terms and the rules and conditions set forth by the Company from time to time for specific promotions. The Company reserves the right to suspend, cancel or modify the terms and conditions of such promotions, incentives and special events at any time. ',
        'name11': 'If the company determines that someone has abused or attempted to abuse a promotion or incentive, or may benefit from such abuse, the company reserves the right to block, refuse, or suspend it as it sees fit. or withdraw any user from participating in related promotions, rewards and special events. ',
        'name12': '6. Terms modification policy',
        'name13': 'The company reserves the absolute right to revise, update and modify these terms and game rules from time to time. Any revisions, updates and modifications will be posted on the official website. These Terms and Game Rules, as amended, updated or modified, will be effective upon publication. When the user continues to use the website or the game after the above revisions, updates and revisions are announced, it is deemed that the user agrees and accepts the revisions, updates and revisions of the company. ',
        'name14': 'We recommend that you regularly read the rules and terms of this website and the rules of the game. ',
        'name15': 'The above revisions, updates and modifications, the company may decide whether to notify the user or not. The company has no obligation to notify users of the above revisions, updates and modifications. ',
    },
    // member deposit
    depositDemo:{
        text1: "Member Deposit",
        text2: "One: Click [Funds] - [Member Deposits]",
        text3: "1: Company deposits",
        text4: "Choose a bank: select our company's receiving bank",
        text5: "Deposit name: Fill in the name of the transfer party: Zhang San",
        text6: "Recharge amount: fill in the amount to be recharged, such as: 501.2 (it is recommended that the mantissa not be 0 or a decimal)",
        text7: "Click [Next]",
        text8: "Copy the required payment information to transfer, click the text icon to copy, after the transfer is successfully received, wait for the finance to add processing for you.",
        text9: "The following transfer methods can be used: online banking transfer, UnionPay transfer, Alipay transfer card",
        text10: "1: Online deposit",
        text11: "Recharge Channel - [Mobile Banking Online Banking Transfer Card] or [Internet Banking 100-10000]",
        text12: "Recharge amount: fill in the amount to be recharged, such as: 1234.5 (recommended that the mantissa is not 0 or has a decimal)",
        text13: "Click [Next]",
        text14: "Copy the required payment information for transfer. After the transfer is successfully received, the bonus will be added automatically.",
    },
    // member withdrawal
    withdrawDemo:{
        text1: "Member Withdrawal",
        text2: "1: Bind the collection account",
        text3: "Click [Personal Information] - [Payment Method Management]",
        text4: "Click [Add bank card] or [Add origo wallet]",
        text5: "Bank name: select the opening bank",
        text6: "Sub-branch name: fill in the sub-branch name",
        text7: "Bank card number: fill in the receiving account number",
        text8: "Confirm card number: fill in the account number again",
        text9: "Verification code (not required): Click [Send Verification Code] to receive our verification code and fill in",
        text10: "Click [OK] to bind the card successfully",
        text11: "2: Click [Funds] - [Member Withdrawal]",
        text12: "One-click collection: Click to collect the balance of all platforms to the account balance.",
        text13: "Payment method: select the bank card to be used for payment",
        text14: "Withdrawal Amount: Enter the amount to be withdrawn",
        text15: "Actual amount received: same as withdrawal, if not, do not submit and cannot withdraw",
        text16: "Withdrawal fee: If there is a deduction fee, do not submit it, and you cannot withdraw money",
        text17: "Administrative Fees: If there is a deduction fee, do not submit it and cannot pay out",
        text18: "Preferential deduction: If there is a deduction fee, do not submit it, and you cannot make a payment",
        text19: "Transaction password: Enter the transaction password and click OK.",

    },
};