// 系统相关接口
import {
    _mainPost,
    _get,
    _post,
    _delete,
    _deleteArray,
} from '../request'
import Config from '../../common/config'
import { _getDeviceId } from '../../utils/finger'
import store from '../../store'
import { Message } from 'element-ui'
import { _getTime, _jump, _formatDate } from '../../utils/utils'
import i18n from '@/il8n/index'

// 获取首页多弹窗公告
// 需要弹出公告的时候会返回公告列表 否则返回false
export const _getGuideAd = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/guideAd/list').then(res => {
            if (res && res.code == 0) {
                if (!res.data || !res.data.lnegth) return resolve(false)
                const first = res.data[0]
                // first.popUpRule  0-不弹  1-每日弹一次  2-次次弹
                if (first.popUpRule == 0) return resolve(false)
                if (first.popUpRule == 2) return resolve(res.data)
                if (first.popUpRule == 1) {
                    const today = localStorage.getItem('todayAD')
                    if (today == _getTime(Date.now())) return resolve(false)
                    localStorage.setItem('todayAD', Date.now())
                    resolve(res.data)
                }
            } else {
                resolve(false)
            }
        })
    })
}

// 获取维护信息
export const _maintain = () => {
    let maintainUrl = '';
    if (window.projectImgUrl == 'fun88') {
        maintainUrl = window.maintainUrl;
    } else {
        maintainUrl = '/clientMaintain/getClientMaintain';
    }
    _mainPost(maintainUrl).then(res => { // https://011623.com
        if (res && res.code == 0 && res.data && res.data.status == 1) { // 维护中
            // const u = window.location.protocol + '//' + window.location.host
            // const o = res.data.maintainUrl
            // const str = `${o}?c=${Config.clientCode}&i=${Config.clientItem}&t=${Config.theme}&l=${Config.local}&u=${u}`
            // window.location.href = str
            Config.maintainEndTime = res.data.endTime
            _jump('maintain')
        } else {
            setTimeout(() => {
                _maintain()
            }, Config.interval)
        }
    })
}

// 获取系统配置
export const _getSystemConfig = () => _get('/longm/api/v1/domain/pageList')

// 获取首页Banner
export const _getBanner = () => _get('/longm/api/v1/banners/app/list')

// 获取公告
export const _getNotice = params => _post('/longm/api/v1/notices/app/home/list', params)

// 获取用户登录的设备列表
export const _deviceList = async () => {
    const deviceId = await _getDeviceId()
    return new Promise(resolve => {
        _get('/member/api/v1/members/memberDeviceFeatureCode/list/' + deviceId).then(res => {
            if (res && res.code == 0) {
                let arr = res.data || []
                // 数据处理
                arr = arr.map(item => {
                    item._source = item.source === 'pc' ? i18n.t("errorMessage.text8") : ''
                    item._updatedAt = _formatDate(item.updatedAt)
                    return item
                })
                store.dispatch('systemDevicesAction', arr)
                resolve(res.data)
            } else {
                resolve(false)
            }
        })
    })
}

// 删除设备
export const _deviceListDel = id => {
    return new Promise(resolve => {
        _get('/member/api/v1/members/memberDeviceFeatureCode/del/' + id, '', true).then(res => {
            if (res && res.code == 0) {
                resolve(res)
                Message.success(i18n.t("errorMessage.text10"))
                _deviceList()
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text9"))
                resolve(false)
            }
        })
    })
}

// 获取银行卡绑定相关配置
export const _getBankCountConfig = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/settings/get/' + 'withdraw_open').then(res => {

            if (res && res.code == 0) {
                let count = 0
                let usdtCount = 0
                let origoCount = 0
                let bind_usdt_sms = 0
                let bind_sms = 0
                try {
                    count = res.data.svalue.bank_card_count //银行卡
                    usdtCount = res.data.svalue.digit_money_count || 0; //数字货币
                    origoCount = res.data.svalue.origo_money_count || 0; //origo
                    bind_usdt_sms = res.data.svalue.bind_usdt_sms || 0;
                    bind_sms = res.data.svalue.bind_sms || 0;
                } catch {
                    count = 0
                }
                store.dispatch('userBankMaxAction', count)
                store.dispatch('userUsdtMaxAction', usdtCount)
                store.dispatch('userOrigoMaxAction', origoCount)

                store.dispatch('userBankBindUsdtAction', bind_usdt_sms)
                store.dispatch('userBankBindSmsAction', bind_sms)
                resolve(res)
            } else {
                resolve(false)
            }
        })
    })
}

// 绑定银行卡前是否需要绑定手机号的配置
export const _getBindSmsSetting = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/settings/getBindSmsSetting').then(res => {
            if (res && res.code == 0) {
                store.dispatch('userBankBindPhoneAction', false)
                resolve(res)
            } else {
                resolve(false)
            }
        })
    })
}

// 获取可选银行列表
export const _getBanks = () => {
    return new Promise(resolve => {
        _get('/pay/api/v1/banks/list').then(res => {
            if (res && res.code == 0) {
                store.dispatch('userBanksAction', res.data)
                resolve(res)
            } else {
                resolve(false)
            }
        })
    })
}

// 获取公告列表
export const _noticeList = params => {
    return new Promise(resolve => {
        _post('/longm/api/v1/notices/app/home/list', {
            createdAt: '',
            currentPage: params.currPage || 1,
            pageSize: params.pageSize || 10,
            publishedAt: '',
            subject: '',
        }, true).then(res => {
            if (res && res.code == 0) {
                let list = []
                let total = 0
                let noticeUnRead = 0
                if (res.data.content.length) {
                    total = res.data.totalRecords
                    noticeUnRead = res.data.unreadCount
                    // 数据处理
                    let unread = false
                    list = res.data.content.map(item => {
                        item._publishedAt = _getTime(item.publishedAt)
                        item._open = false // 是否展开，纯前端使用
                        if (item.readFlag == 0) unread = true
                        return item
                    })

                    // 请求第一页的时候就知道是否有未读公告了
                    if (params.currPage == 1 && unread) {
                        store.dispatch('systemNoticeUnreadAction', true)
                    }
                }
                store.dispatch('systemNoticeAction', { list: list, total: total, noticeUnRead: noticeUnRead })
                resolve(res)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text11"))
                resolve(false)
            }
        })
    })
}

// 标记已读公告
export const _readNotice = (id, index) => {
    // vuex中的数据改为已读
    const list = store.getters.systemNoticeList
    const total = store.getters.systemNoticeTotal
    const noticeUnRead = store.getters.noticeUnReadTotalVuex > 0 ? store.getters.noticeUnReadTotalVuex - 1 : store.getters.noticeUnReadTotalVuex
    if (list[index]) {
        list[index].readFlag = 1
        store.dispatch('systemNoticeAction', { list: list, total: total, noticeUnRead: noticeUnRead })
    }
    // 发送已读请求
    const user = store.getters.userData
    return new Promise(resolve => {
        _post('/longm/api/v1/notices/readNotices', {
            content: '',
            delFlag: '',
            endAt: '',
            id: '',
            memberId: user.userId,
            noticeIds: id,
            operator: '',
            publishedAt: '',
            publishedAtType: 0,
            readFlag: 0,
            status: 0,
            subject: '',
        }, false).then(res => {
            if (res && res.code == 0) return resolve(res)
            Message.error(res.message || i18n.t("errorMessage.text9"))
            resolve(false)
        })
    })
}

// 获取站内信列表
export const _mailList = params => {
    return new Promise(resolve => {
        _post('/longm/api/v1/messages/list', {
            currentPage: params.currPage || 1,
            pageSize: params.pageSize || 10,
            receiverType: 0,
        }, true).then(res => {
            if (res && res.code == 0) {
                let list = []
                let total = 0
                let msgUnRead = 0
                if (res.data.content.length) {
                    total = res.data.totalRecords
                    msgUnRead = res.data.unreadCount
                    list = res.data.content.map(item => {
                        item._publishedAt = _getTime(item.publishedAt)
                        item._open = false // 是否展开，纯前端使用
                        return item
                    })
                }
                store.dispatch('systemMailAction', { list: list, total: total, msgUnRead: msgUnRead })
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text12"))
                resolve(false)
            }
        })
    })
}

// 标记已读站内信
export const _readMail = (id, index) => {
    // vuex中的数据改为已读
    const list = store.getters.systemMailList
    const total = store.getters.systemMailTotal
    const msgUnRead = store.getters.msgUnReadTotalVuex > 0 ? store.getters.msgUnReadTotalVuex - 1 : store.getters.msgUnReadTotalVuex
    if (list[index]) {
        list[index].readFlag = 1
        store.dispatch('systemMailAction', { list: list, total: total, msgUnRead: msgUnRead })
    }
    // 发送已读请求
    return new Promise(resolve => {
        _post('/longm/api/v1/messages/read', {
            messageIds: id,
            type: 0
        }, false).then(res => {
            if (res && res.code == 0) return resolve(res)
            Message.error(res.message || i18n.t("errorMessage.text9"))
            resolve(false)
        })
    })
}

// 删除站内信
export const _deleteMail = id => {
    return new Promise(resolve => {
        _delete('/longm/api/v1/messages/delete/0/' + id, '', true).then(res => {
            if (res && res.code == 0) {
                resolve(res)
            } else {
                resolve(false)
                Message.error(res.msg || i18n.t("errorMessage.text9"))
            }
        })
    })
}

// 删除全部站内信
export const _deleteAllMail = ids => {
    return new Promise(resolve => {
        _deleteArray('/longm/api/v1/messages/delete', {
            messageIds: ids,
            type: 0
        }, true).then(res => {
            if (res && res.code == 0) {
                resolve(res)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text9"))
                resolve(false)
            }
        })
    })
}

// 顶部快捷导航菜单
export const _listQuickEntry = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/settingsEntryWindows/listQuickEntry').then(res => {
            if (res && res.code == 0) {
                // 排序
                res.data.sort((a, b) => a.sort - b.sort)
                // 处理字段
                const list = res.data.map(item => {
                    item._hotPic = store.getters.systemImgHost + item.hotPic
                    return item
                })
                console.log('----快捷入口', list)
                store.dispatch('systemQuickEntryAction', list)
                resolve(res)
            } else {
                resolve(false)
                Message.error(res.msg || i18n.t("errorMessage.text13"))
            }
        })
    })
}

//获取客服链接

export const _systemCustomerServiceUrl = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/customers/app/getList').then(res => {
            if (res && res.code == 0) {          
                res.data.forEach(el=>{
                    if(el.name == 'online service'){
                        console.log(store.state)
                        store.commit('setSystemCustomerServiceUrl',el.url);
                    }
                })
                resolve(res)
            } else {
                resolve(false)
                Message.error(res.msg || i18n.t("errorMessage.text13"))
            }
        })
    })
}