// 用户相关状态、数据
import { _getUserInfo, _getvipdata } from '../../api/modules/user'
import { _hideMiddle, _getTime } from '../../utils/utils'

// userAccessToken 登录的token
const userAccessToken = {
    state: {
        userAccessToken: ''
    },
    mutations: {
        USER_ACCESS_TOKEN(state, data) {
            state.userAccessToken = data
        }
    },
    actions: {
        userAccessTokenAction({ commit }, data) {
            commit('USER_ACCESS_TOKEN', data)
            commit('SAVE')
        }
    },
    getters: {
        userAccessToken: state => state.userAccessToken,
        isLogin: state => !!(state.userAccessToken),
    }
}

// userInfo 用户信息
const userData = {
    state: {
        userData: {}
    },
    mutations: {
        USER_DATA(state, data) {
            const datas = JSON.parse(JSON.stringify(state.userData))
            Object.assign(datas, data)
            state.userData = datas
        }
    },
    actions: {
        userDataAction({ commit }, data) {
            commit('USER_DATA', data)
            commit('SAVE')
        },
        // 刷新基本信息
        userInfoAction({ commit, getters }) {
            if (!getters.isLogin) return
            _getUserInfo().then(res => {
                if (res && res.code == 0) {
                    const datas = res.data
                        // 展示数据的处理
                    datas._birthday = _getTime(datas.birthday)
                    datas._hideMiddlePhone = _hideMiddle(datas.phone)
                    datas._hideMiddleEmail = _hideMiddle(datas.email)
                    datas._hideMiddleQQ = _hideMiddle(datas.qq)
                    datas.totalBalance = datas.totalBalance ? datas.totalBalance.toFixed(2) : "0.00"
                    _getvipdata(res.data.levelId).then(ress => {
                        if (ress && ress.code == 0) {
                            datas._vipLevel = ress.data.gradeId - 1
                            commit('USER_DATA', datas)
                            commit('SAVE')
                        }
                    })

                }
            })
        }
    },
    getters: {
        userData: state => state.userData
    }
}


// 清除登录信息
const clearUserData = ({ commit }) => {
    commit('USER_DATA', {})
    commit('USER_ACCESS_TOKEN', '')
    commit('SAVE')
}


// 账户下各游戏平台余额
const userPlatformBalance = {
    state: {
        userPlatformBalance: []
    },
    mutations: {
        USER_PLATFORM_BALANCE(state, data) {
            state.userPlatformBalance = data
        }
    },
    actions: {
        userPlatformBalanceAction({ commit }, data) {
            commit('USER_PLATFORM_BALANCE', data)
        }
    },
    getters: {
        userPlatformBalance: state => state.userPlatformBalance
    }
}

// 账户下绑定银行卡列表
const userBankList = {
    state: {
        userBankList: [], // 已绑定的银行卡列表
        userBankMax: 0, // 最多银行卡绑定多少个
        userUsdtMax: 0, // 最多Usdt绑定多少个
        userOrigoMax: 0, // 最多origo绑定多少个
        userBankBindPhone: false, // 绑定银行卡是否需要先验证短信
        userBankBindUsdtAction: 0, // 绑定数字货币前是否需要先验证短信
        userBankBindSmsAction: 0, // 绑定银行卡前是否需要先验证短信
        userBanks: [], // 可选银行列表
    },
    mutations: {
        USER_BANK_LIST(state, data) {
            state.userBankList = data
        },
        USER_BANK_MAX(state, data) {
            state.userBankMax = data
        },
        USER_USDT_MAX(state, data){
            state.userUsdtMax = data
        },
        USER_ORIGO_MAX(state, data){
            state.userOrigoMax = data
        },
        USER_BANK_BIND_PHONE(state, data) {
            state.userBankBindPhone = data
        },
        USER_BANK_BIND_USDTPHONE(state, data) {
            state.userBankBindUsdtAction = data
        },
        USER_BANK_BIND_SMSPHONE(state, data) {
            state.userBankBindSmsAction = data
        },
        USER_BANKS(state, data) {
            state.userBanks = data
        },
    },
    actions: {
        userBankListAction({ commit }, data) {
            commit('USER_BANK_LIST', data)
        },
        userBankMaxAction({ commit }, data) {
            commit('USER_BANK_MAX', data)
        },
        userUsdtMaxAction({ commit }, data) {
            commit('USER_USDT_MAX', data)
        },
        userOrigoMaxAction({ commit }, data) {
            commit('USER_ORIGO_MAX', data)
        },
        userBankBindPhoneAction({ commit }, data) {
            commit('USER_BANK_BIND_PHONE', data)
        },
        userBankBindUsdtAction({ commit }, data) {
            commit('USER_BANK_BIND_USDTPHONE', data)
        },
        userBankBindSmsAction({ commit }, data) {
            commit('USER_BANK_BIND_SMSPHONE', data)
        },
        userBanksAction({ commit }, data) {
            commit('USER_BANKS', data)
        }
    },
    getters: {
        userBankList: state => state.userBankList,
        userBankMax: state => state.userBankMax,
        userUsdtMax: state => state.userUsdtMax,
        userOrigoMax: state => state.userOrigoMax,
        userBankBindPhone: state => state.userBankBindPhone,
        userBankBindUsdtAction: state => state.userBankBindUsdtAction,
        userBankBindSmsAction: state => state.userBankBindSmsAction,
        userBanks: state => state.userBanks,
    }
}

// vip等级列表
const vipLevelList = {
    state: {
        vipLevelList: []
    },
    mutations: {
        VIP_LEVEL_LIST(state, data) {
            state.vipLevelList = data
        }
    },
    actions: {
        vipLevelListAction({ commit }, data) {
            commit('VIP_LEVEL_LIST', data)
        }
    },
    getters: {
        vipLevelList: state => state.vipLevelList
    }
}

export default {
    state: {
        ...userAccessToken.state,
        ...userData.state,
        ...userPlatformBalance.state,
        ...userBankList.state,
        ...vipLevelList.state,
    },
    mutations: {
        ...userAccessToken.mutations,
        ...userData.mutations,
        ...userPlatformBalance.mutations,
        ...userBankList.mutations,
        ...vipLevelList.mutations,
    },
    actions: {
        clearUserData,
        ...userAccessToken.actions,
        ...userData.actions,
        ...userPlatformBalance.actions,
        ...userBankList.actions,
        ...vipLevelList.actions,
    },
    getters: {
        ...userAccessToken.getters,
        ...userData.getters,
        ...userPlatformBalance.getters,
        ...userBankList.getters,
        ...vipLevelList.getters,
    }
}