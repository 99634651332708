<!-- 主容器 -->
<template>
  <div>
    <Header />
    <keep-alive :include="keepAliveRouters">
      <router-view />
    </keep-alive>
    <WrapBottom />
    <RightMenu />
  </div>
</template>

<script>
import WrapBottom from "../components/WrapBottom.vue";
import RightMenu from "../components/right-menu/index.vue";
import Header from "../components/header.vue";
export default {
  components: {
    WrapBottom,
    RightMenu,
    Header
  },
  data() {
    return {
      // 不用销毁的页面
      keepAliveRouters: ["promo"],
    };
  },
};
</script>
<style >
.router-view {
  width: 100%;
  height: 900px;
}
</style>