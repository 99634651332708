// 系统相关状态、数据
// 每个数据的增删改查放在一个对象里，方便维护

// 启动页是否获取到了系统配置
const systemReady = {
    state: {
        systemReady: false,
        systemCodeUrl: '', // web服务器地址
        systemImgHost: '', // 图片服务器地址
        systemDowUrl: '', // 下载服务器地址
        systemJackpotUrl: '', // 彩金服务器地址
        systemCustomerServiceUrl: '', // 客服服务器地址
        systemIosDownloadUrl: '', // ios下载地址
        systemAndroidDownloadUrl: '', // 安卓下载地址
        systemPcDownloadUrl: '', // pc下载地址
        systemPcMainUrl: '', // 主站域名
    },
    mutations: {
        SYSTEM_READY (state, data) {
            console.log('设置成功', data)
            if (data) {
                state.systemReady = true
                state.systemCodeUrl = data.systemCodeUrl
                state.systemImgHost = data.systemImgHost
                state.systemDowUrl = data.systemDowUrl
                state.systemJackpotUrl = data.systemJackpotUrl
                // state.systemCustomerServiceUrl = data.systemCustomerServiceUrl
                state.systemIosDownloadUrl = data.systemIosDownloadUrl
                state.systemAndroidDownloadUrl = data.systemAndroidDownloadUrl
                state.systemPcDownloadUrl = data.systemPcDownloadUrl
                state.systemPcMainUrl = data.systemPcMainUrl
            } else {
                state.systemReady = false
            }
        },
        setSystemCustomerServiceUrl(state,data){
            state.systemCustomerServiceUrl = data;
        }
    },
    actions: {
        systemReadyAction ({ commit }, data) {
            commit('SYSTEM_READY', data)
            commit('SAVE')
        }
    },
    getters: {
        systemReady: state => state.systemReady,
        systemCodeUrl: state => state.systemCodeUrl,
        systemImgHost: state => state.systemImgHost,
        systemDowUrl: state => state.systemDowUrl,
        systemJackpotUrl: state => state.systemJackpotUrl,
        systemCustomerServiceUrl: state => state.systemCustomerServiceUrl,
        systemIosDownloadUrl: state => state.systemIosDownloadUrl,
        systemAndroidDownloadUrl: state => state.systemAndroidDownloadUrl,
        systemPcDownloadUrl: state => state.systemPcDownloadUrl,
        systemPcMainUrl: state => state.systemPcMainUrl,
    }
}

// app使用的Host
const systemHost = {
    state: {
        systemHost: '',
    },
    mutations: {
        SYSTEM_HOST (state, data) {
            state.systemHost = data || ''
        }
    },
    actions: {
        systemHostAction ({ commit }, data) {
            commit('SYSTEM_HOST', data)
            commit('SAVE')
        }
    },
    getters: {
        systemHost: state => state.systemHost,
    }
}

// 系统是否在维护
const systemMaintain = {
    state: {
        systemMaintain: false,
    },
    mutations: {
        SYSTEM_MAINTAIN (state, data) {
            state.systemMaintain = data
        }
    },
    actions: {
        systemMaintainAction ({ commit }, data) {
            commit('SYSTEM_MAINTAIN', data)
        }
    },
    getters: {
        systemMaintain: state => state.systemMaintain,
    }
}

// 后台返回的指纹token
const fingerprintId = {
    state: {
        fingerprintId: '',
    },
    mutations: {
        FINGERPRINT_ID (state, data) {
            state.fingerprintId = data
        }
    },
    actions: {
        fingerprintIdAction ({ commit }, data) {
            commit('FINGERPRINT_ID', data)
            commit('SAVE')
        }
    },
    getters: {
        fingerprintId: state => state.fingerprintId,
    }
}

// 登录的设备列表
const systemDevices = {
    state: {
        systemDevices: [],
    },
    mutations: {
        SYSTEM_DEVICES (state, data) {
            state.systemDevices = data || []
        }
    },
    actions: {
        systemDevicesAction ({ commit }, data) {
            commit('SYSTEM_DEVICES', data)
        }
    },
    getters: {
        systemDevices: state => state.systemDevices,
    }
}

// 公告列表
const systemNotice = {
    state: {
        systemNoticeList: [], // 公告列表
        systemNoticeTotal: 0, // 公告总数
        systemNoticeUnread: false, // 是否有未读公告
        noticeUnReadTotalVuex: 0
    },
    mutations: {
        SYSTEM_NOTICE (state, { list, total,noticeUnRead }) {
            state.systemNoticeList = list || []
            state.systemNoticeTotal = total || 0
            state.noticeUnReadTotalVuex = noticeUnRead || 0
        },
        SYSTEM_NOTICE_UNREAD (state, data) {
            state.systemNoticeUnread = data || false
        },
    },
    actions: {
        systemNoticeAction ({ commit }, data) {
            commit('SYSTEM_NOTICE', data)
        },
        systemNoticeUnreadAction ({ commit }, data) {
            commit('SYSTEM_NOTICE_UNREAD', data)
        }
    },
    getters: {
        systemNoticeList: state => state.systemNoticeList,
        systemNoticeTotal: state => state.systemNoticeTotal,
        systemNoticeUnread: state => state.systemNoticeUnread,
        noticeUnReadTotalVuex: state => state.noticeUnReadTotalVuex,
    }
}
// 顶部快捷菜单
const systemQuickEntry = {
    state: {
        systemQuickEntry: []
    },
    mutations: {
        SYSTEM_QUICK_ENTRY (state, data) {
            state.systemQuickEntry = data || []
        },
    },
    actions: {
        systemQuickEntryAction ({ commit }, data) {
            commit('SYSTEM_QUICK_ENTRY', data)
        },
    },
    getters: {
        systemQuickEntry: state => state.systemQuickEntry,
    }

}


// 站内信列表
const systemMail = {
    state: {
        systemMailList: [], // 站内信列表
        systemMailTotal: 0, // 站内信总数
    },
    mutations: {
        SYSTEM_MAIL (state, { list, total }) {
            state.systemMailList = list || []
            state.systemMailTotal = total || 0
        },
    },
    actions: {
        systemMailAction ({ commit }, data) {
            commit('SYSTEM_MAIL', data)
        },
    },
    getters: {
        systemMailList: state => state.systemMailList,
        systemMailTotal: state => state.systemMailTotal,
        msgUnReadTotalVuex: state => state.msgUnReadTotalVuex,
    }
}


export default {
    state: {
        ...systemReady.state,
        ...systemHost.state,
        ...systemMaintain.state,
        ...fingerprintId.state,
        ...systemDevices.state,
        ...systemNotice.state,
        ...systemMail.state,
        ...systemQuickEntry.state,
    },
    mutations: {
        ...systemReady.mutations,
        ...systemHost.mutations,
        ...systemMaintain.mutations,
        ...fingerprintId.mutations,
        ...systemDevices.mutations,
        ...systemNotice.mutations,
        ...systemMail.mutations,
        ...systemQuickEntry.mutations,
    },
    actions: {
        ...systemReady.actions,
        ...systemHost.actions,
        ...systemMaintain.actions,
        ...fingerprintId.actions,
        ...systemDevices.actions,
        ...systemNotice.actions,
        ...systemMail.actions,
        ...systemQuickEntry.actions,
    },
    getters: {
        ...systemReady.getters,
        ...systemHost.getters,
        ...systemMaintain.getters,
        ...fingerprintId.getters,
        ...systemDevices.getters,
        ...systemNotice.getters,
        ...systemMail.getters,
        ...systemQuickEntry.getters,
    }
}