<template>
  <div class="right-menu">
    <ul class="rolling-fw-top">
      <li>
        <a :href="systemCustomerServiceUrl" target="_blank"
          ><img src="../../assets/images/km/kf.png"
        /></a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "systemCustomerServiceUrl", // 客服
    ]),
  },
};
</script>
<style lang="less" scoped>
.right-menu {
  .rolling-fw-top {
    position: absolute;
    right: 15px;
    z-index: 99;
    padding: 0 0;
    top: 500px;
    list-style: none;
    li {
      width: 78px;
      height: 78px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>