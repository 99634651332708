// 项目公共配置

const config = {
    host: '', // 请求的服务器地址
    imgUrl: '', // 图片的服务器地址
    clientCode: '', // 各台子唯一id
    childCode: '', // 子id
    theme: '', // 主题
    projectImgUrl: '', // url.js 里的skin字段
    interval: 18000, // 获取维护信息的间隔
    locale: '', // 语言
    clientIp: '192.168.1.1', // 客户端ip

    // 这三个是请求时候需要带的参数
    appVer: '1.0.0',
    protocolVer: '1.0',
    reqId: 0,

    loginVersion: 'v1.0.0', // 登录版本 登录/注册接口要用
}

export default config