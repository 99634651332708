let platForms = window.projectImgUrl;
let skin = '';
if (platForms === 'amjs') {
    skin = '澳门金沙';
} else if (platForms === 'bmyl') {
    skin = '博美娱乐';
} else if (platForms === 'amxpj') {
    skin = '澳门新葡京';
} else if (platForms === 'qxyl') {
    skin = '千禧娱乐';
}else {
    skin = '888b.com';  
}
export default {
    // api提示
    errorMessage: {
        text1: "vui lòng đăng nhập trước",
        text2: "Không lấy được quy tắc proxy",
        text3: "Không tải được các trò chơi được đề xuất",
        text4: "Đã nhận thành công",
        text5: "Không nhận được",
        text6: "Không lấy được quy tắc",
        text7: "Không tải được nền tảng trò chơi",
        text8: "Trình duyệt",
        text9: "lỗi hệ thống",
        text10: "Hoạt động thành công",
        text11: "Không nhận được thông báo",
        text12: "Không lấy được tin nhắn nội bộ",
        text13: "Không tải được menu lối tắt",
        text14: "Nhận mã xác minh thành công",
        text15: "Lỗi khi nhận mã xác minh",
        text16: "đăng ký không thành công",
        text17: "Số dư đang được thu thập",
        text18: "Không tải được menu lối tắt",
        text19: "Gửi thành công",
        text20: "Không nhận được siêu tiền thưởng",
        text21: "Không đạt được cấp độ VIP",
        text22: "Xác thực không thành công",
        text23: "Gửi thất bại",
        text24: "xác minh không hoàn thành",
        text25: "Tài khoản đã được đăng nhập trên thiết bị khác, vui lòng đăng nhập lại",
        text26: "Tài khoản đăng nhập của bạn đã hết hạn, vui lòng đăng nhập lại",
        text27: "Không lấy được tỷ giá hối đoái",
        text28: "Không lấy được danh sách",
        text29: "Tìm kiếm không thành công",
        text30: "Không tải được danh sách các trò chơi phổ biến",
        text31: "Vui lòng nhập tài khoản và mật khẩu",
        text32: "Vui lòng vượt qua xác minh trước",
        text33: "vui lòng nhập mã xác nhận",
        text34: "Thông tin xác minh của bạn không chính xác và",
        text35: "Số lượng cơ hội xác minh sẽ bị đóng băng và tài khoản sẽ bị đóng băng!",
        text36: "Không lấy được bản ghi trò chơi",
        text37: "Không lấy được bản ghi hoạt động",
        text38: "Số QQ không tồn tại",
        text39: "Mã wechat không tồn tại",
        text40: "Địa chỉ email không tồn tại",
        text41: "hoàn thành cập nhật!",
        text42: "Mật khẩu phải có 6 chữ số",
        text43: "Mật khẩu được nhập hai lần không khớp",
        text44: "Vui lòng nhập đúng mật khẩu cũ",
        text45: "Vui lòng điền tên thật của bạn và thêm thẻ ngân hàng",
        text46: "Mẹo tử tế",
        text47: "Vui lòng đặt mật khẩu rút tiền trước rồi thêm thẻ ngân hàng",
        text48: "Vui lòng liên kết số điện thoại của bạn và thêm thẻ ngân hàng",
        text49: "Vui lòng chọn một phương thức thanh toán",
        text50: "Vui lòng nhập ERC20 hoặc TRC20",
        text51: "Vui lòng nhập tên chi nhánh",
        text52: "Vui lòng nhập địa chỉ ví",
        text53: "Vui lòng nhập số thẻ ngân hàng",
        text54: "Địa chỉ ví được nhập hai lần không nhất quán",
        text55: "Các số thẻ ngân hàng đã nhập hai lần không khớp",
        text56: "Ngoại lệ dịch vụ",
        text57: "Thêm thành công",
        text58: "thêm không thành công",
        text59: "Trò chơi đang được bảo trì",
        text60: "Không tính được phí",
        text61: "Vui lòng chọn phương thức rút tiền",
        text62: "Vui lòng nhập mật khẩu giao dịch",
        text63: "Giới hạn rút tiền của bạn thấp hơn giới hạn rút tiền một lần",
        text64: "Giới hạn rút tiền của bạn cao hơn giới hạn rút tiền một lần",
        text65: "Số tiền thực tế của bạn quá thấp để có thể rút",
        text66: "Không nhận được phương thức gửi tiền",
        text67: "Gửi thành công",
        text68: "Không lấy được danh sách",
        text69: "Định dạng điện thoại không chính xác",
    },
    // 文本
    textMessage: {
        text1: "đến",
        text2: "lấy nó ngay bây giờ",
        text3: "Nhận",
        text4: "hết hạn",
        text5: "đánh bắt cá",
        text6: "Chế biến",
        text7: "hoàn tác",
        text8: "Kiểm duyệt",
        text9: "hoàn thành",
        text10: "Phê duyệt không thành công",
        text11: "Tham gia không thành công",
        text12: "Tham gia vào thành công",
        text13: "Không có được thành viên hợp lệ",
        text14: "Không nhận được khoản giảm giá",
        text15: "Không lấy được mã khuyến mại",
        text16: "chưa được xử lý",
        text17: "Nạp tiền thành công",
        text18: "Nạp tiền không thành công",
        text19: "Khép kín",
        text20: "Xử lý",
        text21: "Được trả tiền",
        text22: "Thanh toán",
        text23: "thanh toán thành công",
        text24: "Thanh toán không thành công",
        text25: "đóng cửa",
        text26: "Thanh toán bằng thẻ ngân hàng ngoại tuyến",
        text27: "Thanh toán bằng mã QR ngoại tuyến",
        text28: "Thanh toán trực tuyến",
        text29: "lên thủ công",
        text30: "Thanh toán bằng tiền tệ số",
        text31: "Mã quét Alipay",
        text32: "Mã quét WeChat",
        text33: "WeChat H5",
        text34: "Alipay H5",
        text35: "đã nhận được",
        text36: "Mã quét UnionPay",
        text37: "Chuyển khoản UnionPay",
        text38: "khác",
        text39: "Rút tiền thành công",
        text40: "Rút tiền không thành công",
        text41: "Trong khi chờ thanh toán",
        text42: "Thanh toán",
        text43: "Thanh toán thành công",
        text44: "Thanh toán không thành công",
        text45: "Rút tiền từ thẻ ngân hàng",
        text46: "xuống thủ công",
        text47: "Ứng dụng rút tiền bằng tiền tệ số",
        text48: "rút tiền từ ví origo",
        text49: "thành công",
        text50: "Thất bại",
        text51: "đóng cửa",
        text52: "Không đặt được dữ liệu bộ nhớ cache",
        text53: "Trình duyệt hiện tại có những rủi ro về bảo mật, bạn nên thay thế nó!",
        text54: "Sao chép thành công",
        text55: "Sao chép không thành công",
        text56: "Vui lòng chờ",
        // 其它记录
        textMapDate: {
            6: 'Chiết khấu tiền gửi ngoại tuyến',
            7: 'Ưu đãi Gửi tiền Trực tuyến',
            11: 'Tăng điểm theo cách thủ công',
            12: 'Điểm thưởng tiền gửi thủ công',
            13: 'Điểm thưởng chiết khấu thủ công',
            14: 'Giảm giá hoạt động thủ công trên điểm',
            15: 'Giảm giá hoạt động tự động trên điểm',
            20: 'Đặt cọc điểm theo cách thủ công',
            21: 'Điểm phụ thủ công - khuyến mại',
            22: 'Điểm phụ thủ công - tiền gửi thủ công được hoàn lại',
            23: 'Đăng ký Thủ công - Ưu đãi Đặt cọc',
            25: 'Tăng điểm thủ công - khuyến mãi',
            26: 'Điểm thủ công - chiết khấu tiền gửi thủ công',
            27: 'Hướng dẫn sử dụng - Ưu đãi tiền gửi',
            28: 'Điểm thủ công - khác',
            31: 'Thêm tiền thưởng theo cách thủ công',
            32: 'Tiền thưởng sự kiện',
            33: 'Phần thưởng tiền thưởng thủ công - hoàn trả tiền gửi thủ công',
            34: 'Thưởng Cổ tức Thủ công - Ưu đãi Tiền gửi',
            35: 'Thêm tiền thưởng - khuyến mãi theo cách thủ công',
            36: 'Tăng thủ công - các phần thưởng khác',
            42: 'Tiền tệ số cung cấp điểm tăng',
            44: 'Giảm điểm thưởng',
            45: 'Tiền thưởng cứu trợ',
            46: 'Điểm nhận phòng hàng ngày',
            50: 'Giảm giá toàn quốc',
            51: 'khác',
            52: 'Tiền gửi lãi',
            53: 'Rút tiền gốc từ Kho báu lãi suất',
            62: 'Xác thực Thông tin',
            63: 'Phần thưởng hàng tuần',
            64: 'Cá cược may mắn',
            65: 'Món quà kỷ niệm',
            66: 'cờ vua và phần thưởng bài',
            67: 'cờ vua và giải cứu thẻ bài',
            68: 'Một nghìn lần một trăm lần',
            69: 'Đổi lại một trăm triệu nhân dân tệ',
            99: 'Rút lãi từ kho lãi',
        },
    },
    // 组件
    componentsViews: {
        // Electron
        text1: "trò chơi điện tử",
        // 找回密码
        text2: "Lấy lại mật khẩu",
        text3: "vui lòng nhập tài khoản",
        text4: "xác nhận gửi",
        text5: "Vui lòng nhập mã xác minh SMS",
        text6: "lấy mã xác minh",
        text7: "xác nhận gửi",
        text8: "Vui lòng nhập mật khẩu mới",
        text9: "Vui lòng nhập lại mật khẩu mới",
        text10: "xác nhận gửi",
        text11: "Mọi thắc mắc? Vui lòng liên hệ",
        text12: "dịch vụ trực tuyến",
        text13: "bước đầu tiên",
        text14: "Bước thứ hai",
        text15: "bước thứ ba",
        text16: "Mật khẩu được nhập hai lần không khớp",
        text17: "bước đầu tiên",
        // ip限制
        text18: "Khu phận sự",
        text19: "Người dùng thân mến, do luật và quy định có liên quan, khu vực của bạn không thể truy cập được, nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với bộ phận chăm sóc khách hàng, chúng tôi sẽ trả lời bạn trong thời gian sớm nhất, chúng tôi xin lỗi vì sự bất tiện đã gây ra cho bạn, cảm ơn bạn đã sự hiểu biết và hỗ trợ của bạn! ",
        text20: "địa chỉ IP của bạn",
        // ip验证弹窗
        text21: "Xác thực ngoại lệ",
        text22: "Hệ thống phát hiện khu vực đăng nhập tài khoản của bạn bất thường, vui lòng điền tên thật của tài khoản để xác minh!",
        text23: "vui lòng nhập tên thật của bạn",
        text24: "Gửi xác minh",
        text25: "xác minh an toàn",
        text26: "Bạn đang đăng nhập bằng một thiết bị mới. Để bảo mật cho tài khoản của bạn, vui lòng thực hiện xác minh bảo mật",
        text27: "Vui lòng nhập số điện thoại di động của bạn",
        text28: "Số điện thoại không hợp lệ",
        // 系统维护
        text29: "Nâng cấp Bảo trì Hệ thống",
        text30: "Thời gian mở cửa dự kiến:",
        text31: "người dùng được tôn trọng:",
        text32: "Để mang lại cho bạn trải nghiệm tốt hơn",
        text33: "Nền tảng chính thức đang được nâng cấp máy chủ. Trong thời gian này, trang web và các sản phẩm của chuyên mục thể thao sẽ tạm ngừng hoạt động. Chúng tôi sẽ khôi phục lại cho bạn trong thời gian sớm nhất. Xin lỗi vì sự bất tiện đã gây ra. Cảm ơn bạn đã thông cảm và ủng hộ ! ",
        // 滚动公告
        text34: "Thông báo / TIN TỨC Mới nhất",
        text35: "Thông báo thông báo",
        // 注册表单
        text36: "tài khoản",
        text37: "mật khẩu mở khóa",
        text38: "Xác nhận mật khẩu",
        text39: "Số điện thoại",
        text40: "Mã xác minh SMS",
        text41: "lấy mã xác minh",
        text42: "Mã mời",
        text43: "tên thật",
        text44: "Nam giới",
        text45: "Giống cái",
        text46: "Chọn ngày",
        text47: "WeChat",
        text48: "Thư",
        text49: "Địa chỉ liên hệ",
        text50: 'Trên 18 tuổi và đồng ý với"Thỏa thuận người dùng "của trang web này',
        text51: "Đăng ký",
        text52: "(không bắt buộc)",
        text53: "Vui lòng nhập số điện thoại",
        text54: "Mã xác minh đã gửi",
        text55: "Lỗi định dạng mã lời mời",
        text56: "vui lòng nhập tài khoản",
        text57: "Vui lòng nhập mật khẩu của bạn",
        text58: "Hai mật khẩu không khớp",
        text59: "Email không hợp lệ",
        text60: "Số QQ không hợp lệ",
        text61: "WeChat không hợp lệ",
        text62: "vui lòng nhập mã xác nhận",
        text63: "Vui lòng đánh dấu vào Thỏa thuận người dùng",
        text64: "Xin vui lòng điền vào biểu mẫu hoàn toàn",
        text65: "đăng ký thành công",
        // 专题活动详情
        text66: "Ưu đãi trong thời gian có hạn",
        text67: "dài hạn",
        // 验证码
        text68: "Mã xác nhận",
        text69: "Không lấy được cấu hình mã xác minh",
        text70: "Không lấy được mã xác minh",
        text71: "Không đạt được bài kiểm tra cực hạn",
        text72: "Đang tải",
        text73: "Nhấp để xác minh",
        text74: "Xác thực thành công",
        text75: "xác minh không hoàn thành",
        // 代理申请
        text76: "Ứng dụng Đại lý",
        text77: "Nhập tài khoản ứng dụng",
        text78: "Nhập mật khẩu tài khoản",
        text79: "Vui lòng nhập tên của bạn",
        text80: "Tên",
        text81: "Vui lòng nhập số điện thoại",
        text82: "Ngày sinh nhật",
        text83: "Vui lòng chọn ngày sinh",
        text84: "Vui lòng nhập qq",
        text85: "Vui lòng nhập ID WeChat",
        text86: "giới tính",
        text87: "Thư",
        text88: "địa chỉ",
        text89: "Vui lòng nhập địa chỉ",
        text90: "Quá trình gửi thành công, vui lòng liên hệ với ủy viên trong vòng 3 ngày để mở và cung cấp số đại lý và liên kết đại lý của bạn.",
        text91: "đăng ký ngay lập tức",
        text92: "hiểu",
        text93: "Vui lòng nhập 4-15 chữ cái hoặc số",
        text94: "Không được để trống số điện thoại",
        text95: "Xin vui lòng nhập một số điện thoại hợp lệ",
        text96: "Vui lòng nhập 6-16 chữ cái hoặc số",
        text97: "Hai mật khẩu không khớp, vui lòng nhập lại",
        text98: "Vui lòng nhập tên của bạn",
        text99: "Tên không được vượt quá 8 ký tự",
        text100: "Vui lòng chọn ngày sinh",
        text101: "Vui lòng nhập số QQ chính xác",
        text102: "Vui lòng nhập đúng ID WeChat",
        text103: "vui lòng nhập email vaild của bạn",
        text104: "Ứng dụng thành công",
        text105: "Vui lòng nhập thông tin đầy đủ",
        text106: "Nhập lại mật khẩu",
        text107: "vui lòng nhập email của bạn",
    },
    // 帮助中心
    'helpamjs': {
        'text0': `Về chúng tôi`,
        'text1': `${skin} được đăng ký tại Manila, Philippines, có giấy phép cá cược thể thao và sòng bạc trực tuyến do First Cagayan Leisure and Resort Corporation thuộc Đặc khu kinh tế Cagayan của Chính phủ Philippines cấp và được ủy quyền và quản lý bởi chính phủ Philippines và Cagayan Đặc khu kinh tế CEZA. Tất cả các hoạt động trò chơi đều tuân thủ Hiệp ước về trò chơi của chính phủ Philippines. Trong thị trường trò chơi trực tuyến ngày càng cạnh tranh, chúng tôi không ngừng tìm kiếm những ý tưởng mới, tìm kiếm những ý tưởng mới nhất và tuân thủ các dịch vụ tốt nhất. Cung cấp cho khách hàng những dịch vụ chất lượng cao, sản phẩm tiên tiến, trải nghiệm giải trí phong phú là mục đích vĩnh cửu của ${skin} chúng tôi. `,
        'text2': 'Chúng tôi nghĩ về những gì bạn muốn và cung cấp các biện pháp bảo mật và quyền riêng tư cá nhân tiêu chuẩn cao và toàn diện cũng như hệ thống bảo mật tài chính mạnh mẽ. Thanh toán trực tuyến của người chơi và tất cả các giao dịch ngân hàng sẽ được thực hiện bởi các tổ chức tài chính quốc tế thông qua một cổng mạng an toàn và bảo mật tiêu chuẩn cao (Tiêu chuẩn mã hóa SSL 128 bit). Nhập dữ liệu tài khoản người chơi cũng phải có ID và mật khẩu đăng nhập duy nhất của người chơi để đảm bảo rằng tiền của khách hàng được an toàn và bảo mật. Đồng thời, các biện pháp mã hóa tiên tiến nhất được sử dụng để đảm bảo an toàn cho trò chơi của người chơi, phát hiện và giám sát nền 7 × 24 giờ đảm bảo rằng chúng tôi có thể cung cấp một không gian trò chơi trực tuyến hoàn toàn công bằng và an toàn. Mọi hoạt động của khách hàng trên nền tảng này đều được bảo mật nghiêm ngặt và chúng tôi sẽ không bao giờ tiết lộ dữ liệu của khách hàng cho bên thứ ba (kể cả các đơn vị có liên quan). ',
        'text3': 'Chúng tôi hứa với bạn:',
        'text4': 'Dựa trên sự chính trực',
        'text5': 'Là một nhà điều hành trò chơi trực tuyến chuyên nghiệp quốc tế, chúng tôi hứa sẽ cung cấp cho mọi khách hàng những trò chơi trò chơi an toàn nhất và công bằng nhất cùng với đầy đủ các dịch vụ. ',
        'text6': 'Cung cấp các trò chơi đa dạng và thực tế nhất',
        'text7': 'Các nhà cái trong video trực tiếp của chúng tôi được đào tạo chuyên nghiệp bởi các sòng bạc quốc tế, chuyên nghiệp, hiệu quả và nghiêm ngặt! Mỗi ván bài được chia bởi người chia bài trong thời gian thực, không phải là kết quả xác suất được thiết lập trước trên máy tính. Thông qua công nghệ webcasting công nghệ cao, nó sẽ mang lại cho bạn trải nghiệm thú vị như ở trong sòng bạc! ',
        'text8': 'Cá cược thể thao của chúng tôi có các nhà điều hành phòng giao dịch cấp cao nhất, đầu tư nhiều nhân lực và tài nguyên nhằm mang đến những sự kiện trọn vẹn và lối chơi phong phú cho người chơi yêu thích thể thao. ',
        'text9': 'Các loại trò chơi xổ số đều dựa trên kết quả trò chơi do kết quả chính thức tạo ra, cho phép người chơi giải trí chân thực nhất trong giao diện cá cược sống động. ',
        'text10': 'Trò chơi điện tử của chúng tôi được hợp tác phát triển với nhóm phát triển trò chơi điện tử hàng đầu thế giới, sử dụng các hiệu ứng hình ảnh hoạt hình tiên tiến nhất, sử dụng xác suất tạo số ngẫu nhiên công bằng nhất, cho phép bạn yên tâm thưởng thức các trò chơi điện tử đa dạng, thú vị và giàu hình ảnh. ',
        'text11': 'Tất cả các trò chơi của chúng tôi đều có những ưu điểm chung',
        'text12': 'Cung cấp dịch vụ khách hàng xuất sắc',
        'text13': 'Chúng tôi có đội ngũ dịch vụ khách hàng được lựa chọn với các tiêu chuẩn cao nhất và đào tạo nghiêm ngặt nhất để cung cấp cho bạn dịch vụ khách hàng trực tuyến theo thời gian thực 365 ngày x 24 giờ. Mỗi ý kiến tư vấn của người chơi sẽ được giải đáp một cách chuyên nghiệp và thân tình, đồng thời giúp bạn giải quyết vấn đề đồng thời mang đến cho bạn trải nghiệm như một ngôi nhà xa nhà! thời gian. và đề xuất; tổ chức nhiều ưu đãi và khuyến mãi hơn để cung cấp cho khách hàng nhiều phản hồi và bất ngờ hơn. ',
        'text14': 'Gửi và rút tiền hiệu quả và an toàn',
        'text15': 'Chúng tôi cung cấp cho các thành viên nhiều lựa chọn gửi và rút tiền an toàn và dễ dàng. Chúng tôi luôn tuân thủ các nguyên tắc "Biết về thành viên của chúng tôi (KYC)" và Chống rửa tiền (AML), đồng thời hợp tác với các cơ quan quản lý tài chính của bên thứ ba để đảm bảo tuân thủ tối đa các luật và quy định có liên quan. ',
        'text16': 'Chúng tôi rất tự hào về công ty mà chúng tôi đã xây dựng và muốn tất cả người dùng tận hưởng và thu lợi nhuận từ các sản phẩm và dịch vụ được thiết kế tốt của chúng tôi trong một môi trường an toàn và thú vị. Chúng tôi cung cấp phương thức rút tiền nhanh nhất và an toàn nhất, và các thành viên có thể đăng ký rút tiền trong 365 ngày x 24 giờ. ',
        'text17': 'Giúp bạn trở thành người chiến thắng lớn',
        'text18': 'Chúng tôi sẽ tiếp tục tung ra tất cả các hình thức giảm giá có lợi cho các thành viên mới và cũ, gửi những điều bất ngờ mỗi ngày, phản hồi mỗi tuần và đưa ra hàng tháng để bạn có thể trở thành người chiến thắng lớn. ',
        'text19': 'Tích cực tham gia các tổ chức từ thiện',
        'text20': 'Chúng tôi phục vụ mọi thành viên hết lòng, chúng tôi cũng gánh vác trách nhiệm đối với xã hội và từ thiện. Lấy nó từ xã hội và sử dụng nó cho xã hội cũng là một trong những nguyên lý mà chúng tôi đã và đang tuân thủ. Chúng tôi tích cực tham gia vào các hoạt động quyên góp khác nhau và sẽ không bao giờ quên đóng góp nhiều hơn nữa cho nhu cầu của xã hội. ',
        'text21': 'Công ty quảng bá cờ bạc có kiểm soát. Chúng tôi khuyên bạn nên tuân theo một số quy tắc cơ bản: ',
        'text22': '• Cấm các trò chơi cờ bạc dành cho người dưới 18 tuổi. Vui lòng đảm bảo bạn trên 18 tuổi trước khi tham gia trò chơi. ',
        'text23': '• Tùy chỉnh ngân sách chơi game của bạn và đặt cược vào ngân sách của bạn. ',
    },
    'problem': {
        'text1': `Về ${skin}`,
        'text2': `${skin} được thành lập vào năm 2009. Nó có giấy phép hoạt động trò chơi trực tuyến do chính phủ Philippines cấp. Đây cũng là một trang web trò chơi trực tuyến có giấy phép trò chơi hợp pháp do Ủy ban trò chơi trực tuyến lập. Đây là một sòng bạc chuyên nghiệp hoạt động hợp pháp . Công ty đã hợp tác kỹ thuật với Asia Gaming, Boeing (BBIN), PT (PlayTech) và MG (MicroGaming) để cùng tạo ra một nền tảng chơi game chất lượng cao với tính hợp pháp và công bằng tuyệt đối. Để cung cấp cho khách hàng của chúng tôi nhiều hoạt động chơi trò chơi và cung cấp các phần thưởng hậu hĩnh. `,
        'text3': 'Quản lý một cách liêm chính',
        'text4': 'Công ty tuân thủ nghiêm ngặt quy tắc chơi game do chính phủ Philippines quy định và dựa trên "sự chân thành". 100% tín dụng đảm bảo rằng trò chơi là công bằng và chính đáng. Mỗi khoản tiền gửi và rút tiền của bạn sẽ được hoàn tất một cách an toàn và nhanh chóng sau khi được xem xét ',
        'text5': 'công bằng và công bằng',
        'text6': 'Các nhà tổ chức giải trí trực tiếp phải được đào tạo chuyên nghiệp nghiêm ngặt và được cấp chứng chỉ của các sòng bạc quốc tế, và tất cả các trò chơi đều phản ứng theo hành động của người tổ chức, thay vì các kết quả đặt trước tỷ lệ cược máy tính không thú vị. Chúng tôi sử dụng công nghệ webcasting công nghệ cao để mang đến cho bạn trải nghiệm thú vị của sòng bạc. Trò chơi điện tử sử dụng xác suất tạo số ngẫu nhiên công bằng nhất, cho phép bạn yên tâm thưởng thức các trò chơi giải trí đa dạng và rực rỡ. ',
        'text7': 'An ninh và sự riêng tư',
        'text8': 'Để đảm bảo an ninh mạng của khách hàng, công ty đã thành lập một trung tâm bảo trì an ninh mạng và đạt được chứng chỉ quốc tế GEOTRUST để đảm bảo tính công bằng và khách quan của trang web. Trong cá cược trò chơi, chúng tôi sử dụng hệ thống truyền mã hóa SSL 128 bit và bảo vệ mã hóa MD5 cung cấp khả năng bảo vệ thêm cho nền tảng trò chơi và thông tin khách hàng. Chúng tôi cam kết sẽ tiếp tục không nỗ lực thực hiện nghiêm túc hệ thống bảo mật và quyền riêng tư để đảm bảo an toàn thông tin cá nhân và đảm bảo rằng thông tin của bạn sẽ không bị tiết lộ cho bất kỳ bên nào. ',
        'text9': 'Đội ngũ dịch vụ khách hàng thân thiện và chuyên nghiệp',
        'text10': 'Các chuyên gia dịch vụ khách hàng thân thiện và thân thiện của chúng tôi được đào tạo bài bản và có thể cung cấp cho bạn dịch vụ cá nhân và cao cấp nhất đồng thời giúp giải quyết các vấn đề của khách hàng. Chúng tôi cũng có chức năng trò chuyện trực tuyến để khách hàng có thể liên hệ với chúng tôi ngay lập tức. ',
        'text11': 'vấn đề thường gặp',
        'text12': `Một. ${skin} Đăng ký`,
        'text13': `1. Cách đăng ký tài khoản tiền thật ${skin}? `,
        'text14': `Nhấp vào nút" Tài khoản Miễn phí "ở góc trên bên phải của trang web và điền vào thông tin thực và hiệu quả theo yêu cầu của trang bật lên và hệ thống nền của ${skin} sẽ tự động tạo tài khoản. Nếu bạn có bất kỳ câu hỏi nào trong quá trình này, vui lòng liên hệ ngay với dịch vụ khách hàng của ${skin}. `,
        'text15': `2. Làm thế nào để đăng nhập vào trò chơi ${skin}? `,
        'text16': `Bạn có thể đăng nhập vào trang web chính thức hoặc thông qua ứng dụng khách, đăng nhập vào trang trò chơi của ${ skin }.`,
        'text17': '3. Bạn đã dùng thử chưa? ',
        'text18': 'Chúng tôi cung cấp tài khoản dùng thử miễn phí để bạn biết và làm quen với nền tảng trò chơi và các loại trò chơi của chúng tôi. Nếu bạn hài lòng với nền tảng trò chơi của chúng tôi, bạn có thể gửi tiền để chơi bằng tiền thật. (Lưu ý: Phần thưởng dùng thử chỉ dành cho chơi thử và không tham gia rút tiền) ',
        'text19': `3. Tại sao tôi không thể duyệt trang web ${ skin } một cách bình thường`,
        'text20': `Trang web ${ skin } có thể khiến bạn không thể duyệt trang web một cách bình thường khi không thể chống lại sự can thiệp của các yếu tố.Trong trường hợp này, vui lòng liên hệ với nhân viên dịch vụ khách hàng của ${ skin } ngay lập tức. `,
        'text21': `4. ${ skin } Giới hạn độ tuổi chơi game của khách hàng`,
        'text22': `${ skin } Giới hạn độ tuổi tối thiểu cho tài khoản tiền thật là 21 tuổi.Bất kỳ ai dưới 21 tuổi, ${ skin } đều từ chối đăng ký! `,
        'text23': `Hai.Tài khoản ${ skin } `,
        'text24': '1. Cách thay đổi thông tin đăng ký',
        'text25': `${ skin } phải được đăng ký bằng tên thật của bạn.Sau khi đăng ký thành công, tên và số tài khoản không thể thay đổi; thông tin thẻ ngân hàng và mật khẩu có thể được thay đổi trong[Trung tâm thành viên]. Nếu bạn gặp bất kỳ sự cố nào trong quá trình trong quá trình này, bạn có thể liên hệ với dịch vụ khách hàng của ${ skin }.`,
        'text26': '2. Tôi nên làm gì nếu quên mật khẩu? ',
        'text27': `${ skin } có tổng cộng 2 mật khẩu.Một là mật khẩu đăng nhập của bạn để vào trang web hoặc trò chơi, hai là mật khẩu bảo mật dùng khi rút tiền. `,
        'text28': 'A. Quên mật khẩu đăng nhập: Nhấp vào "Quên mật khẩu" ở góc trên bên phải của trang web:',
        'text29': '1. Đặt lại mật khẩu đăng nhập bằng cách nhập mật khẩu an toàn',
        'text30': '2. Đặt lại mật khẩu đăng nhập thông qua địa chỉ email liên kết với thông tin đăng ký của bạn;',
        'text31': '3. Lấy mật khẩu đăng nhập thông qua điện thoại di động được liên kết với tài khoản trò chơi của bạn;',
        'text32': 'B. Quên mật khẩu bảo mật:',
        'text33': '1. Vui lòng đăng nhập vào tài khoản của bạn trước, nhập [Trung tâm thành viên]> [Quản lý bảo mật]> [Mật khẩu bảo mật], và đặt lại mật khẩu bảo mật bằng cách đặt câu hỏi bảo mật;',
        'text34': '2. Đặt lại câu hỏi bảo mật và mật khẩu thông qua điện thoại di động liên kết với tài khoản trò chơi. ',
        'text35': '3. Cách hoàn tất tiền gửi',
        'text36': 'MỘT. đặt cọc',
        'text37': `Sau khi đăng ký tài khoản thành công, bạn có thể bắt đầu kinh doanh tiền gửi.Cho đến nay, ${ skin } chấp nhận: "Thanh toán bằng mã quét", "Thanh toán trực tuyến", "Thanh toán qua WeChat", "Chuyển khoản Alipay" và "Chuyển khoản ngân hàng qua Internet / ATM và tại quầy" và các phương thức gửi tiền khác.Hãy lựa chọn phương thức gửi tiền phù hợp nhất theo tình hình thực tế. `,
        'text38': 'B. Tôi có thể được thêm vào tài khoản của mình bao lâu sau khi nạp tiền? ',
        'text39': `Theo các phương thức gửi tiền khác nhau mà bạn chọn, thời gian để số tiền đến nơi sẽ khác nhau, nhưng nhìn chung, số tiền đó sẽ đến ngay lập tức.Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng của ${ skin }.`,
        'text40': 'C. Có bất kỳ yêu cầu đặt cược nào sau khi gửi tiền không? ',
        'text41': 'Sau khi bạn hoàn tất việc gửi tiền, nếu bạn không yêu cầu bất kỳ khoản tiền thưởng nào, bạn chỉ cần hoàn thành số tiền đặt cược gấp đôi số tiền gửi trước khi bạn có thể đăng ký rút tiền. ',
        'text42': '4. Cách hoàn tất việc rút tiền',
        'text43': 'MỘT. rút tiền ',
        'text44': 'Sau khi tài khoản của bạn đã hoàn thành tất cả các yêu cầu đặt cược, bạn có thể gửi đơn đăng ký sau khi điền thông tin vào [Rút tiền cho thành viên] của [Trung tâm tài chính], và bộ phận liên quan sẽ xử lý ngay lập tức cho bạn; khi đăng ký rút tiền, vui lòng đảm bảo điền thông tin ngân hàng thực. Rút tiền thường có sẵn trong vòng 5 phút. ',
        'text45': 'B. Tại sao lệnh rút tiền bị từ chối? ',
        'text46': `Sau khi doanh nghiệp rút tiền được bộ phận liên quan của ${ skin } trả lại, vui lòng liên hệ với dịch vụ khách hàng của ${ skin } ngay lập tức. `,
        'text47': '5. Làm thế nào để chuyển tiền trong nước? ',
        'text48': 'Sau khi đăng nhập tài khoản, nhấp vào "Nạp tiền - Chuyển khoản trong nhà" ở góc trên bên phải phiên bản web máy tính, nhấp vào "Chuyển tiền" ở cuối trang chủ đối với phiên bản web di động, chọn sảnh game tương ứng, chọn "Chuyển sang nền tảng trò chơi" khi chơi, và chọn "Chuyển sang nền tảng trò chơi" khi rút tiền Chuyển vào Tài khoản Master ". ',
        'text49': `Ba.${ skin } trò chơi`,
        'text50': `1. ${ skin } cung cấp bao nhiêu trò chơi ? `,
        'text51': `${ skin } hiện cung cấp các trò chơi đa nền tảng trả tiền cao, bao gồm: `,
        'text52': `2. Tôi nên làm gì nếu vào game rất chậm ? `,
        'text53': 'Nếu bạn truy cập trang web của chúng tôi hoặc trò chơi quá chậm, chúng tôi khuyên bạn nên xóa bộ nhớ cache! Các bước dành cho trình duyệt IE và 360 là: ',
        'text54': '1. Vui lòng thoát khỏi trang web của chúng tôi trước. ',
        'text55': '2. Nhấp vào "Công cụ" ở góc trên bên phải của trình duyệt, chọn "Tùy chọn Internet", sau đó nhấp vào "Xóa", kiểm tra "Cookie và dữ liệu trang web" và nhấp vào Xóa, đợi quá trình xóa hoàn tất, hãy thử nhập trò chơi một lần nữa! ',
        'text56': `Khoảng Tôi nên làm gì nếu video không thể hiển thị trong trò chơi? ${ skin } `,
        'text57': 'Bạn nên xóa bộ nhớ cache FLASH của mình, vui lòng nhấp vào URL liên kết này:',
        'text58': `Sau đó nhấp vào' Xóa tất cả các trang WEB '. Nhấp vào "Xác nhận" trong cửa sổ bật lên để hoàn tất thao tác! `,
        'text59': '4. Điện thoại di động có thể chơi game không? ',
        'text60': 'Hiện tại trang web hỗ trợ Android và iPhone, bạn có thể sử dụng web game hoặc tải game client. Tuy nhiên, ngay cả trong môi trường WIFI, khả năng hoạt động hiện tại của bộ vi xử lý điện thoại di động vẫn còn hạn chế, và đôi khi màn hình bị đóng băng. Vì vậy, nó được khuyến khích để sử dụng máy tính để chạy trò chơi! ',
        'text61': '5. Làm thế nào để tạo ra kết quả trò chơi và đảm bảo tính công bằng của trò chơi? ',
        'text62': `${ skin } Tất cả kết quả trò chơi đều do nhà cung cấp trò chơi tạo ra, kết quả trò chơi là thực và hợp lệ và ${ skin } sẽ chịu trách nhiệm về tất cả kết quả trò chơi.Các nhà cái trong giải trí trực tiếp đã được đào tạo chuyên môn nghiêm ngặt và được cấp chứng chỉ của các sòng bạc quốc tế, và tất cả các trò chơi đều phản ứng theo hành động của nhà cái, chứ không phải là kết quả xác suất máy tính không thú vị đặt trước.Trò chơi điện tử sử dụng xác suất tạo số ngẫu nhiên công bằng nhất, cho phép bạn yên tâm thưởng thức các trò chơi giải trí đa dạng và rực rỡ. `,
        'text63': '6. Làm thế nào để đặt cược, hủy cược và kiểm tra hồ sơ trò chơi? ',
        'text64': `${ skin } Tất cả cược trò chơi đều có nút đặt cược nổi bật trên trang trò chơi, bạn có thể đặt cược tùy theo thói quen cá cược của mình.Không thể thu hồi trò chơi xèng.Sau khi nhấp vào, trò chơi sẽ tự động bắt đầu, vui lòng nhấp đúng cách.Trò chơi trực tiếp hoặc thể thao có tùy chọn hủy đặt cược cho đến khi cược không được chấp nhận.Tất cả các bản ghi trò chơi có thể được truy vấn trong menu trang trò chơi.Vui lòng liên hệ với dịch vụ khách hàng của ${ skin } nếu bạn có bất kỳ câu hỏi nào. `,
        'text65': '7. Tại sao số tiền đặt cược hiệu quả trong hồ sơ cá cược không phù hợp với số liệu thống kê của bạn? ',
        'text66': 'Nền tảng AG và nền tảng Boeing đều sử dụng Giờ Miền Đông (Giờ Miền Đông Hoa Kỳ, chẳng hạn như giờ New York, Washington), vì vậy thời gian trong hồ sơ cá cược sẽ khác với thời gian cá cược thực tế. Ví dụ, thời gian cá cược thực tế là 20 giờ tối cùng ngày',
        'text67': '8. Trò chơi live-action có phải là trò chơi thời gian thực không? Trang trò chơi có phải là giả mạo không? ',
        'text68': `${ skin } Các nhà cung cấp trò chơi trực tiếp trực tiếp là Playtech, AG, Microgaming, BBIN và tất cả các trò chơi trực tiếp trực tiếp của họ đều là trò chơi thời gian thực.TV bên cạnh bàn PT phát các chương trình của kênh truyền hình CNN của Hoa Kỳ hoặc các đài truyền hình nổi tiếng khác được đồng bộ trên toàn cầu.Ngoài ra, bạn có thể trò chuyện với người chia bài trực tiếp bất cứ lúc nào; AG, bạn có thể gọi điện theo thời gian thực bằng cách quay số điện thoại Philippine trên máy tính để bàn. `,
        'text69': '9. Tôi nên làm gì nếu trò chơi đột ngột dừng lại và gặp trường hợp khẩn cấp? ',
        'text70': `${ skin } Trò chơi đã liên tục được cải tiến và đã trở thành một môi trường trò chơi rất ổn định trong ngành.Tuy nhiên, do sự can thiệp của nhiều yếu tố không kiểm soát được như nhà mạng quốc tế, quốc gia nơi khách hàng đang ở… nên game có thể bị gián đoạn đột ngột.Nếu bạn gặp phải những vấn đề như vậy, vui lòng đừng hoảng sợ và liên hệ ngay với nhân viên dịch vụ khách hàng của ${ skin } để được hỗ trợ.${ skin } xin long trọng cam kết rằng ${ skin } sẽ chịu trách nhiệm và bồi thường cho mọi tổn thất trong trò chơi do ${ skin } gây ra!. `,
        'text71': ` Bốn.${ skin } Yêu cầu đặt cược và tiền thưởng`,
        'text72': `1. ${ skin } cách nhận tiền thưởng`,
        'text73': `${ skin } cung cấp nhiều cơ hội để nhận được phần thưởng ưu đãi.Một số phần thưởng được tự động thêm vào tài khoản của bạn mà không cần đăng ký.Các phần thưởng khác yêu cầu bạn đăng ký và được tự động thêm cho bạn sau khi vượt qua. `,
        'text74': '2. Tiền thưởng có thể bị hủy bỏ không? ',
        'text75': 'Khi tiền thưởng đã được kiếm vào tài khoản của bạn, nó sẽ không thể bị hủy bỏ. ',
        'text76': '3. Có thể rút tiền mà không cần hoàn thành các yêu cầu đặt cược không? ',
        'text77': `${ skin } Tất cả các tài khoản tiền thật sẽ không được rút cho đến khi hoàn thành các yêu cầu đặt cược tiền thưởng tương ứng.Nếu số dư tài khoản của bạn nhỏ hơn 5 RMB, tất cả các yêu cầu đặt cược trước đó sẽ tự động bị xóa. `,

    },
    'declarations': {
        'text88': 'Quy tắc và Tuyên bố',
        'text89': 'Công ty là một tổ chức kinh doanh Internet hợp pháp và những khách hàng có ý định tham gia vào tất cả các trò chơi của công ty được nhắc nhở rằng họ nên chú ý đến các luật và quy định liên quan của quốc gia hoặc khu vực cư trú của họ. Nếu có nghi ngờ, họ nên tìm kiếm địa phương tư vấn pháp luật về các vấn đề liên quan. Các điều khoản này bao gồm tất cả các thỏa thuận giữa bạn và công ty khi chơi game trên trang web này. ',
        'text90': '1. Yêu cầu luật định',
        'text91': 'Theo luật của quốc gia cư trú của bạn, bạn phải đủ tuổi hợp pháp để sử dụng trang web này. ',
        'text92': 'Nếu bạn dưới 21 tuổi, bạn không được sử dụng trang web này trong bất kỳ trường hợp nào. ',
        'text93': 'Tính hợp pháp của cờ bạc trực tuyến chưa được làm rõ trong luật pháp của một số khu vực hoặc quốc gia, và thậm chí một số khu vực hoặc quốc gia đã quy định rõ ràng cờ bạc trực tuyến là một hành vi bất hợp pháp. Chúng tôi không có ý định mời bất kỳ ai sử dụng trang web này một cách bất hợp pháp tại các khu vực hoặc quốc gia này. Sự tồn tại của trang web này không có nghĩa là chúng tôi quảng bá cờ bạc trực tuyến ở bất kỳ khu vực hoặc quốc gia nào mà cờ bạc là bất hợp pháp. Bạn phải hoàn toàn chịu trách nhiệm về việc xác định xem việc sử dụng cờ bạc trực tuyến có hợp pháp ở khu vực hoặc quốc gia nơi bạn sinh sống hay không. ',
        'text94': '2. Quy định về tài khoản',
        'text95': 'Khách hàng chỉ được có một tài khoản, nếu phát hiện khách hàng có nhiều tài khoản, công ty có quyền phong tỏa tài khoản của khách hàng và hủy tất cả các cược thắng trong tài khoản. Công ty có quyền bảo lưu quyền hủy bỏ bất kỳ số tiền nào khiến số dư tài khoản không chính xác. Ngoài ra, nếu phát hiện chủ tài khoản và người thân dùng chung nhiều tài khoản khác nhau, công ty có quyền hạn chế mỗi khách hàng chỉ được giữ tối đa một tài khoản. Nếu tình huống trên đã cấu thành hành vi gian lận, gây tổn hại hoặc xâm phạm quyền hoặc lợi ích của công ty và người dùng khác, công ty có quyền yêu cầu người dùng bồi thường và tịch thu số dư trong tài khoản của người dùng, đồng thời chấm dứt hoặc hủy bỏ quyền của người dùng để tiếp tục sử dụng hệ thống ',
        'text96': '3. Thanh toán giao dịch',
        'text97': 'Người dùng có trách nhiệm và nghĩa vụ kiểm tra số dư tài khoản của mình trước hoặc sau mỗi giao dịch trò chơi và trước khi rời khỏi trò chơi. Khi người dùng nghi ngờ số dư bị sai, anh ta phải khiếu nại với nhân viên dịch vụ khách hàng trực tuyến của trang web này hoặc bộ phận dịch vụ khách hàng của công ty trước khi vòng tiếp theo của trò chơi (khi trò chơi bắt đầu và sự xuất hiện của màn hình kiểm tra được coi là một vòng). Nếu bạn không làm như vậy, công ty sẽ coi rằng bạn đã từ bỏ quyền khiếu nại và chấp nhận dữ liệu số dư hiện tại là chính xác. ',
        'text98': 'Đối với người dùng thực hiện các giao dịch trực tiếp với công ty, tên tài khoản ngân hàng phải giống với tên được sử dụng trong quá trình đăng ký và đăng ký thành viên. Nếu người dùng sử dụng thẻ tín dụng hoặc thẻ ghi nợ, tên chủ thẻ cũng phải giống với tên được sử dụng trong quá trình đăng ký và đăng ký thành viên. Trong trường hợp tên tài khoản ngân hàng hoặc tên chủ thẻ không phù hợp với tên dùng để đăng ký tài khoản (đăng ký thành viên), công ty có quyền từ chối giải quyết các giao dịch có liên quan ',
        'text99': 'Đối với người dùng thanh toán giao dịch trực tiếp với công ty, công ty sẽ gửi thu nhập chơi game vào tài khoản người dùng của bạn theo thông tin phương thức giao dịch do người dùng cung cấp. Trong quá trình gửi bất kỳ khoản tiền hoặc thu nhập nào vào tài khoản, nếu xảy ra lỗi giao dịch do người dùng cung cấp không đủ thông tin hoặc thông tin cung cấp không chính xác, công ty sẽ không chịu trách nhiệm dưới mọi hình thức. Khi người dùng nhận thấy rằng số tiền trong tài khoản không chính xác, người dùng có trách nhiệm thông báo cho chúng tôi ngay lập tức. ',
        'text100': 'Công ty có quyền khấu trừ và khấu trừ các khoản phí nêu trên từ số dư tài khoản của người dùng đối với các khoản phí ngân hàng mà công ty phải chịu do giao dịch thanh toán của người dùng, bao gồm phí xử lý, bưu phí điện tín, v.v. ',
        'name1': 'JACKPOT sẽ khấu trừ một khoản phí quản lý nhóm giải thưởng nhất định theo số tiền trúng thưởng cụ thể sau khi trúng số, dựa trên số tiền mà công ty nền tảng trò chơi tính phí. ',
        'name2': '4. Quyền và nghĩa vụ của công ty',
        'name3': 'Công ty sẽ thực hiện nhiều biện pháp khác nhau để bảo vệ tính bảo mật cho tài khoản của người chơi. ',
        'name4': 'Công ty sẽ không bao giờ sử dụng bất kỳ phương tiện nhân tạo nào để ảnh hưởng đến tính công bằng của trò chơi. ',
        'name5': 'Công ty không chịu trách nhiệm về bất kỳ hành vi sử dụng bất hợp pháp tài khoản người chơi nào. ',
        'name6': 'Công ty có quyền sửa đổi tất cả các hệ thống và quy tắc. ',
        'name7': `Nếu công ty phát hiện ra rằng người chơi gian lận, tấn công hoặc phá hủy hoạt động bình thường của hệ thống trong trò chơi và sử dụng các phương pháp rõ ràng hoặc ngụ ý để vu khống, vu khống và đe dọa" ${skin} Casino ", hành vi vu khống, sau khi bị được công ty khuyên can, công ty có mọi quyền từ chối / cấm và thu hồi việc người chơi tiếp tục sử dụng hệ thống, "sẽ bị truy tố theo luật pháp của Cộng hòa Philippines". `,
        'name8': 'Công ty chúng tôi có "dữ liệu dự phòng" cho dữ liệu cá nhân của bạn và hồ sơ trong hệ thống. Tuy nhiên, trong trường hợp không may xảy ra các yếu tố bất khả kháng (như thiên tai, khủng bố, chiến tranh, bạo loạn ...) dẫn đến mất dữ liệu trên, công ty sẽ cố gắng hết sức để khôi phục lại toàn bộ dữ liệu lịch sử, dữ liệu đã được khôi phục cũng sẽ được coi là công bằng. ',
        'name9': '5. Khuyến mãi và ưu đãi',
        'name10': 'Tất cả các chương trình khuyến mãi, ưu đãi và các sự kiện đặc biệt phải tuân theo các Điều khoản này và các quy tắc và điều kiện do Công ty đưa ra tại từng thời điểm cho các chương trình khuyến mãi cụ thể. Công ty có quyền đình chỉ, hủy bỏ hoặc sửa đổi các điều khoản và điều kiện của các chương trình khuyến mãi, ưu đãi và sự kiện đặc biệt đó vào bất kỳ lúc nào. ',
        'name11': 'Nếu Công ty xác định rằng có sự lạm dụng hoặc cố tình lạm dụng chương trình khuyến mại hoặc phần thưởng, hoặc có thể hưởng lợi từ việc lạm dụng đó, Công ty có quyền chặn, từ chối, đình chỉ hoặc thu hồi bất kỳ người dùng nào theo cách thức mà họ cho là phù hợp. khuyến mãi, ưu đãi và các sự kiện đặc biệt. ',
        'name12': '6. Chính sách sửa đổi điều khoản',
        'name13': 'Công ty có toàn quyền sửa đổi, cập nhật và sửa đổi các điều khoản và quy tắc trò chơi này theo thời gian. Mọi chỉnh sửa, cập nhật và sửa đổi sẽ được đăng trên trang web chính thức. Các Điều khoản và Quy tắc Trò chơi này, như được sửa đổi, cập nhật hoặc sửa đổi, sẽ có hiệu lực khi được xuất bản. Khi người dùng tiếp tục sử dụng trang web hoặc trò chơi sau khi các sửa đổi, cập nhật và sửa đổi nêu trên được công bố, được coi là người dùng đồng ý và chấp nhận các chỉnh sửa, cập nhật và sửa đổi của công ty. ',
        'name14': 'Chúng tôi khuyên bạn nên thường xuyên đọc các quy tắc và điều khoản của trang web này và các quy tắc của trò chơi. ',
        'name15': 'Các chỉnh sửa, cập nhật và sửa đổi trên, công ty có thể quyết định có thông báo cho người dùng hay không. Công ty không có nghĩa vụ phải thông báo cho người dùng về các chỉnh sửa, cập nhật và sửa đổi ở trên. ',
    },
    // 会员存款
    depositDemo: {
        text1: "Tiền gửi Thành viên",
        text2: "1: Nhấp vào [Tiền] - [Tiền gửi của Thành viên]",
        text3: "1: Tiền gửi của công ty",
        text4: "Chọn ngân hàng: chọn ngân hàng nhận của chúng tôi",
        text5: "Tên đặt cọc: Điền tên bên chuyển nhượng: Trương San",
        text6: "Số tiền nạp: điền số tiền sẽ được nạp, chẳng hạn như: 501.2 (phần định trị được khuyến nghị không phải là 0 hoặc với số thập phân)",
        text7: "Nhấn tiếp]",
        text8: "Sao chép thông tin thanh toán cần thiết để chuyển khoản, nhấp vào biểu tượng văn bản để sao chép, sau khi chuyển khoản thành công, hãy đợi bộ phận tài chính thêm xử lý cho bạn.",
        text9: "Có thể sử dụng các phương thức chuyển khoản sau: chuyển khoản ngân hàng trực tuyến, chuyển khoản UnionPay, thẻ chuyển khoản Alipay",
        text10: "1: Gửi tiền trực tuyến",
        text11: "Kênh Nạp Tiền - [Thẻ Chuyển Khoản Ngân Hàng Trực Tuyến Mobile Banking] hoặc [Ngân Hàng Trực Tuyến 100-10000]",
        text12: "Số tiền nạp: điền số tiền cần nạp, chẳng hạn như: 1234,5 (phần định trị được khuyến nghị không phải là 0 hoặc có số thập phân)",
        text13: "Nhấn tiếp]",
        text14: "Sao chép thông tin thanh toán cần thiết để chuyển khoản. Sau khi nhận được chuyển khoản thành công, tiền thưởng sẽ tự động được thêm vào.",
    },
    // 会员取款
    withdrawDemo: {
        text1: "Rút tiền thành viên",
        text2: "1: Ràng buộc tài khoản thu tiền",
        text3: "Nhấp vào [Thông tin cá nhân] - [Quản lý phương thức thanh toán]",
        text4: "Nhấp vào [Thêm thẻ ngân hàng] hoặc [Thêm ví origo]",
        text5: "Tên ngân hàng: chọn ngân hàng",
        text6: "Tên chi nhánh phụ: điền vào tên chi nhánh phụ",
        text7: "Số thẻ ngân hàng: điền số tài khoản nhận tiền",
        text8: "Xác nhận số thẻ: điền lại số tài khoản nhận",
        text9: "Mã xác minh (không bắt buộc): Nhấp vào [Gửi mã xác minh] để nhận mã xác minh của chúng tôi và điền vào",
        text10: "Nhấp vào [OK]: Liên kết thẻ thành công",
        text11: "2: Nhấp vào [Tiền] - [Rút tiền của thành viên]",
        text12: "Thu thập một lần nhấp: Nhấp để thu thập số dư của tất cả các nền tảng vào số dư tài khoản.",
        text13: "Phương thức thanh toán: chọn thẻ ngân hàng được sử dụng để thanh toán",
        text14: "Số tiền rút: Nhập số tiền được rút",
        text15: "Số tiền thực nhận: giống như lần rút tiền, nếu không có thì không nộp, không rút được",
        text16: "Phí rút tiền: Nếu có phí khấu trừ, không nộp và bạn không thể rút tiền",
        text17: "Chi phí Hành chính: Nếu có một khoản phí khấu trừ, không nộp nó và bạn không thể thanh toán",
        text18: "Giảm trừ ưu đãi: Có khấu trừ phí thì không nộp, không nộp thì thôi",
        text19: "Mật khẩu giao dịch: Nhập mật khẩu giao dịch và nhấp vào OK.",

    },
};
