<template>
  <div id="app" class="theme">
    <router-view/>
  </div>
</template>

<script>
import { _systemInit } from './core/comp/global'
import {_getLocale} from '@/core/utils/utils'
export default {
  created () {
    _systemInit()
    _getLocale()
  },
}
</script>

<style>
/* 全局样式 */
html, body {
  height: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-attachment: fixed;
  background-size: cover;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
::-webkit-scrollbar {
  display: none;
}
li {
  list-style-type: none;
}

img {
  box-sizing: border-box;
}
div {
  box-sizing: border-box;
}

/* 主题样式 */
.theme { color: #333; }
.theme .background-color { background-color: #fff }


/* 穿透样式 */
.deep .component-name .class-name { color: antiquewhite !important; }


/* element样式修改 */
.element-class { color: black !important; }
.home-electron-component .swiper-button-prev {
    background-image: url('./assets/images/index/black_homeRecommended_jiantou_left_i.png');
    background-size: 10px 20px;
    left: -36px;
    top: 40px;
}
.home-electron-component  .swiper-button-next {
    background-image: url('./assets/images/index/black_homeRecommended_jiantou_right_i.png');
    background-size: 10px 20px;
    right: -36px;
    top: 40px;
}
.page-elec .el-input__inner {
    width: 270px;
    height: 40px;
    color: #fff;
    font-weight: 700;
    background-color: #212121;
    border: 1px solid;
    border-color: #434343;
    border-radius: 90px;
}
.page-elec .swiper-button-prev, .page-chess .swiper-button-prev {
  position: absolute;
  background-image: url('./assets/images/index/black_homeRecommended_jiantou_left_i.png');
  background-size: 10px 20px;
  left: -18px;
  top: 22px;
}
.page-elec .swiper-button-next, .page-chess .swiper-button-next {
  position: absolute;
  background-image: url('./assets/images/index/black_homeRecommended_jiantou_right_i.png');
  background-size: 10px 20px;
  right: -18px;
  top: 22px;
}

.page-elec .el-pager li, .page-chess .el-pager li{
  width:30px;
  height:30px;
  line-height: 30px;
  min-width: 30px;
  background-color:#343434;
  border:1px solid;
  border-color:#999999;
  border-radius:50%;
  font-weight:700;
  color:#999999;
  font-size:16px;
  margin: 0 5px;
}
.page-elec .el-pager li.active,.page-chess .el-pager li.active {
  border: none;
  color:#000000;
  background-color:#fdf86a;
}
.page-elec  .el-pager li:hover,.page-chess .el-pager li:hover {
  color:#000000;
  background-color:#fdf86a;
}
.page-elec .el-pagination .btn-next, .page-elec .el-pagination .btn-prev,
.page-chess .el-pagination .btn-next, .page-chess .el-pagination .btn-prev {
  color: #999;
  text-align: center;
  background-color:#343434;
  border:1px solid;
  border-color:#999999;
  border-radius:90px;
}
.page-elec .el-pagination .btn-next, .page-chess .el-pagination .btn-next {
  margin-left: 5px;
}
.page-elec .el-pagination .btn-prev, .page-chess .el-pagination .btn-prev {
  margin-right: 5px;
}
.page-elec .el-pagination .btn-next:hover,.page-elec .el-pagination .btn-prev:hover,
.page-chess .el-pagination .btn-next:hover,.page-chess .el-pagination .btn-prev:hover {
  color: #000;
  background-color:#fdf86a;
  border-color:#fdf86a;
}
.page-elec .el-pager li.btn-quicknext, .page-elec  .el-pager li.btn-quickprev, .page-chess .el-pager li.btn-quicknext, .page-chess .el-pager li.btn-quickprev {
  color: #999;
}
.page-live .swiper-button-prev {
  position: absolute;
  background-image: url('./assets/images/index/black_homeRecommended_jiantou_left_i.png');
  background-size: 10px 20px;
  left: 274px;
  top: 210px;
}
.page-live  .swiper-button-next {
  position: absolute;
  background-image: url('./assets/images/index/black_homeRecommended_jiantou_right_i.png');
  background-size: 10px 20px;
  right: -8px;
  top: 210px;
}
.page-proxy .el-form-item {
  margin-bottom: 18px;
}
.page-proxy .el-form-item__label {
  padding-right: 20px;
}
.page-proxy .el-radio__input.is-checked .el-radio__inner {
  border-color: #fdf86a;
  background-color:#fdf86a;
}
.page-proxy .el-radio__inner::after {
  background-color: #242424;
}
.page-proxy .el-radio__inner:hover {
    border-color: #fdf86a;
}
.page-proxy .el-radio__input.is-checked+.el-radio__label {
  color: #666;
}
.register-form .el-radio__input.is-checked .el-radio__inner {
  border-color: #fdf86a;
  background-color:#fdf86a;
}
.register-form .el-radio__input.is-checked+.el-radio__label {
  color: #606266;
}
.register-form .el-radio__inner::after {
  background-color: #242424;
}
.register-form .el-input__inner:focus {
  outline: none;
  border-color: #dcad02;
}
.register-form .el-radio__inner:hover {
    border-color: #fdf86a;
}
.register-form .el-radio__input.is-checked .el-radio__inner {
  border-color: #fdf86a;
  background-color:#fdf86a;
}
.register-form .el-input__inner {
  width: 448px;
  height: 40px;
  padding-left: 40px;
  background-color: #f8f8f8;
  border: 1px solid;
  border-color: #dcdfe6;
  border-radius: 3px;
  box-sizing: border-box;
}
.page-proxy  .el-form-item__label {
  font-weight: 700;
  color: #666;
}
.page-proxy .el-date-editor.el-input, .page-proxy .el-date-editor.el-input__inner {
  width: 100%;
}
.el-popover {
  position: absolute !important;
  left: 0 !important;
  margin-top: 0 !important;
}
.el-popover {
  background-image:linear-gradient(180deg,#000000 0%,#2e2100 100%) !important; 
  box-shadow:0px 10px 10px rgba(0, 0, 0, 0.04) !important;
  border: none !important;
  padding-left: 0 !important;
  z-index: 11111;
}
.popper__arrow {
  display: none !important;
}
.el-carousel__arrow i{
  font-size: 24px;
  font-weight: 700;
  color: #00A6FF;
}
.wrap-menu-popover_content  .el-carousel__arrow--right {
  right: -80px;
}
.wrap-menu-popover_content .el-carousel__arrow--left {
  left: -80px;
}
.login-box .el-dialog {
  width: 500px;
  min-height: 416px;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #707070;
  border-radius: 10px;
}
.login-box .login-dialog .el-dialog__header {
  padding: 0;
}
.register-form .el-dialog {
  /* height: 542px; */
}
.content-box .el-table th.el-table__cell {
  background-color: #ce06cd;
  color: #fff;
}
.content-box .el-table td.el-table__cell, .content-box .el-table th.el-table__cell.is-leaf {
  border-color: #E3E3E3;
}
.content-box .el-table tr {
  background-color: #fff;
}
.content-box .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: #F5F7FA;
}
.el-popup-parent--hidden {
  padding-right: 0 !important;
}
.content-box .el-table::before {
  background-color: transparent;
}
.pagination-boxs {
    width: 100%;
    text-align: right;
    margin-top: 36px;
}
.pagination-boxs  .el-pager li{
  background-color: transparent;
}
.pagination-boxs .el-pager {
  color: #323232;
  background-color: transparent;
}
.pagination-boxs .el-pager li.active, .pagination-boxs .el-pager li:hover {
  font-weight:700;
  color:#00A6FF;
}
.pagination-boxs .el-pager li.btn-quicknext, .pagination-boxs .el-pager li.btn-quickprev {
  color: #323232;
}
.pagination-boxs .el-pagination button:disabled {
  color: #323232;
  background-color: transparent;
}
.pagination-boxs .el-pagination .btn-next,  .pagination-boxs .el-pagination .btn-prev {
  color: #323232;
  background-color: transparent;
}
.pagination-boxs .el-pagination .btn-next:hover, .pagination-boxs .el-pagination .btn-prev:hover {
   color:#00A6FF;
}
.el-select-dropdown__item {
  display: flex;
  align-items: center;
}
.cursorPointer {
  cursor: pointer;
}
.swipers .swiper-button-next {
  right: -50px;
}
.el-table__body-wrapper::-webkit-scrollbar {
  display: block;
}
/*定义滚动条轨道
 内阴影+圆角*/

.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {
    background-color: #ccc;
    width: 5px;
}
/*定义滑块
 内阴影+圆角*/
.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {
    height: 20px;
    width: 5px;
    border-radius: 10px;
    background: linear-gradient(180deg, #c4e8fc, #42b4f1, #00a6ff);
}
.el-button--primary {
  background: linear-gradient(180deg, #c4e8fc, #42b4f1, #00a6ff);
  box-shadow: 0px 1px 0px #00a6ff;
  border: none !important;
}
.el-button--primary:hover {
  opacity: .9;
}
.el-message-box__headerbtn .el-message-box__close:hover {
  color: #dcad02 !important;
}
@keyframes refresh {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(720deg);
    }
}
</style>
