// 游戏相关接口
import { Message } from 'element-ui'
import store from '../../store/index'
import i18n from '@/il8n/index'
import {
    _get,
    _post,
} from '../request'


// 根据平台和游戏获取热门游戏列表
/*
    gameKindId
    1  Electron  电子游艺
    2  Sports  体育赛事
    3  Chess  棋牌游戏
    4  Lottery  彩票游戏
    5  LiveCasino  真人视讯
    6  Esports  电子竞技
    7  Fishing  捕鱼
*/
export const _recommendGameByID = (gameKindId, ids) => _get(`/game/api/v1/game/recommendGame?gameKindId=${gameKindId}&vendorId=${ids}`, '', true)

// 首页热门游戏
export const _recommendGame = () => {
    return new Promise(resolve => {
        _get('/game/api/v1/game/hotGame', '', false).then(res => {
            if (res && res.code == 0) {
                const list = res.data.map(item => {
                    item._pictureUrl = store.getters.systemImgHost + item.pictureUrl
                    return item
                })
                store.dispatch('gameRecommenAction', list)
                resolve(res)
            } else {
                // 获取推荐游戏失败
                Message.error(res.msg || i18n.t("errorMessage.text3"))
                resolve(false)
            }
        })

    })
}

// 获取三级目录
export const _vendorGame = params => _post('/game/api/v1/manager/vendorGame', params, true)

// 通过ids获取游戏列表
export const _getGameByIds = params => _post('/game/api/v1/games/getGameByIds', params, true)

// 获取游戏菜单
export const _menuList = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/menus/list').then(async res => {
            if (!res) return

            // 拆分成各个分类的数据
            let arr_dz = [], // 电子游戏
                arr_ty = [], // 体育赛事
                arr_qp = [], // 棋牌游戏
                arr_cp = [], // 彩票游戏
                arr_zb = [], // 直播游戏
                arr_dj = [], // 电竞赛事
                arr_by = [], // 捕鱼游戏
                arr_dz_all = [] // 包含了捕鱼分类的所有电子游戏

            let fishItem = {} // 捕鱼游戏源数据
            res.data.forEach(types => {
                switch (types.nameEn) {
                    case 'CASINO':
                        arr_dz = concatImgHost(types.children)
                        arr_dz_all = arr_dz
                        arr_dz = arr_dz.filter(item => item.nameEn != 'fishing') // 过滤捕鱼游戏
                        break
                    case 'SPORTS':
                        arr_ty = concatImgHost(types.children)
                        break
                    case 'CARD GAME':
                        arr_qp = concatImgHost(types.children)
                        break
                    case 'LOTTERY':
                        arr_cp = concatImgHost(types.children)
                        break
                    case 'LIVE CASINO':
                        arr_zb = concatImgHost(types.children)
                        break
                    case 'Electronic Sports':
                        arr_dj = concatImgHost(types.children)
                        break
                }
            })

            store.dispatch('gameMenuAction', {
                    arr_dz_all,
                    arr_dz,
                    arr_ty,
                    arr_qp,
                    arr_cp,
                    arr_zb,
                    arr_dj
                })
                // 获取捕鱼数据
            let result_by = null
            switch (fishItem.type) {
                case 1: // 通过平台数据获取
                    result_by = await _vendorGame({
                        currentPage: 1,
                        pageSize: 100000,
                        gameKindId: fishItem.id,
                        vendorId: fishItem.ids
                    })
                    break
                case 3: // 通过ids查询
                    result_by = await _getGameByIds({
                        currentPage: 1,
                        pageSize: 100000,
                        gameKindId: fishItem.id,
                        ids: fishItem.ids
                    })
                    break
            }
            if (result_by) {
                arr_by = concatImgHost(result_by.data.list)
            }
            store.dispatch('gameMenuAction', {
                arr_by,
            })

            resolve(res)

            // 拼接图片host 和找到捕鱼源数据
            function concatImgHost(types) {
                const arr = types || []
                return arr.map(game => {
                    game._imgUrl = store.getters.systemImgHost + (game.imgUrl || game.pictureUrl)
                    if (game.nameEn == 'fishing') {
                        fishItem = game
                    }
                    return game
                })
            }
        })
    })
}

// 电子游戏 根据二级菜单获取三级菜单
export const _vendorGameAction = game => {
    return new Promise(resolve => {
        const datas = store.getters.gameElecChildren
        if (datas[game.id] && datas[game.id].length) {
            resolve(datas[game.id])
            return
        }
        const reqMap = {
            1: '/game/api/v1/manager/vendorGame',
            3: '/game/api/v1/games/getGameByIds'
        }
        _post(reqMap[game.type], {
            currentPage: 1,
            pageSize: 24,
            gameKindId: game.parentId,
            vendorId: game.ids,
            ids: game.ids,
        }, false).then(res => {
            if (res && res.code == 0) {
                const val = {}
                val[game.id] = res.data.list.map(item => {
                    item._pictureUrl = store.getters.systemImgHost + item.pictureUrl
                    return item
                })
                store.dispatch('gameElecChildrenAction', val)
                resolve(res.data.list)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text2"))
                resolve(false)
            }
        })
    })
}

// 进入游戏
export const _getTokenApi = params => _post('/game/api/v1/game/getToken', params, true)

// 游戏搜索
export const _searchGame = params => _post('/game/api/v1/games/searchGame', params, true)

// 超级彩金JACKPOT列表
export const _getJackpotList = () => {
    return new Promise(resolve => {
        _post('/game/api/v1/game/getJackpotList').then(res => {
            if (res && res.code == 0) {
                store.dispatch('gameJackpotListAction', res.data || [])
                resolve(res)
            } else {
                resolve(false)
                Message.error(res.msg || i18n.t("errorMessage.text20"))
            }
        })
    })
}