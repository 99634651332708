let platForms = window.projectImgUrl;
let skin = '';
if (platForms === 'amjs') {
    skin = '澳门金沙';
} else if (platForms === 'bmyl') {
    skin = '博美娱乐';
} else if (platForms === 'amxpj') {
    skin = '澳门新葡京';
} else if (platForms === 'qxyl') {
    skin = '千禧娱乐';
}

export default {
    // api提示
    errorMessage: {
        text1: "请先登录",
        text2: "获取代理规则失败",
        text3: "获取推荐游戏失败",
        text4: "领取成功",
        text5: "领取失败",
        text6: "获取规则失败",
        text7: "获取游戏平台失败",
        text8: "浏览器",
        text9: "操作失败",
        text10: "操作成功",
        text11: "获取公告失败",
        text12: "获取站内信失败",
        text13: "快捷菜单获取失败",
        text14: "获取验证码成功",
        text15: "获取验证码错误",
        text16: "注册失败",
        text17: "余额正在归集",
        text18: "快捷菜单获取失败",
        text19: "发送成功",
        text20: "获取超级彩金失败",
        text21: "获取vip等级失败",
        text22: "校验失败",
        text23: "发送失败",
        text24: "验证失败",
        text25: "账号已在其它设备登录，请重新登录",
        text26: "您的登录账户已经过期，请您重新登录",
        text27: "获取汇率失败",
        text28: "获取列表失败",
        text29: "搜索失败",
        text30: "获取热门游戏列表失败",
        text31: "请输入账号和密码",
        text32: "请先通过验证",
        text33: "请输入验证码",
        text34: "您的验证信息错误，还有",
        text35: "次验证机会，超过次数会冻结账号哦！",
        text36: "获取游戏记录失败",
        text37: "获取活动记录失败",
        text38: "QQ号码不存在",
        text39: "微信号码不存在",
        text40: "邮箱地址不存在",
        text41: "更新成功！",
        text42: "密码必须为6位数字",
        text43: "两次输入的密码不一致",
        text44: "请输入正确的旧密码",
        text45: "请填写真实姓名再添加银行卡",
        text46: "温馨提示",
        text47: "请先设置取款密码再添加银行卡",
        text48: "请绑定手机号再添加银行卡",
        text49: "请选择收款方式",
        text50: "请输入ERC20或TRC20",
        text51: "请输入支行名称",
        text52: "请输入钱包地址",
        text53: "请输入银行卡号",
        text54: "两次输入的钱包地址不一致",
        text55: "两次输入的银行卡号不一致",
        text56: "服务异常",
        text57: "添加成功",
        text58: "添加失败",
        text59: "游戏正在维护中",
        text60: "获取手续费失败",
        text61: "请选择取款方式",
        text62: "请输入交易密码",
        text63: "您的取款额度低于单次取款限额",
        text64: "您的取款额度高于单次取款限额",
        text65: "您的实际获得额度过低，无法提现",
        text66: "存款方式获取失败",
        text67: "提交成功",
        text68: "获取列表失败",
        text69: "手机格式不正确",
    },
    // 文本
    textMessage: {
        text1: "至",
        text2: "立即领取",
        text3: "已领取",
        text4: "已过期",
        text5: "捕鱼",
        text6: "进行中",
        text7: "未完成",
        text8: "等待审核",
        text9: "已完成",
        text10: "审核未通过",
        text11: "参与失败",
        text12: "参与成功",
        text13: "获取有效会员失败",
        text14: "获取返利失败",
        text15: "推广码获取失败",
        text16: "未处理",
        text17: "充值成功",
        text18: "充值失败",
        text19: "关闭",
        text20: "处理中",
        text21: "待支付",
        text22: "支付中",
        text23: "支付成功",
        text24: "支付失败",
        text25: "已关闭",
        text26: "线下银行卡支付",
        text27: "线下二维码支付",
        text28: "线上支付",
        text29: "手动上分",
        text30: "数值货币支付",
        text31: "支付宝扫码",
        text32: "微信扫码",
        text33: "微信H5",
        text34: "支付宝H5",
        text35: "待领取",
        text36: "银联扫码",
        text37: "银联转账",
        text38: "其它",
        text39: "提款成功",
        text40: "提款失败",
        text41: "待出款",
        text42: "出款中",
        text43: "出款成功",
        text44: "出款失败",
        text45: "银行卡提款",
        text46: "手动下分",
        text47: "数值货币提款申请",
        text48: "origo钱包提款",
        text49: "成功",
        text50: "失败",
        text51: "已关闭",
        text52: "设置缓存数据失败",
        text53: "当前浏览器存在安全风险, 建议更换！",
        text54: "复制成功",
        text55: "复制失败",
        text56: "请稍等",
        // 其它记录
        textMapDate: {
            6: '线下入款优惠',
            7: '线上入款优惠',
            11: '手动上分',
            12: '手动存款优惠上分',
            13: '手动返水优惠上分',
            14: '手动活动优惠上分',
            15: '自动活动优惠上分',
            20: '手动下分-存款',
            21: '手动下分-优惠活动',
            22: '手动下分-人工存款反水',
            23: '手动下分-存款优惠',
            25: '手动上分-优惠活动',
            26: '手动上分-人工存款返水',
            27: '手动上分-存款优惠',
            28: '手动上分-其他',
            31: '手动上分彩金',
            32: '活动上分彩金',
            33: '手动上分彩金-人工存款返水',
            34: '手动上分彩金-存款优惠',
            35: '手动上分彩金-优惠活动',
            36: '手动上分-其他彩金',
            42: '数值货币优惠上分',
            44: '救济金上分',
            45: '救济金彩金',
            46: '每日签到上分',
            50: '全民返利',
            51: '其他',
            52: '利息宝存入',
            53: '利息宝本金提取',
            62: '信息认证',
            63: '周勤奖励',
            64: '幸运注单',
            65: '周年礼金',
            66: '棋牌奖励',
            67: '棋牌救援',
            68: '千倍百倍',
            69: '亿元回馈',
            99: '利息宝利息提取',
        },
    },
    // 组件
    componentsViews: {
        // Electron
        text1: "电子游艺",
        // 找回密码
        text2: "找回密码",
        text3: "请输入账号",
        text4: "确认提交",
        text5: "请输入短信验证码",
        text6: "获取验证码",
        text7: "确认提交",
        text8: "请输入新密码",
        text9: "请再次输入新密码",
        text10: "确认提交",
        text11: "有问题？请联系",
        text12: "在线客服",
        text13: "第一步:验证账号信息",
        text14: "第二步:短信验证",
        text15: "第三步:修改新密码",
        text16: "两次输入的密码不一致",
        text17: "第一步:验证账号信息",
        // ip限制
        text18: "访问受限制",
        text19: "尊敬的用户，由于相关法规限制，您所在的地区无法访问，如有任何疑问，请联系客服，我们将第一时间给您回复，对您造成的不便，我们深表歉意，感谢您的理解和支持！",
        text20: "您的IP地址",
        // ip验证弹窗
        text21: "异常验证",
        text22: "系统检测到您的账户登录地区异常，请填写你的账户真实姓名进行验证！",
        text23: "请输入真实姓名",
        text24: "提交验证",
        text25: "安全验证",
        text26: "您正在一台新设备登录，为了您的账号安全，请进行安全验证",
        text27: "请输入您绑定的手机号",
        text28: "无效的手机号",
        // 系统维护
        text29: "系统维护升级",
        text30: "预期开启时间：",
        text31: "尊敬的用户：",
        text32: "为了给您带来更好的体验，",
        text33: "官方平台，正在进行服务器升级，期间体育部分的网站和产品将暂停服务，我们将尽快为您恢复，由此带来的不便敬请谅解，感谢您的理解与支持！",
        // 滚动公告
        text34: "最新公告/NEWS",
        text35: "公告通知",
        // 注册表单
        text36: "账号",
        text37: "密码",
        text38: "确认密码",
        text39: "手机号",
        text40: "短信验证码",
        text41: "获取验证码",
        text42: "邀请码",
        text43: "真实姓名",
        text44: "男",
        text45: "女",
        text46: "选择日期",
        text47: "微信",
        text48: "邮箱",
        text49: "联系地址",
        text50: "已经满18周岁，并且同意本站《用户使用协议》",
        text51: "注册",
        text52: "（选填）",
        text53: "请输入手机号码",
        text54: "验证码已发送",
        text55: "邀请码格式错误",
        text56: "请输入账号",
        text57: "请输入密码",
        text58: "两次密码不一致",
        text59: "无效的邮箱",
        text60: "无效的QQ号码",
        text61: "无效的微信",
        text62: "请输入验证码",
        text63: "请勾选用户使用协议",
        text64: "请完整填写表单",
        text65: "注册成功",
        // 专题活动详情
        text66: "限时优惠",
        text67: "永久",
        // 验证码
        text68: "验证码",
        text69: "获取验证码配置失败",
        text70: "获取验证码失败",
        text71: "获取极验失败",
        text72: "加载中",
        text73: "点击验证",
        text74: "验证成功",
        text75: "验证失败",
        // 代理申请
        text76: "代理申请",
        text77: "输入申请账号",
        text78: "输入账户密码",
        text79: "请输入姓名",
        text80: "姓名",
        text81: "请输入手机号",
        text82: "生日",
        text83: "请选择生日",
        text84: "请输入qq",
        text85: "请输入微信号",
        text86: "性别",
        text87: "邮箱",
        text88: "地址",
        text89: "请输入地址",
        text90: "提交成功，请在3日内与专员联系开通， 并提供您的代理编号和代理链接。",
        text91: "立即申请",
        text92: "知道了",
        text93: "请输入4-15位字母或数字",
        text94: "手机号不能为空",
        text95: "请输入正确的手机号",
        text96: "请输入6-16位字母或数字",
        text97: "两次密码不一致,请重新输入",
        text98: "请输入姓名",
        text99: "姓名不能超过8位",
        text100: "请选择生日",
        text101: "请输入正确的qq号",
        text102: "请输入正确的微信号",
        text103: "请输入正确的邮箱",
        text104: "申请成功",
        text105: "请输入完整信息",
        text106: "再次输入密码",
        text107: "请输入邮箱",
    },
    // 帮助中心
    'helpamjs': {
        'text0': `关于我们`,
        'text1': `${skin}注册于菲律宾马尼拉，持有菲律宾政府卡格扬经济特区 First Cagayan leisure and Resort Corporation颁发的体育博彩与线上赌场执照，接受菲律宾政府和卡格扬经济特区CEZA授权和监管，我们一切博彩营业行为皆遵从菲律宾政府博彩条约。在竞争日益激烈的网络博彩市场中，我们不断地求新求变，寻找最新的创意，秉持最好的服务。向客户提供高品质的服务、先进的产品、丰富的娱乐体验，是我们${skin}永恒的宗旨。`,
        'text2': '我们思您所想，提供高标准全方位的个人隐私保密措施以及高强度的资金安全体系。玩家网上支付和所有银行交易将由国际金融机构通过高标准的安全和机密的网络端口（SSL 128 bit encryption Standard）中进行。进入玩家帐户数据也必须有玩家唯一的登录ID和密码，确保客户的资金安全有保障。同时也采用最先进的加密措施来保证玩家的游戏安全，7×24小时的后台检测和监控，确保我们可以提供一个完全公正和安全的网络游戏空间。客户在本平台的所有活动均严格保密，我们绝不会向第三方（包括相关单位）透露客户数据。',
        'text3': '我们向您承诺：',
        'text4': '以诚信为本',
        'text5': '作为国际专业的网上博彩游戏运营商，我们承诺，为每一位客户提供最安全、最公平的博彩游戏，以及全方位的服务。',
        'text6': '提供最多样化与最真实的游戏',
        'text7': '我们的真人视讯里面的荷官都是经国际赌场专业训练的，专业、高效、严谨！每一局派牌均由荷官现场实时派发，而不是预设的电脑机率结果。通过高科技的网路直播技术，带给您身临赌场现场的刺激经验!',
        'text8': '我们的运动博彩拥有顶级的盘房操盘，投入大量的人力以及资源，以求将完整的赛事，丰富的玩法带给热爱体育的玩家。',
        'text9': '各式彩票游戏，是依官方赛果产生的游戏结果，让玩家在活泼的投注界面，享受最真实的娱乐。',
        'text10': '我们的电子游戏是与国际顶尖的电子游艺开发团队共同研发，采用最先进的动漫视觉效果，使用最公平的随机数产生机率，让您安心享受多元、刺激，观感丰富的电子游艺。',
        'text11': '本公司所有的游戏皆有共同的优点: 无须下载、介面操作简易、功能齐全、画面精致、游戏秉持公平、公正、公开!',
        'text12': '提供优质的客户服务',
        'text13': '我们有以最高标准选拔、最严格训练的客服团队，向您提供365天×24小时实时在线的客户服务。每位玩家的咨询，都将以专业、亲切的态度解答，在帮您解决问题的同时带给您宾至如归的体验!提供多渠道的与客户的互动交流，了解客户的需求，随时关注客户的意见及建议；举办更多的优惠及促销活动，给客户提供更多的回馈及惊喜。',
        'text14': '高效安全的存取款',
        'text15': '我们提供各种安全简便的存款及取款选择给我们的会员。我们一直坚持“了解我们的会员（KYC）”和 反洗钱(AML)的原则，并与第三方的财务管理当局合作以确保最大范围的遵从相关的法律法规。',
        'text16': '我们对我们所建立的公司深感自豪，并希望所有的用户能够在一个安全愉快的环境下享受我们精心设计的产品和服务并能够从中获利。 我们提供最迅捷最安全的取款方式，会员365天×24小时均可申请取款。',
        'text17': '让您成为大赢家',
        'text18': '将不间断的推出各式各样有利新老会员的优惠，天天送惊喜、周周有回馈，月月大放送，让您成为大赢家。',
        'text19': '积极参与慈善公益',
        'text20': '我们在尽心为每一位会员服务的同时，也肩负着对社会、对慈善公益的责任。取之于社会，用之于社会也是我们一直秉承的宗旨之一。我们积极参加各种捐款活动，也将永不磨灭的针对社会的需要作出更多的产生。',
        'text21': '本公司提倡有节制的赌博。我们建议阁下遵守以下一些基本规则：',
        'text22': '• 禁止未满18岁的人士参与赌博游戏。在加入游戏之前，请您确定您已年满18周岁。',
        'text23': '• 自定博彩预算，并按预算下注。',
    },
    'problem': {
        'text1': `关于${skin}`,
        'text2': `${skin}成立于2009年，持有菲律宾政府颁发的网络博彩运营执照，同时也是网络博彩委员会制定有合法博彩牌照的网络博彩网站，是正规合法对外营业的专业赌场。公司与亚游（Asia Gaming）,波音（BBIN）, PT（PlayTech），MG（MicroGaming）进行技术合作，共同打造高品质的游戏平台，具有绝对的合法性和公平性。为广大客户提供丰富多彩的博彩活动，并提供丰厚的优惠回馈。`,
        'text3': '诚信经营',
        'text4': '公司严格遵守菲律宾政府规定博彩守则，以“诚”为本。100%信誉保证游戏公平、公正。您的每笔存取款业务在经过审核后都将安全、快捷的完成',
        'text5': '公平公正',
        'text6': '真人娱乐中的荷官均接受严格的国际赌场专业训练与认证，所有赌局都依据荷官动作做出反应，而不是无趣的电脑几率预设结果。我们运用高科技的网络直播技术，带给您亲历赌场的刺激体验。电子游艺使用最公平的随机数产生机率，让您安心享受多元，炫丽的娱乐性游戏。',
        'text7': '安全与隐私',
        'text8': '为了客户的网络安全，公司成立网络安全维护中心，并获得GEOTRUST国际认证，确保网站公平公正。而在游戏投注中，我们采用128位SSL加密传输系统，MD5加密保护为游戏平台和客户信息提供进一步保护。我们承诺，将继续不遗余力地严格实行保密和隐私制度，确保个人资料的安全，并保证不将您的资料泄露给任意一方。',
        'text9': '友善和专业的客户服务团队',
        'text10': '我们亲切友善的客服专员训练有素，在帮助解决客户疑难的同时也能给与您最优越和最贴身的服务。我们也有提供线上聊天的功能，让客户们能即时联络我们。',
        'text11': '常见问题',
        'text12': `一．${skin}注册`,
        'text13': `1.如何注册${skin}真钱账户？`,
        'text14': `点击网页右上角“免费开户”按钮，根据弹出页面需求,填写真实有效的信息后，${skin}后台系统将自动生成账户。如在此过程中产生任何问题，请立即联系${skin}客服人员。`,
        'text15': `2.如何登录${skin}游戏？`,
        'text16': `您可以登录官方网址或通过客户端，登录${skin}的游戏页面。`,
        'text17': '3.有没有试玩？',
        'text18': '我们提供免费试玩账号，让您了解以及熟悉我们的游戏平台和游戏种类，如果对我们游戏平台满意的话可以存款进行真钱游戏。（备注：试玩彩金仅供试玩，不参与提款）',
        'text19': `3.为何无法正常浏览${skin}网站`,
        'text20': `${skin}网站在无法抗拒因素干扰时可能会导致您无法正常浏览网站，如遇此类情况请立即联系${skin}客服人员。`,
        'text21': `4.${skin}客户游戏年龄限制`,
        'text22': `${skin}真钱账户年龄最低限制为21岁，凡21岁以下，${skin}谢绝注册！`,
        'text23': `二．${skin}账户`,
        'text24': '1.如何更改注册信息',
        'text25': `${skin}必须使用真实姓名注册，一旦注册成功，姓名和账号无法更改；银行卡信息和密码可以在【会员中心】自行更改，如在此过程中遇到任何问题，可以随时联系${skin}客服。`,
        'text26': '2.忘记密码怎么办？',
        'text27': `${skin}共有2个密码。一个是您进入网站或者游戏的登录密码，另外一个是提款时使用的安全密码。`,
        'text28': 'A.登录密码忘记：点击网站右上角的“忘记密码”：',
        'text29': '1.通过输入安全密码来重置登录密码',
        'text30': '2.通过您注册资料绑定的邮箱重置登录密码；',
        'text31': '3.通过您游戏账号绑定的手机来找回登录密码；',
        'text32': 'B.安全密码忘记：',
        'text33': '1.请先登录您的账号，进入【会员中心】>【安全管理】>【安全密码】，通过设置安全问题重置安全密码；',
        'text34': '2.通过游戏账号绑定的手机重置安全问题，重设安全密码。',
        'text35': '3.如何完成存款',
        'text36': 'A．存款',
        'text37': `自您成功注册账号之后，您就可以开始存款业务。目前为止${skin}接受：“扫码支付” “在线支付”，“微信支付”，“支付宝转账”，及“网银转账/ATM及柜台”等存款方式。请您根据实际情况，选择最合适的存款方式。`,
        'text38': 'B．完成存款之后多久可以添加到账户中？',
        'text39': `根据您选择的存款方式的不同，金额到帐时间也会有所不同，一般都是即时到帐。如遇问题请联系${skin}客服人员。`,
        'text40': 'C．存款之后是否有投注要求？',
        'text41': '在您完成存款之后，在您没有领取任何奖金的情况下，您只需要完成存款额一倍的投注额，即可申请提款。',
        'text42': '4.如何完成提款',
        'text43': 'A．提款',
        'text44': '在您的账号完成所有投注要求之后，您可以在【财务中心】的【会员提款】填写资料后提交申请，相关部门会立刻为您处理；申请提款时，请务必填写真实银行信息。提款一般5分钟内可以到帐。',
        'text45': 'B．提款为什么被拒绝？',
        'text46': `提款业务被${skin}相关部门退回之后，请您立即联系${skin}客服咨询。`,
        'text47': '5.如何户内转账？',
        'text48': '登录账号以后，电脑网页版点击右上角“资金-户内转账”，手机网页版点击首页下方“转账”，选择相应的游戏厅，游戏时选择“转入游戏平台”，提款时选择“转入主账户”。',
        'text49': `三．${skin}游戏`,
        'text50': `1.${skin}提供多少款游戏？`,
        'text51': `${skin}目前提供多平台高支付游戏，包含：`,
        'text52': '2.进入游戏很慢怎么办？',
        'text53': '如果您进入我们的网站或游戏过于缓慢，建议您清除下缓存！ IE和360浏览器的步骤为：',
        'text54': '1.请您先退出我们的网站。',
        'text55': '2.点击浏览器右上角“工具”选择“Internet选项”，再点击“删除”，勾选“Cookie和网站数据”并点击删除，等待删除完成后请再次尝试进入游戏！',
        'text56': `关于3.游戏中视频无法显示怎么办？${skin}`,
        'text57': '建议您可以清除您的FLASH 缓存，请您点击这个链接网址：',
        'text58': '然后点击“删除所有WEB站点”。在弹窗中点击“确认”即可完成操作！',
        'text59': '4.手机可以玩游戏吗？',
        'text60': '目前网站支持安卓和苹果手机，您可以使用网页游戏或者下载客户端游戏。但是就算是在WIFI的环境下，目前手机处理器的运行能力还是有限，有时会出现画面卡顿的情况。所以建议还是用电脑运行游戏！',
        'text61': '5.如何产生游戏结果并保证游戏的公平性？',
        'text62': `${skin}所有游戏结果均由游戏供应商所产生，游戏结果真实有效，${skin}会对所有的游戏结果负责。真人娱乐中的荷官均接受严格的国际赌场专业训练与认证，所有赌局都依据荷官动作做出反应，而不是无趣的电脑几率预设结果。电子游艺使用最公平的随机数产生机率，让您安心享受多元，炫丽的娱乐性游戏。`,
        'text63': '6.如何下注，撤销投注，查询游戏记录？',
        'text64': `${skin}所有游戏下注均在游戏页面拥有醒目下注按钮，根据您的投注习惯下注即可。老虎机类游戏无法撤销投注，一经点击游戏自动开始，请妥善点击。真人现场类或体育竞技类游戏在投注未被接受之前，可以选择取消投注。所有游戏记录均可在游戏页面菜单中查询。如遇任何问题，请随时联系${skin}客服人员。`,
        'text65': '7.为什么投注记录中的有效投注额和你们统计的不一致呢？',
        'text66': 'AG平台和波音平台都是采用的美东时间（美国东部时间如：纽约、华盛顿时间）所以投注记录中的时间会和实际投注时间有差别。例如实际投注时间为当天晚上20:00则记录上显示的时间为当天早上8:00，有12个小时的时差。MG采用的格林威治时间，和北京时间有8个小时时差。',
        'text67': '8.真人现场游戏是实时游戏吗？游戏页面是否作假？',
        'text68': `${skin}真人现场游戏提供商为Playtech、AG、Microgaming、BBIN，其旗下所有真人现场游戏均为实时游戏。PT牌桌旁边电视中播放美国CNN电视台或者其他著名电视台全球同步的节目，此外您还可以随时与真人现场荷官聊天；AG您可以拨打桌面的菲律宾电话进行实时通话。`,
        'text69': '9.如果游戏突然中断遇突发状况我该怎么办？',
        'text70': `${skin}游戏经过不断完善已经在同行业中属于极为稳定的游戏环境。但是由于国际网络，客户所在国家地区等诸多不可控制因素干扰，游戏有可能会突然中断。如遇到此类问题，请勿惊慌焦躁，立即联系${skin}客服人员协助处理。在此${skin}郑重承诺，凡是由于${skin}方面造成的游戏损失，${skin}一定会为此负责及赔偿！。`,
        'text71': `四．${skin}投注及奖金要求`,
        'text72': `1.${skin}如何获得奖金`,
        'text73': `${skin}提供了许多优惠奖金获取机会，部分奖金是您无需申请，自动添加至您的账户中，另外一些奖金则需要您申请，通过之后自动为您添加。`,
        'text74': '2.可以取消奖金吗？',
        'text75': '在您账户获得奖金之后，是无法取消的。',
        'text76': '3.没有完成投注要求是否可以提款？',
        'text77': `${skin}所有真钱账户在没有完成相应奖金投注要求之前，谢绝提款业务。如果您的账户余额低于5元人民币时，之前的所有投注要求将会自动清零。`,
    },
    'declarations': {
        'text88': '规则与声明',
        'text89': '本公司是合法互联网交易机构，现提醒有意参与本公司所有游戏之客户，应注意其国家或居住地区的相关法律规定，如有疑问，应就相关问题寻求当地法律意见。 本条款涵盖您与本公司，在本网站进行博彩行为时的所有协议。',
        'text90': '1、法定要求',
        'text91': '根据您所居住国的法律，您必需达到法定年龄，您才可使用本网站。',
        'text92': '如果您尚未年满21岁，在任何情况下您都不可使用本网站。',
        'text93': '部份地区或国家法律尚未明确在线博彩的合法性问题，甚至某些地区或国家已明确规范在线博彩为非法行为。我们无意邀请任何人在这些地区或国家非法使用本网站。本网站的存在并不表示我们推广在线博彩于任何赌博非法的地区或国家。您必需确定在您所居住的地区或国家使用在线博彩是否合法，并负完全的责任。',
        'text94': '2、帐户规定',
        'text95': '客户只能拥有一个账号，如发现客户拥有多个账号，本公司有权冻结客户的账号以及取消该账号内所有胜出的投注。本公司有权保留取消任何因素导致账号结余不正确的金额。此外如发现账号持有人与亲属共同使用多个不同账号，本公司有权限制客户每人各保留最多一个账号。若上述情形已构成欺诈、危害或侵犯本公司及其它用户权利或利益时，本公司有权要求该用户赔偿并没收用户帐户中所存之余额，终止或注销用户继续使用本系统的权利',
        'text96': '3、交易结算',
        'text97': '用户有责任及义务在每次游戏交易之前或之后及离开游戏前，检查自己的账户余额。当用户怀疑余额有错误时，必需在进行下一回合的游戏（游戏开始到结账画面出现视为一个回合）前，向本网站在线客服人员或本公司客服部人员提出申诉。如果您不这么做，本公司将视为您放弃提出申诉的权利，并接受目前的余额数据为正确。',
        'text98': '直接与本公司交易结算的用户，银行帐户名称须与会员登记和申请过程中所使用的姓名一致。若用户使用信用卡或借记卡时，持卡人姓名亦须与会员登记和申请过程中所使用的姓名一致。若发生银行账户名称或持卡人姓名和账户登记(会员注册)时所使用的姓名不一致时，本公司有权拒绝相关交易的结算',
        'text99': '用直接与本公司交易结算的用户，本公司会依据用户所提供的交易方式信息，将博彩所得存入您的用户帐户中。任何款项或所得在存入账户的过程中，若因为用户所提供的信息不足或信息错误而发生交易错误的情形时，本公司不负任何形式的责任。用户一旦发现帐户内款项有误时，有责任立即通知我们。',
        'text100': '本公司因用户的结算交易而蒙受的银行费用，包括手续费、电邮费等，本公司有权从用户的帐户余额中扣除和抵扣上述费用。',
        'name1': 'JACKPOT 中奖后将根据具体中奖金额扣取一定的奖池管理费，具体以游戏平台公司的收取金额作为依据。',
        'name2': '4、本公司之权利与义务',
        'name3': '本公司会采取各种措施保护玩家的账户安全。',
        'name4': '本公司绝不会以任何人为的手段，影响游戏的公正性。',
        'name5': '本公司对非法使用玩家账户的行为不负任何责任。',
        'name6': '本公司保留修改所有制度及规则条款的权利。',
        'name7': `本公司若发现玩家在游戏中，有作弊、攻击或破坏本系统正常操作以及使用明确表示或暗示的方式以与事实不符、莫须有之说针对「${skin}娱乐城」诋毁、漫骂、恐吓、毁谤的行为，经公司劝阻不止，本公司保留拒绝/禁止和吊销玩家继续使用本系统的所有权利 ，"并将依菲律宾共和国法律进行追诉"。`,
        'name8': '本公司对于您个人的数据及在系统中进行的记录，皆有「备份数据」。但若不幸发生不可抗拒之因素（如天灾、恐怖活动、战争、暴动…）导致上述数据遗失时，本公司会尽全力恢复所有的历史数据，所恢复的数据也将视为公正。',
        'name9': '5、促销和优惠',
        'name10': '所有促销、优惠和特别活动均须遵守本条款及本公司针对具体优惠活动所不时制定的规则与条款。本公司保留在任何时候中止、取消或修改该等促销、奖励和特别活动之相关规定及条款的权利。',
        'name11': '若本公司认定有人滥用或企图滥用某项促销或奖励活动，或可能因该等滥用行为而获利时，本公司有权自主决定以其认为合适的方式阻止、拒绝、中止或撤销任何用户参加有关的促销、奖励和特别活动。',
        'name12': '6、条款修改政策',
        'name13': '本公司保留不时修订、更新和修改本条款和游戏规则的绝对权力。任何修订、更新和修改将在官网上公布。经修订、更新或修改的本条款和游戏规则，在公布时即生效。用户在上述修订、更新和修改公布后，继续使用本网站或游戏时，则视为用户同意并接受本公司的修订、更新和修改。',
        'name14': '我们建议您定期阅读本网站之规则与条款及游戏规则。',
        'name15': '上述修订、更新和修改，本公司可自行决定是否通知用户。本公司并无任何义务向用户通知上述之修订、更新和修改。',
    },
    // 会员存款
    depositDemo: {
        text1: "会员存款",
        text2: "一：点击【资金】-【会员存款】",
        text3: "1：公司入款",
        text4: "选择银行：选择我司收款银行",
        text5: "存款姓名：填入转账方姓名：张三",
        text6: "充值金额：填入要充值的金额如：501.2（建议尾数不为0或带小数）",
        text7: "点击【下一步】",
        text8: "复制所需的收款信息进行转账，点击文本图标可进行复制，转账成功到账后，等待财务为您添加处理。",
        text9: "可使用以下转账方式：网银转账、银联转账、支付宝转卡",
        text10: "1：线上入款",
        text11: "充值通道-【手机银行网银转账卡】或【网银100-10000】",
        text12: "充值金额：填入要充值的金额如：1234.5（建议尾数不为0或带小数）",
        text13: "点击【下一步】",
        text14: "复制所需的收款信息进行转账，转账成功到账后，自动添加彩金。",
    },
    // 会员取款
    withdrawDemo: {
        text1: "会员取款",
        text2: "1：绑定收款账户",
        text3: "点击【个人资料】-【收款方式管理】",
        text4: "点击【添加银行卡】或【添加origo钱包】",
        text5: "银行名称：选择开户行",
        text6: "支行名称：填写支行名称",
        text7: "银行卡号：填写收款账号",
        text8: "确认卡号：再次填写收款账号",
        text9: "验证码（非必填）：点击【发送验证码】收到我司验证码填入即可",
        text10: "点击【确定】：绑卡成功",
        text11: "2：点击【资金】-【会员提款】",
        text12: "一键归集：点击，可将所有平台的余额归集到账户余额上。",
        text13: "收款方式：选择要用的银行卡收款",
        text14: "提款金额：输入要提现的额度",
        text15: "实际到账金额：与提款相同，如不相同则不要提交也无法出款",
        text16: "提现手续费：出现扣除费用则不要提交，也无法出款",
        text17: "行政费用：出现扣除费用则不要提交，也无法出款",
        text18: "优惠扣除：出现扣除费用则不要提交，也无法出款",
        text19: "交易密码：输入交易密码，点击确定即可。",

    },
};