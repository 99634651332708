// 用户相关接口
import { _get, _post, _put } from '../request'
import store from '../../store/index'
import { Message } from 'element-ui'
import config from '../../common/config'
import { _hideMiddle } from '../../utils/utils'
import { _getDeviceInfo, _getDeviceId } from '../../utils/finger'
import i18n from '@/il8n/index'

// 获取用户基本信息
export const _getUserInfo = () => _get('/member/api/v1/members/' + store.getters.userData.user_id)

// 获取用户基本信息
export const _getvipdata = (leveId) => _get('/member/api/v1/memberLevels/' + leveId)
    // 登录请求
export const _login = params => _post('/member/api/v1/members/login', params, true)

// 验证账号
export const _validateLogin = params => _post('/member/api/v1/memberValidate/validateLogin', params, true)


// 获取验证码类型
export const _getDefaultStatus = () => {
    return new Promise(resolve => {
        _get('/member/api/v1/validateCode/getDefaultStatus').then(res => {
            if (res && res.code == 0) {
                store.dispatch('fingerprintIdAction', res.data.fingerprintId)
            }
            resolve(res)
        })
    })
}

// 获取普通类型验证码
export const _captcha = () => _get('/member/api/v1/members/captcha')

// 获取极验验证码信息
export const _gt = () => _get('/member/api/v1/geeTest/firstRegister?t=' + window.childCode)


/**
 * 获取手机验证码
 * @param {String} mobile 手机号
 * @param {number} num  功能编号：1其他功能默认配置,2找回密码,3提款短信验证,4平台单发短信发送,5平台短信批量发送
 */
export const _sendSmsCode = (mobile, num) => {
    return _get('/member/api/v1/members/mobile/' + mobile + '?functionId=' + num, '', true)
}

// 发送验证码
export const _sendValidateSmsCode = ({ mobile, name }) => {
    return new Promise(resolve => {
        _post('/member/api/v1/memberValidate/sendValidateSmsCode', { mobile, name }, true).then(res => {
            if (res && res.code == 0) {
                Message.success(i18n.t("errorMessage.text14"))
                resolve(true)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text15"))
                resolve(false)
            }
        })
    })
}

// 获取注册模块配置
export const _registerFormitem = () => _get('/longm/api/v1/settings/get/member_register')

// 注册
export const _register = params => {
    return new Promise(resolve => {
        _post('/member/api/v1/members/register', params, true).then(res => {
            if (!res || res.code) {
                Message.error(res.msg || i18n.t("errorMessage.text16"))
                resolve(false)
            }
            store.dispatch('userDataAction', res.data)
            store.dispatch('userAccessTokenAction', res.data.token_type + ' ' + res.data.access_token)
            resolve(res)
        })
    })
}


// 获取各平台余额
export const _getPlatformBalance = () => {
    return new Promise(resolve => {
        const user = store.getters.userData
        _post('/game/api/v1/game/getAllBalances', {
            clientId: user.tenant_id,
            clientIp: config.clientIp,
            memberId: user.user_id,
            username: user.username,
        }, false).then(res => {
            if (res.data && res.code == 0) {
                store.dispatch('userDataAction', {
                    totalBalance: res.data.totalBalance ? res.data.totalBalance.toFixed(2) : "0.00"
                })
                store.dispatch('userPlatformBalanceAction', res.data.totalBalanceDetail)
                resolve(res.data.totalBalanceDetail)
            } else {
                resolve(false)
            }
        })
    })
}

// 一键归集
let oneKeyLoading = false
export const _oneKeyBalance = () => {
    return new Promise(resolve => {
        if (oneKeyLoading) {
            resolve(false)
            return Message.warning(i18n.t("errorMessage.text17"))
        }
        oneKeyLoading = true
        setTimeout(() => {
            oneKeyLoading = false
        }, 10000)
        const user = store.getters.userData
        _post('/game/api/v1/game/oneKeyBalanceCollection', {
            clientId: user.tenant_id,
            clientIp: config.clientIp,
            memberId: user.user_id,
            username: user.username,
        }, true).then(res => {
            if (res.code == 0) resolve(res)
            else resolve(false)
        })
    })
}

// 获取返水信息
export const _getReback = () => _get('/wallet/api/v1/rebates/getRebateAmount?memberId=' + store.getters.userData.user_id)

// 领取返水
export const _receiveReback = () => {
    return new Promise(resolve => {
        _get('/wallet/api/v1/rebates/receiveRebate?memberId=' + store.getters.userData.user_id).then(res => {
            if (res && res.code == 0) {
                Message.success(i18n.t("textMessage.text3"))
                resolve(res)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text5"))
                resolve(false)
            }
        })
    })
}


// 获取银行卡列表
export const _getBankList = () => {
    return new Promise(resolve => {
        _get('/pay/api/v1/memberBanks/list').then(res => {
            if (res && res.code == 0) {
                let datas = res.data || []
                    // 数据处理
                datas = datas.map(item => {
                    item._hideMiddleNumber = _hideMiddle(item.number)
                    return item
                })
                store.dispatch('userBankListAction', datas)
                resolve(res.data || [])
            } else {
                resolve(false)
            }
        })
    })
}

// 编辑个人资料
export const _updateInfo = data => {
    return new Promise(resolve => {
        _put('/member/api/v1/members/', data, true).then(res => {
            if (res && res.code == 0) {
                store.dispatch('userDataAction', res.data)
                resolve(res)
            } else {
                resolve(false)
            }
        })
    })
}

// 设置资金密码
export const _withdrawPass = data => _post('/member/api/v1/members/withdrawPass', data, true)

// 修改登录密码
export const _updatePassword = data => _post('/member/api/v1/members/updatePassword', data, true)

// 获取手机短信验证码
export const _smsCode = (phone, type) => {
    return new Promise(resolve => {
        _get('/member/api/v1/members/mobile/' + phone + '?functionId=' + type, '', true).then(res => {
            if (res && res.code == 0) {
                Message.success(i18n.t("errorMessage.text19"))
                resolve(res)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text23"))
                resolve(false)
            }
        })
    })
}

// 添加银行卡
export const _addBankCard = params => _post('/pay/api/v1/memberBanks', params, true)

// 获取会员等级列表
export const _getVipList = () => {
    return new Promise(resolve => {
        _post('/member/api/v1/memberLevels/app/list').then(res => {
            if (res && res.code == 0) {
                store.dispatch('vipLevelListAction', res.data || [])
                resolve(res.data)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text21"))
                resolve(false)
            }
        })
    })
}

// 真实姓名校验
export const _validateRealName = params => _post('/member/api/v1/memberValidate/validateRealName', params, true)

// 设备验证
export const _checkValidateSmsCode = async({ mobile, name, smsCode }) => {
    const device = await _getDeviceInfo()
    
    
    let params = {
        mobile,
        name,
        smsCode,
    }
    if(!['a025','a026'].includes(config.theme)){
        let fingerprint  = await _getDeviceId()
        params.fingerprint = fingerprint
        params.phoneModel = device.browserType
    }
    return new Promise(resolve => {
        _post('/member/api/v1/memberValidate/checkValidateSmsCode', {
            ...params
        }, true).then(res => {
            if (res && res.code == 0) {

                resolve(true)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text22"))
                resolve(false)
            }
        })
    })
}

// 找回密码-第一步-验证账号
export const _getMemberByName = params => _post('/member/api/v1/members/getMemberByName', params, true)

// 找回密码-第二步-通过账号发送验证码
export const _mobilebyacc = (account, type) => {
    return new Promise(resolve => {
        _get(`/member/api/v1/members/mobilebyacc/${account}?functionId=${type}`, '', true).then(res => {
            if (res && res.code == 0) {
                Message.success(i18n.t("errorMessage.text19"))
                resolve(true)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text23"))
                resolve(false)
            }
        })
    })
}

// 找回密码-第二步-验证短信验证码
export const _checkSmsCode = ({ name, smsCode }) => {
    return new Promise(resolve => {
        _post('/member/api/v1/members/checkSmsCode', {
            name,
            smsCode
        }, true).then(res => {
            if (res && res.code == 0) {
                resolve(true)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text22"))
                resolve(false)
            }
        })
    })
}

// 找回密码-第三步-提交新密码
export const _resetMemberPwdByClient = params => _post('/member/api/v1/members/resetMemberPwdByClient', params, true)