import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './core/store'
import i18n from '@/il8n/index'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'; //导入swiper
import 'swiper/css/swiper.min.css'
import {
    MessageBox,
    Button,
    Message,
    Dialog,
    Input,
    Option,
    Select,
    Image,
    Form,
    FormItem,
    Carousel,
    CarouselItem,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Badge

} from 'element-ui';
import VueLazyload from 'vue-lazyload'
import '@/assets/css/index.scss'
Vue.use(VueLazyload, {
    loading: require('./assets/images/loading.gif'),
    error: require('./assets/images/loading_error.svg'),
    attempt: 1
})
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$cache = require('./core/utils/cache.js');
// 取出缓存中的数据放入vuex
store.dispatch('take')
import 'animate.css';
Vue.config.productionTip = false
Vue.prototype.$config = require('./core/common/config.js').default;
Vue.use(VueAwesomeSwiper)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Option)
Vue.use(Select)
Vue.use(Image)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Badge)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')