import comp_en from "../../core/il8n/lang/en";
const en = {
  name: "data",
  errorCode: {
    loading: "Loading...",
    webCode503: "Maintenance",
    webDefault:
      "The network response is abnormal, please try again later, or contact customer service for consultation",
    "4001": "Call remote interface exception",
    "401": "Your login account has expired, please log in again",
    "503": "System maintenance",
    "0": "Success! ",
    "1": "Failure! ",
    "500": "Exception!",
    "1001": "Login failed, failed to get token",
    "1012": "Network error, please try again later! ",
    "1101": "User is not authorized to log in",
    "1102": "User authorization does not match",
    "4011": "redis link exception",
    "4021": "SMS failed to send",
    "7001": "Parameter exception",
    "7002": "Data parsing exception",
    "7011": "Insert parameter error",
    "7101": "The data to be re-aggregated is empty",
    "7102": "Id cannot be empty",
    "7103": "The current ip is in non-CIDR format",
    "7201": "Failed to initialize verification code",
    "7202": "Verification code verification failed, please try again",
    "7203": "Verification code cannot be empty",
    "7204": "The verification code is sent too frequently",
    "7301": "The account does not exist or has been activated, please re-enter",
    "7302": "The account has been activated, please do not activate it again",
    "7303": "The account is being activated, please do not activate it again",
    "7304": "The account does not exist",
    "7305": "The account password is incorrect, please re-enter! 3",
    "7306":
      "The number of times your password has been changed has reached the upper limit, please contact customer service! ",
    "8000": "Cannot find relevant data",
    "9980": "The event has not started",
    "9981": "User level mismatch",
    "9983": "Name cannot be empty! ",
    "9985": " The level information is abnormal! ",
    "9987": " Save exception! ",
    "9990":
      " The automatic audit condition setting is abnormal, please confirm and try again! ",
    "9992":
      "The operation has taken effect, please do not repeat the operation",
    "9993":
      "The level setting data is abnormal, please check the level setting and try again! ",
    "9994": "No user information was found",
    "9996": "User does not meet activity requirements",
    "9997": "The event has expired",
    "10001": "The event has not started or has ended",
    "10002": "Timed activity exception",
    "10003":
      "Only new registered players are allowed to participate in this event",
    "10004": "Your membership level is not allowed to participate in the event",
    "10031": "The record type cannot be modified",
    "10032": "The jump type of this record cannot be modified",
    "10033": "The jump content of this record cannot be modified",
    "10034": "The fixed code of this record cannot be modified",
    "10035": "The record cannot be deleted",
    "10062": "Sign-in abnormal user does not meet the activity requirements",
    "10091":
      "Not participating in the manufacturer's data format is abnormal, the data format is key-value format",
    "10501": "IP has been added, no need to add again",
    "10502":
      "When the jump type is external link, the jump content must start with http:// or https://! ",
    "11001":
      "Your application has been submitted, please wait for customer service to contact",
    "11031": "Add at least one level",
    "11061": "The program has been used and cannot be closed",
    "11091":
      "The proxy account may have been disabled, or deleted, or the proxy level is not a proxy",
    "11501": "The proxy account name already exists, please fill in again",
    "11531": "Commission configuration details are empty",
    "11801": "Phone number encryption failed, please try again later",
    "12001": "Failed to get game link",
    "12002": "Failed to get game link",
    "12003": "Deletion failed, there are still games under the label",
    "12104": "The game or the manufacturer has been deleted",
    "12105":
      "The game manufacturer has not yet supported transfer confirmation",
    "12208": "No prize pool data",
    "12501": "queryType must be one of [vendor or tags or reportFee]",
    "12532": "Start time is required",
    "12533": "Start time is greater than end time",
    "12534": "Start time is greater than 60 days",
    "12565": "gameKindCode cannot be empty",
    "12566": "The user's id does not match the member's id",
    "12592": "The vendorCode does not exist in the game vendor",
    "12597": "The manufacturer information cannot be empty:",
    "13001": "User does not exist",
    "13002": "Failed to save database during user registration",
    "13003": "User check failed",
    "13004": "User status is abnormal",
    "13006": "member does not exist",
    "13007":
      "Your account is not bound to a mobile phone number, please contact customer service for processing",
    "13008": "The account has been frozen, please contact customer service",
    "13009": "The account has been disabled, please contact customer service",
    "13010":
      "Due to the version update, your account exceeds the character limit, please contact the online customer service for processing",
    "130011":
      "The username or password is incorrect, please re-enter, or contact customer service for processing",
    "130012": "Username already exists",
    "13501": "The phone number already exists",
    "13502": "Username id cannot be empty",
    "13503": "The username of the mobile phone number was not found",
    "13801": "User registration failed",
    "13802": "User update failed",
    "13803": "Remote call user update failed",
    "13901":
      "The member registration setting is abnormal, please contact customer service! ",
    "13902":
      "The number of times the password is retrieved in the background is incorrectly configured",
    "14001": "User transfer failed",
    "14002": "Auto transfer failed",
    "14003": "The value of the two audits has changed",
    "14031": "The order is not locked yet, please lock it before operating",
    "14032": "The order has been locked by others and you cannot operate it! ",
    "14033": "The status of the order does not meet the requirements! ",
    "14034":
      "Submission failed, exchange rate has changed, please check and confirm the amount and submit again! ",
    "14061":
      "The withdrawal request has been submitted, please do not submit it again",
    "14062": "Withdrawal exception",
    "14091": "You have already bound this bank card",
    "14092": "Bank card account name cannot be empty",
    "14093": "The bound bank card has reached the upper limit",
    "14094":
      "The name of the bank card is inconsistent with the real name of your account",
    "14095": "The number of wallet types bound has reached the upper limit",
    "14096":
      "The bank card cannot be deleted temporarily, please contact customer service for processing, thank you",
    "14097": "The bank card or digital wallet has been bound, please re-enter",
    "14098": "Your account is disabled, please contact customer service! ",
    "14099":
      "Your wallet account is disabled, please contact customer service! ",
    "14100":
      "The account number you entered is incorrect, please contact customer service! ",
    "14101": "Please enter a 6-digit wallet ID number, thank you",
    "14501": "Depositor cannot be empty",
    "14502": "Payment platform code and name are required",
    "14503": "Wallet user not found",
    "14504": "Your single withdrawal amount is lower than the lower limit:",
    "14505": "Your single withdrawal amount is higher than the upper limit:",
    "14506": "The deposit amount must be greater than zero",
    "14801": "Update failed",
    "14901": "The payment key is not set",
    "14902": "The URL to get the payment list is not set",
    "14903": "The URL to get the synchronization order is not set",
    "14904": "The URL of the order callback is not set",
    "14905": "The recharge callback CALLBACK of digital currency is not set",
    "14906": "The payment key of this merchant is not set",
    "14907": "The payment related dictionary settings are wrong",
    "15001": "Collection failed",
    "15002": "Receive exception",
    "15034": "Getting tenant information is abnormal",
    "15035": "Data has been changed, roll back the transaction manually",
    "15036": "The maximum number of participations has been reached",
    "15037": "The total amount of participation has reached the upper limit",
    "15038": "Single amount has exceeded the upper limit",
    "15061": "The national rebate function has not been activated",
    "15062":
      "The unclaimed rebate amount is less than the minimum claim amount",
    "15063": "Do not resubmit",
    "15064": "Receiving rebates",
    "15091": "The balance is being collected, no query! ! ! ",
    "15121": "Wallet service abnormal",
    "15151": "The order has been processed",
    "15181": "Failed to clean up freeze",
    "15501": "The transfer amount must be greater than 1",
    "15502": "The transfer amount must be greater than 0",
    "15503": "The transfer mode is not a legal value",
    "15504": "Insufficient frozen balance",
    "15801": "Failed to transfer the central wallet to the game dealer! ",
    "15802": "The game provider failed to transfer to the central wallet! ",
    "15901": "The rebate is not configured",
    "16001": "Platform maintenance",
    "16002": "Platform does not exist",
    "16003":
      "The account has been logged in on another device, please log in again",
    "17007": "Bank name or currency cannot be empty",
    "17008": "The card number or wallet address cannot be empty",
    "17009": "Bank name or currency cannot be empty",
    "17010": "Branch or link cannot be empty",
    "17011": "Member id cannot be empty",
    "100661":
      "Your sign-in request has been sent, please do not submit it again",
    "110004": "Token is invalid! ",
    "110007": "User is not authorized to log in! ",
    "110009": "Current ip is restricted",
    "110010": "The request is too frequent, please try again later",
    "120001": "The merchant does not exist",
    "120004": "There is a null in the request parameter",
    "120014": "The user's central wallet does not exist",
    "120017": "Membership level does not exist",
    "120021": "Order number already exists:",
    "120024": "Order information does not match",
    "120025": "Payment pattern mismatch",
    "120026": "The order status has been finalized, you cannot operate it! ! ",
    "120027":
      "Your last withdrawal has not been processed, please be patient! ",
    "120029":
      "The order needs to be approved by risk control before you can operate it! ",
    "120031": "Your daily withdrawals have been used up:",
    "120032": "Wallet has been disabled, please contact customer service! ",
    "120050": "Critical warning: The password for this user does not match",
    "120051": "Your balance is insufficient and cannot be withdrawn! ",
    "120055": "An error occurred while generating the secret value",
    "120056": "Your withdrawal password is wrong! ",
    "120057": "Update wallet data failed version mismatch",
    "120070": "The user's balance is insufficient",
    "120093": "Fetching frequently, please try again later! ! ! ",
    "120561":
      "There is a problem with the invitation code you filled in (the proxy user does not exist, or has not been enabled), please verify and resubmit the application",
    "130000":
      "The exchange rate of this currency on the link is not found, please change the currency",
    "999999": "Payment exception",
    "1000":
      "Unknown error, if there is an abnormal situation, please contact customer service for consultation",
    "130013": "The original password is wrong, the modification failed",
  },
  // 提示
  tipsMessage: {
    text1: "Please login first",
  },
  // 提款
  accountPage: {
    headerTitle: "Withdrawls",
    balance: "Account balance",
    element: "INR",
    collection: "Transfer wallet",
    getMode: "Select withdrawal method",
    tipsnull: "I don't have a bank card",
    addMode: "Add withdrawal method",
    addbank: "Add Bank Card",
    addMoney: "Add digital currency wallet",
    cancel: "Cancel",
    more: "More",
    down: "Pull down to refresh",
    away: "Put away",
    warning: "10 secs refresh time. Please wait!",
    Refresh: "Refresh balance successfully",
    hand: "Centralize",
    handRes: "Successful",
    handErr: "Collection failed, please try again!",
    setpassword:
      "You haven't set a transaction password yet, do you want to go to the setting?",
    forward: "Go now",
    tipsbank:
      "You can only tie up to 3 bank cards! If you need to adjust, please contact customer service!",
    tipsM:
      "You can only bind up to 3 digital currencies! If you need to adjust, please contact customer service!",
    tipsW:
      "You can only tie up to 3 origo wallets! If you need to adjust, please contact customer service!",
    addWallet: "Add origo wallet",
    wallet: "Origo wallet",
    next: "Next",
    phoneget: "Please verify your mobile phone number",
    phone: "phone number",
    inputPhone: "Please enter phone number",
    inputcode: "Please enter verification code",
    code: "Verification code",
    determine: "Confirm",
    getcode: "Get verification code",
    getmoneyMode: "Receiving method",
    phoneErr: "Malformed phone number",
    getcodeSuee: "Get the verification code successfully",
    mobile: "Bind mobile phone number successfully",
    selectWithdrawlMethod: "Please select the withdrawal method",
    cwallet: "Center wallet",
  },
  // 充值
  rechargePage: {
    headerTitle: "Deposit",
    null: "The deposit method is temporarily unavailable",
    getMode: "Select deposit method: ",
    passageway: "Deposit channel",
    seleBank: "Select Bank",
    name: "Deposit name",
    tipsName:
      "In order to receive the account in time, please be sure to enter the correct deposit name",
    inputName: "Please enter deposit name",
    setmoney: "Deposit amount",
    inputMoney: "Please enter the deposit amount",
    minMoney: "Amount (Min.):",
    element: "Rupees",
    maxMoney: "Amount (Max.):",
    tipstitle: "Importants",
    tips1:
      "1. Please take note on minimum transfer amount showed on page. Deposit smaller than the minimum deposit amount will not be credited to your account and cannot be returned.",
    tips2:
      "2. After you deposit to the above address, you need to confirm the entire network node. Please wait patiently, we will immediately credit the corresponding amount to your account after receiving the digital currency. If you have not received it for a long time, you may contact customer service.",
    tips3:
      "3. Please be sure to check the currency and chain name when transferring. If the wrong currency is submitted, it cannot be retrieved.",
    tips4:
      "4. The exchange rate displayed on the page is for reference only. Due to exchange rate fluctuations, the calculation will be based on the latest exchange rate ratio when posting.",
    confirm: "Confirm deposit",
    next: "Next",
    setparagraph: "Transfer",
    online: "Online",
    currency: "DIGICCY",
    vip: "VIP",
    tipsnull:
      "There is currently no recharge information, please contact customer service!",
    gotIt: "Got it",
    determine: "Confirm",
    range: "Amount input is out of range",
    retry: "The channel is unavailable, please try again",
    error: "error code",
    bankSelect: "Please select a bank card",
    errortext: "Failed to load",
    withdrawlNote:
      "Note: All the withdrawal requests will be processed within 10:00AM-11:59PM daily.",
    noRechargeRecordAlertText:
      "Dear UWIN players, for the safety of your funds, please contact the online customer service for phone verification before withdrawing.",
    firstWithdrawlAlertText:
      "Dear UWIN players, for the safety of your funds, please contact the online customer service for phone verification before withdrawing.",
    notRechargedFirstWithdrawal:
      "You need to recharge any amount for first withdrawal.",
  },
  // 首页index
  homePageIndex: {
    text1: "Beijing time",
    text2: "Mobile betting",
    text3: "Easy betting anytime, anywhere",
    text4: "MOBILE BET",
    text5:
      "Supports all mobile devices of IOS & Android. For mobile devices, please visit:",
    text6:
      "Our mobile betting platform is for players all over the network, providing nearly 100 slot machine baccarat and lottery game betting, online deposits and online withdrawals, one-click operation, to achieve the concept of uninterrupted entertainment anytime, anywhere.",
    text7: "Video Games",
    text8: "Millions of prize pools are waiting for you",
    text9: "FAQ",
    text10: "Simple and convenient, easy to use",
    text11: "Apply for an agent",
    text12: "Joining the agent, the monthly income is one million",
    text13: "How to deposit",
    text14: "Quick and easy, free deposit",
    text15: "How to withdraw money",
    text16: "No worries about big money, withdraw at any time",
    text17: "Customer Email",
    text18: "Customer Service QQ",
    text19: "Customer Service WeChat",
    text20: "Online Customer Service",
    text21: "7x24 hours online caring service",
    text22: "QQ QR code",
    text23: "WeChat QR Code",
    text24: "About Us",
    text25: "Deposit Help",
    text26: "Withdrawal help",
    text27: "Rules and Statements",
    text28: "FAQ",
    text29: "Agent Center",
    text30: "Copyright © bwin Reserved @v2.0.1 - 009",
    text31: "Home",
    text32: "Promotions",
    text33: "Video Games",
    text34: "Live Video",
    text35: "E-Sports",
    text36: "Sports Events",
    text37: "Lottery Game",
    text38: "chess and card games",
    text39: "Fishing Game",
    text40: "Native APP enjoys fingertips",
    text41: "Sports betting, live video, easy to grasp",
    text42:
      "The world's first integrated entertainment native APP, enjoy smooth, perfect operation, massive",
    text43:
      "Sports, top e-sports events, live entertainment, lottery betting and electronic games, etc...",
    text44:
      "The latest and most complete entertainment items can be experienced in the palm of your hand, scan the code to download, and have it immediately!",
    text45: "Scan the code to download the APP",
    text46: "Support iOS & Android",
    text47: "Direct access without downloading",
    text48: "Live Video",
    text49: "Would you like to bind a bank card? ",
    text50: "You have not bind a bank card",
    text51: "OK",
    text52: "Cancel",
  },
  // 首页登录
  homePageLogin: {
    text1: "Profile",
    text2: "Payment method",
    text3: "Game record",
    text4: "Transaction record",
    text5: "Announcement information",
    text6: "Logout",
    text7: "Login",
    text8: "Register",
    text9: "Account",
    text10: "Password",
    text11: "Retrieve password",
    text12: "Online Customer Service",
    text13: "Exception validation",
    text14:
      "The system detects that your account login area is abnormal, please fill in your account real name for verification!",
    text15: "Please enter your real name",
    text16: "Submit Verification",
    text17: "Security Authentication",
    text18:
      "You are logging in on a new device. For the security of your account, please perform security verification",
    text19: "Please enter your bound mobile phone number",
    text20: "Please enter the SMS verification code",
    text21: "Get verification code",
    text22: "Submit Verification",
    text23: "Refresh succeeded",
    text24: "Logged out",
    text25: "Please enter your real name",
    text26: "Invalid phone number",
    text27: "Please enter the SMS verification code",
  },
  // 活动
  promoInfo: {
    text1: "All Offers",
    text2: "Long-term activity",
    text3: "Limited time event",
    text4: "There is currently no activity",
    text5: "to",
    text6: "Contact Customer Service",
    text7: "Join Now",
    text8: "Active status:",
    text9: "View activity log",
    text10: "Activity Status: Activity Deadline",
    text11: "View activity log",
    text12: "Activity record",
    text13: "Date",
    text14: "Complete condition",
    text15: "Completed Status",
  },
  // 游戏二级页面
  gamePage: {
    text0: "All games",
    text1: "Play now",
    text2: "Top Recommendations",
    text3: "Play now",
    text4: "Please enter the game name",
    text5: "Enter the game",
    text6: "League of Legends",
    text7: "Honor of Kings",
    text8: "DOTA2",
    text9: "CSGO",
    text10: "Hearthstone",
    text11: "Overwatch",
    text12: "FIFA",
    text13:
      "High-definition live broadcast allows you to experience exciting sports",
    text14:
      "Full coverage of the world's top events, providing professional odds data and settlement result data. It has the top",
    text15:
      "Technical support to provide players with an amazing visual interface and the ultimate user experience.",
    text16: "Sexy Dealer · Showcase",
    text17:
      "The most beautiful croupier on the entire network, starring beauties from seven countries, original entertainment & professional dual mode, extreme high-definition",
    text18:
      "Visual feast, diversified game types, bring you the most comfortable gaming experience.",
    text19:
      "The latest and most dazzling live online entertainment, you can choose from classics, microphone cards, and package table baccarat, and beautiful women such as",
    text20:
      "Compatible with multiple cloud terminals, bringing you the ultimate smooth gaming experience.",
    text21: "Baccarat",
    text22: "Roulette",
    text23: "Sic Bo",
    text24: "Dragon Tiger",
    text25: "Niu Niu",
    text26: "Fried Golden Flower",
    text27: "Three Dukes",
    text28: "Experience the thrill of minutes",
    text29:
      "Top flagship platform, the most popular color varieties, diverse gameplay and simple operation",
    text30: "Real-time help, keep adding good luck!",
    text31: "Enjoy the wonderful world",
    text32:
      "Super-high odds, all kinds of lottery, Mark Six, and Beijing racing",
    text33:
      "Committed to creating a high-quality entertainment environment for players.",
    text34: "Speed ​​Racing",
    text35: "Beijing Racing",
    text36: "Shi Shi Cai",
    text37: "Five from Eleven",
    text38: "Mark Six",
    text39: "New gameplay · Feel the excitement",
    text40:
      "A variety of sports events are available, and the game has a variety of gameplay. The second half is bold and the ball is new to play",
    text41: "Fa, feel different and wonderful",
    text42: "Cash Early · Stop Loss in Time",
    text43:
      "Innovative functions are cashed in advance, you can settle in advance without waiting, and the stop-loss mechanism allows you to have no taboos",
    text44: "Football",
    text45: "Basketball",
    text46: "Volleyball",
    text47: "Baseball",
    text48: "Tennis",
    text49: "Ping-Pong",
    text50: "Badminton",
  },
  // 用户中心
  userPage: {
    // 活动记录
    text1: "Activity record",
    text2:
      "The data of each activity will have a certain time delay, for reference only",
    text3: "Event Name",
    text4: "activity time",
    text5: "Year/Month/Day",
    text6: "Query",
    text7: "Reset",
    text8: "Event Name",
    text9: "Participation time",
    text10: "Operation",
    text11: "View Details",
    text12: "Not a single record...",
    text13: "Please enter the event name",
    // 收款方式管理
    text14: "Management of payment methods",
    text15: "Add payment method",
    text16: "Account Name",
    text17: "Bank Name",
    text18: "Select Bank",
    text19: "Branch name",
    text20: "Please enter the branch name",
    text21: "Bank Card Number",
    text22: "Please enter the bank card number",
    text23: "Confirm card number",
    text24: "Please enter the bank card number again",
    text25: "Mobile phone number",
    text26: "Verification Code",
    text27: "Please enter the verification code",
    text28: "Send verification code",
    text29: "Account Name",
    text30: "currency name",
    text31: "Link",
    text32: "Please enter ERC20 or TRC20",
    text33: "Wallet address",
    text34: "Please enter the wallet address",
    text35: "Confirm address",
    text36: "Please enter the wallet address again",
    text37: "Mobile phone number",
    text38: "Currency",
    text39: "Select currency",
    text40: "Wallet Account ID",
    text41: "Please enter origo wallet ID",
    text42: "Confirm account",
    text43: "Please enter the origo wallet ID again",
    text44: "OK",
    text45: "Return",
    text46: "Bank Card",
    text47: "Digital Currency",
    text48: "origo wallet",
    text49: "No bank card yet",
    text50: "Add bank card",
    text51: "Add digital currency",
    text52: "Add origo wallet",
    // 公告
    text53: "Notification",
    text54: "Announcement",
    // 银行卡
    text55: "Payment by bank card",
    text56: "Payment Details",
    text57: "Payee account name",
    text58: "Receiving Bank",
    text59: "Payment card number",
    text60: "Account opening bank-sub-branch information",
    text61: "Warm reminder",
    text62: "1. Please fill in the card number and recharge amount correctly.",
    text63:
      "2. Please confirm that the payment has been made after the bank card transfer is successful.",
    text64:
      "3. The arrival time depends on the bank's processing time, please be patient.",
    text65: "4. Please do not pay twice.",
    // 存款
    text66: "Deposit",
    text67: "Payment method",
    text68: "Company Deposit",
    text69: "Online Deposit",
    text70: "Digital Currency",
    text71: "VIP Exclusive",
    text72: "Gift",
    text73: "Recharge Channel",
    text74: "Warm reminder",
    text75:
      "1. Please pay attention to the minimum transfer amount displayed on the page, recharges less than the minimum recharge amount will not be credited and cannot be refunded",
    text76:
      "2. After you recharge to the above address, you need the confirmation of the entire network node, please wait patiently, we will credit the corresponding amount to your account immediately after receiving the digital currency. If you haven't received it for a long time, you can contact customer service consult",
    text77:
      "3. Please be sure to check the currency and chain name when transferring money. If you submit the wrong asset, you cannot get it back",
    text78:
      "4. The exchange rate displayed on the page is for reference only. Due to fluctuations in the exchange rate, the account will be calculated based on the latest exchange rate ratio",
    text79: "Deposit Name",
    text80:
      "The account has not arrived in time, please be sure to enter the correct deposit name",
    text81: "Recharge amount",
    text82: "Single minimum deposit amount",
    text83: "Single maximum deposit amount",
    text84: "Please enter deposit name",
    text85: "Please enter a legal amount",
    text86: "The recharge amount is not within the range",
    text87: "The channel is abnormal, please contact customer service!",
    //短信验证弹窗
    text88: "Please verify your mobile phone number to withdraw money",
    text89: "Mobile",
    text90: "Please enter your phone number",
    text91: "Verification Code",
    text92: "Please enter the verification code",
    text93: "Send verification code",
    text94: "OK",
    text95: "Mobile phone number cannot be empty",
    text96: "Please enter the correct mobile phone number",
    text97: "The verification code cannot be empty",
    text98: "The format of the phone number is incorrect",
    // 取款
    text99: "Withdrawal",
    text100: "Main account limit",
    text101: "Meta",
    text102: "Transfer wallet ",
    text103: "Inclusion",
    text104: "Payment method",
    text105: "Add payment method",
    text106: "Next step",
    text107: "Payment method",
    text108: "Withdrawal Amount",
    text109: "Current withdrawal amount",
    text110: "All",
    text111: "Actual Receipt Amount",
    text112: "Actual Receipt Amount",
    text113: "Withdrawal Fee",
    text114: "Administrative Expenses",
    text115: "Discount deduction",
    text116: "Transaction Password",
    text117: "Please enter your password",
    text118: "Previous step",
    text119: "OK",
    text120: "Please select a withdrawal method",
    text121: "Refresh succeeded",
    text122: "The withdrawal amount cannot be empty!",
    text123: "The transaction password cannot be empty!",
    text124:
      "The application is successful, please wait for the platform to review!",
    // 游戏记录
    text125: "Game Record",
    text126:
      "There will be a certain time delay in the data of each bet, for reference only",
    text127: "Game Platform",
    text128: "Betting Amount",
    text129: "Time Filter",
    text130: "Query",
    text131: "Reset",
    text132: "Order number",
    text133: "Game Platform",
    text134: "Game Name",
    text135: "Bet time",
    text136: "Payout Time",
    text137: "Betting Amount",
    text138: "Valid Bet",
    text139: "Payout Amount",
    text140: "Profit and Loss Amount",
    text141: "Total Bet",
    text142: "Total Effective Bet",
    text143: "Total payout",
    text144: "Total profit and loss amount",
    // 彩金
    text146: "Bonus",
    text147: "Collection period expires",
    text148: "Loading",
    text149: "In the end",
    text150: "No data yet",
    text151: "Bonus claim",
    text152: "Flow Requirements",
    text153: "fold",
    text154: "Cancel",
    text155: "Get it now",
    text156: "All",
    text157: "Available",
    text158: "Claimed",
    text159: "Expired",
    // 公告消息
    text160: "Announcement message",
    text161: "Not a single notification...",
    text162: "Not a single announcement...",
    // 全民返利
    text163: "National Rebate",
    text164: "Rule Description",
    text165: "Cumulative rebate",
    text166: "full",
    text167: "Meta, and valid membership≥",
    text168: "People can get it",
    text169: "Receive",
    text170: "Members",
    text171: "Valid Member",
    text172: "My Promotion Code",
    text173: "Download Promotion Code",
    text174: "My invitation code",
    text175: "Copy the invitation code",
    text176: "My promotion address",
    text177: "Copy address",
    text178: "Rule Description",
    text179: "Receive rebate",
    text180: "Flow Requirements",
    text181: "Cancel",
    text182: "OK",
    text183: "The claim requirement is not met, the minimum claim amount",
    text184: "Yuan, the number of valid members≥",
    text185: "People, get the upper limit",
    text186: "Received successfully",
    text187: "Failed to claim",
    // 邀请会员
    text188: "Invite members",
    text189: "Total Bet",
    text190: "Accumulated total rebates",
    text191: "Registration time:",
    text192: "Select time:",
    text193: "Member Account",
    text194: "Registration time",
    text195: "Total effective bets (yuan)",
    text196: "Rebate (yuan)",
    // 个人资料
    text197: "Profile",
    text198: "Common Device Management",
    text199: "(Up to 10 common login devices can be bound)",
    text200: "Native",
    text201: "Delete",
    text202: "No device currently",
    // 资金密码
    text203: "Funding Password",
    text204: "Old password",
    text205: "Please enter the original fund password",
    text206: "The current password of funds you are using",
    text207: "New password",
    text208: "Please enter the new fund password",
    text209: "Password 6-digit combination",
    text210: "Confirm Password",
    text211: "Please enter the password again",
    text212: "must match the new password",
    text213: "OK",
    text214: "Confirm password cannot be empty",
    text215: "Transaction password must be 6 digits",
    text216: "The passwords entered twice do not match",
    text217: "New fund password cannot be empty",
    text218: "Transaction password must be 6 digits",
    text219: "The original fund password cannot be empty",
    // 账号密码
    text220: "Account Password",
    text221: "Old password",
    text222: "Please enter the original login password",
    text223: "The current login password you are using",
    text224: "New password",
    text225: "Please enter a new login password",
    text226:
      "Password 6-15 digits, at least two combinations of alphanumeric symbols",
    text227: "Confirm Password",
    text228: "Please enter the new password again",
    text229: "must match the new password",
    text230: "Confirm password cannot be empty",
    text231: "The passwords entered twice do not match",
    text232: "The original login password cannot be empty",
    text233: "The new login password cannot be empty",
    text234: "Please login again",
    // tab
    text235: "Security Authentication",
    text236: "Funding Password",
    text237: "Login Password",
    text238: "Device Management",
    // 返水详情页
    text239: "Transaction History",
    text240: "Rebate Details",
    text241:
      "Only the transaction records of the past month can be inquired. For more information, please contact customer service for inquiries",
    text242: "Betting Amount",
    text243: "Time Filter",
    text244: "Time",
    text245: "Game Platform",
    text246: "Game Name",
    text247: "Flowing Water",
    text248: "Rebate",
    text249:
      "Your query time has exceeded the established time limit, if you need more information, please contact online customer service",
    // 交易记录
    text250: "Transaction History",
    text251:
      "Only the transaction records of the past month can be inquired. For more information, please contact customer service to inquire",
    text252: "Transaction Type",
    text253: "Transaction time",
    text254: "Select time",
    text255: "Copy",
    text256: "Amount",
    text257: "Status",
    text258: "Details",
    text259: "Recharge Record",
    text260: "Bank Transfer",
    text261: "Withdrawal History",
    text262: "Rebate record",
    text263: "Other",
    text264: "Today",
    text265: "Yesterday",
    text266: "Last 7 days",
    text267: "Last 30 days",
    text268: "Payment method",
    text269: "Withdrawal method",
    text270: "Rebate type",
    text271: "Type",
    text272:
      "Your query time has exceeded the established time limit, if you need more information, please contact online customer service",
    // 收款方式管理
    text273: "Management of payment methods",
    text274: "Add bank card/digital currency",
    text275: "Do not add the same bank repeatedly",
    // 基本信息
    text276: "Account Password",
    text277: "Transaction Password",
    text278: "Manage Devices",
    text279: "Management",
    text280: "Modify",
    text281: "Settings",
    // 信息编辑
    text282: "Real name",
    text283: "Please enter your real name",
    text284: "Member Birthday",
    text285: "User Nickname",
    text286: "Contact QQ",
    text287: "Please enter user nickname",
    text288: "Mobile number",
    text289: "Please enter your mobile number",
    text290: "Contact WeChat",
    text291: "Please enter contact WeChat",
    text292: "Member Email",
    text293: "Please enter member email address",
    text294: "Please enter user nickname",
    // 个人中心
    text295: "Ordinary user",
    text296: "Balance",
    text297: "Commission",
    text298: "Rebate Rebate",
    text299: "VIP level",
    text300: "Level",
    text301: "Condition met",
    text302: "Time",
    // 用户菜单
    text303: "User Information",
    text304: "Game Record",
    text305: "Transaction History",
    text306: "Announcement message",
    text307: "My Account",
    text308: "Activity Log",
    text309: "National Rebate",
    text310: "Deposit",
    text311: "Withdrawal",
    text312: "Bonus",
    text313:
      "The rebate amount does not meet the conditions and cannot be claimed!",
    text314: "Password",
    text315: "Bank Account",
    text316: "Language",
    text317: "Transfer",
    text318: "Total number of members",
    text319: "Please create your withdrawal method",
    text320: "Withdrawal method",
    text321: "Create withdrawal method",
    text322: "Please verify phone number",
    text323: "SMS verification code",
  },
  ...comp_en,
};
export default en;
